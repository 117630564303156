import { useAppSelector } from '@hooks/redux'
import { useLazyGetAllUsersQuery } from '@services/users-service'
import { setUsers } from '@store/slices/users'
import { getUserLocation } from '@utils/utils'
import { getMemberName, getMemberInitials } from '@utils/helpers'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fakeUser } from '@constants/app'

const useUserInfo = () => {
  const users = useSelector(state => state?.users || [])
  const authState = useAppSelector(state => state?.auth)
  const user = authState?.user || {}
  const [fetchAllUsers] = useLazyGetAllUsersQuery()
  const dispatch = useDispatch()

  /** Gets a user by id by checking the redux slice.
   *
   *  Pass in useLoggedInUserAsFallback as true to use the currently logged in user as a fallbck */
  const getUserById = useCallback(
    (userId, useLoggedInUserAsFallback = false) => {
      if (Number(userId) === user?.id) {
        return user
      }
      const userQueryResult = Array.isArray(users)
        ? users.find(member => member?.id === Number(userId))
        : null
      if (userQueryResult) {
        return userQueryResult
      }
      if (useLoggedInUserAsFallback) {
        return user
      }
      return fakeUser
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users, user]
  )

  /**
   * Get's a user's location
   * @param {Object} user
   * @returns string
   */
  const getMemberLocation = user => getUserLocation(user)

  const loggedInUserIsOrganization =
    user?.first_name === null ||
    user?.first_name === undefined ||
    (user?.first_name === '' && user?.org_name?.trim() !== '')

  const getUserInitials = user => getMemberInitials(user)

  const getAllUsers = async (cache = false) => {
    try {
      const response = await fetchAllUsers(null, cache).unwrap()
      dispatch(setUsers(response?.users || []))
    } catch (e) {
      console.log(e)
    }
  }

  return {
    getUserById,
    getMemberName,
    getMemberLocation,
    user,
    loggedInUserIsOrganization,
    getUserInitials,
    getAllUsers
  }
}

export default useUserInfo
