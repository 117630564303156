import { useAppSelector } from '@hooks/redux'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const ErrorFallback = ({ error }) => {
  const navigate = useNavigate()

  const { user } = useAppSelector(state => state.auth)

  useEffect(() => {
    if (error) {
      if (user) {
        navigate('/dashboard/home')
      } else {
        navigate('/')
      }
    }
    toast.error('Something went wrong, please try again later')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return (
    <>
      {/* <div className="h-screen bg-white flex flex-col items-center justify-center">
        <p>Something went wrong while performing that action:</p>
        <pre>
          We have notified our developers and a fix will be made promptly
        </pre>
        <button
          className="mt-5 bg-primary font-lato text-white rounded-md px-4 py-2"
          onClick={() => {
            navigate('/dashboard/home')
          }}
        >
          Try again
        </button>
      </div> */}
    </>
  )
}

ErrorFallback.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func
}

export default ErrorFallback
