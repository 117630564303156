// import { LoginSuccess } from 'actions-deleted/UserAuth'
// import { updateModalMenu } from 'actions-deleted/modalMenu'
// import { useUploadImageMutation } from '@service/fileUpload'
// import {
//   useUpdateBusinessVisibilityMutation,
//   useUpdateVisibilityMutation
// } from '@service/manageVisibility'
// import { useGetUserMutation } from '@service/userLogin'
// import {
//   useAddUserLocationMutation,
//   useAddUserOrganisationMutation,
//   useDeleteUserOrganisationMutation,
//   useEditUserLocationMutation,
//   useUpdateUserDetailsMutation
// } from '@service/userService'
// import React, { useRef, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import MenuModalHOC from '../MenuModalHOC'
// import './EditProfile.css'
// import Individual from './Individual'
// import Organisation from './Organisation'

// function EditProfile() {
//   const dispatch = useDispatch()
//   const fileInputRef = useRef(null)
//   const [image, setImage] = useState(null)
//   const [loading, setLoading] = useState(false)

//   const [inputedEmployers, setInputedEmployers] = useState([])
//   const [employersToBeDeleted, setEmployersToBeDeleted] = useState([])
//   const [schoolsToBeDeleted, setSchoolsToBeDeleted] = useState([])
//   const [inputedSchools, setInputedSchools] = useState([])

//   const user = useSelector(state => state?.auth?.data)
//   const {
//     address = '',
//     firstName = '',
//     lastName = '',
//     bioStatement = '',
//     profession = '',
//     displayName = '',
//     account_type = '',
//     user_reference = {}
//   } = user?.user || []

//   const {
//     id: orgId = '',
//     name = '',
//     employeeCount = '',
//     tagline = '',
//     businessType = '',
//     industry = ''
//   } = user?.user?.user_organisations[0] || {}

//   const { location, hometown } = user?.user?.user_location || {}

//   const [data, setData] = useState({
//     address: address || '',
//     firstName: firstName || '',
//     lastName: lastName || '',
//     profession: profession || '',
//     displayName: displayName || '',
//     bioStatement: bioStatement || '',
//     location: location || '',
//     hometown: hometown || '',
//     imageUrl: null,
//     showPicture: user_reference?.showPicture || null
//   })

//   const [orgData, setOrgData] = useState({
//     name: name || '',
//     employeeCount: employeeCount || 0,
//     tagline: tagline || '',
//     businessType: businessType || '',
//     industry: industry || ''
//   })

//   //retrieve already saved school and employer data
//   const currentEmployers = user?.user?.user_organisations?.filter(
//     school => school.orgType === 'employer'
//   )

//   const currentSchools = user?.user?.user_organisations?.filter(
//     school => school.orgType === 'school'
//   )

//   //update display image and checker
//   const [showPicture, setShowPicture] = useState(
//     user?.user?.user_reference?.showPicture || null
//   )
//   const handleChange = event => {
//     setShowPicture(event.target.checked)
//   }

//   const handleCameraClick = () => {
//     fileInputRef.current.click()
//   }

//   const handleFileChange = event => {
//     setImage(event.target.files[0])
//   }

//   const [updateUserDetails] = useUpdateUserDetailsMutation()
//   const [updateVisibility] = useUpdateVisibilityMutation()
//   const [addUserOrganisation] = useAddUserOrganisationMutation()
//   const [deleteUserOrganisation] = useDeleteUserOrganisationMutation()
//   const [addUserLocation] = useAddUserLocationMutation()
//   const [editUserLocation] = useEditUserLocationMutation()
//   const [getUser] = useGetUserMutation()
//   const [updateOrgDetails] = useUpdateBusinessVisibilityMutation()
//   const [uploadImage] = useUploadImageMutation()

//   const [openModal, setOpenModal] = useState(false)
//   const [activatedMenu, setActivatedMenu] = useState('')
//   const openMenuDialog = menu => {
//     dispatch(updateModalMenu(menu))
//   }

//   const onIndividualSubmit = async () => {
//     //Update in the User Collection
//     const userPayload = {
//       firstName: data.firstName,
//       lastName: data.lastName,
//       bioStatement: data.bioStatement,
//       profession: data.profession,
//       displayName: data.displayName,
//       id: user?.user?.id
//     }

//     //update in the user_reference Collection
//     /*const visibilityPayload = {
//       id: user?.user?.user_reference?.id,
//       showPicture: showPicture,
//     };*/

//     //update in the user_location collections
//     const userLocationsPayload = {}
//     if (user?.user?.user_location?.id) {
//       userLocationsPayload.id = user?.user?.user_location.id
//     }
//     if (data.location !== '') {
//       userLocationsPayload.location = data.location
//     }
//     if (data.hometown !== '') {
//       userLocationsPayload.hometown = data.hometown
//     }
//     if (user?.user?.user_location?.id) {
//       userLocationsPayload.users_permissions_user = user?.user?.id
//     }

//     const addUserLocationPayload = {
//       location: data.location,
//       hometown: data.hometown,
//       users_permissions_user: user?.user?.id
//     }

//     setLoading(true)
//     try {
//       //User collection
//       if (image !== null) {
//         const formData = new FormData()
//         formData.append('files', image)
//         formData.append('ref', 'plugin::users-permissions.user')
//         formData.append('refId', `${user?.user?.id}`)
//         formData.append('field', 'imageUrl')

//         const imagePayload = {
//           jwt: user?.jwt,
//           body: formData
//         }

//         await uploadImage(imagePayload)
//       }
//       await updateUserDetails(userPayload)

//       //user_reference collection
//       /*if (showPicture !== user?.user?.user_reference?.showPicture) {
//         await updateVisibility(visibilityPayload);
//       }*/

//       //user organisations
//       if (inputedEmployers.length > 0) {
//         inputedEmployers.forEach(employer => {
//           addUserOrganisation(
//             JSON.stringify({
//               data: {
//                 name: employer,
//                 orgType: 'employer',
//                 users_permissions_user: user?.user?.id
//               }
//             })
//           )
//         })
//       }

//       if (inputedSchools.length > 0) {
//         inputedSchools.forEach(school => {
//           addUserOrganisation(
//             JSON.stringify({
//               data: {
//                 name: school,
//                 orgType: 'school',
//                 users_permissions_user: user?.user?.id
//               }
//             })
//           )
//         })
//       }

//       if (employersToBeDeleted.length > 0) {
//         employersToBeDeleted.forEach(employer => {
//           deleteUserOrganisation(employer.id)
//         })
//       }

//       if (schoolsToBeDeleted.length > 0) {
//         schoolsToBeDeleted.forEach(school => {
//           deleteUserOrganisation(school.id)
//         })
//       }

//       //User_Locations
//       if (
//         (data.hometown || data.location) &&
//         (user?.user?.user_location === null ||
//           user?.user?.user_location === undefined)
//       ) {
//         await addUserLocation(addUserLocationPayload)
//       } else if (
//         (data.hometown || data.location) &&
//         user?.user?.user_location
//       ) {
//         await editUserLocation(userLocationsPayload)
//       }
//     } catch (error) {
//       setLoading(false)
//     } finally {
//       const data = {
//         id: user?.user?.id,
//         jwt: user?.jwt
//       }
//       await getUser(data)
//         .unwrap()
//         .then(response => {
//           const newData = {
//             jwt: user?.jwt,
//             user: response
//           }
//           dispatch(LoginSuccess({ data: newData }))
//         })
//       setLoading(false)
//       openMenuDialog('Edit Profile Success')
//     }
//   }

//   //Organisation Accounts
//   const onOrganisationSubmit = async () => {
//     //Updates in User Collection
//     const userPayload = {
//       address: data.address,
//       bioStatement: data.bioStatement,
//       displayName: data.displayName,
//       id: user?.user?.id
//     }

//     //Updates In User_Organisation collection
//     const organisationPayload = {}
//     if (orgData.name !== '') {
//       organisationPayload.name = orgData.name
//     }
//     if (orgData.tagline !== '') {
//       organisationPayload.tagline = orgData.tagline
//     }
//     if (orgData.employeeCount !== '') {
//       organisationPayload.employeeCount = orgData.employeeCount
//     }
//     if (orgData.businessType !== '') {
//       organisationPayload.businessType = orgData.businessType
//     }
//     if (orgData.industry !== '') {
//       organisationPayload.industry = orgData.industry
//     }
//     if (orgId) {
//       organisationPayload.id = orgId
//     }

//     //updates in User_location collection
//     const userLocationsPayload = {}
//     if (user?.user?.user_location?.id) {
//       userLocationsPayload.id = user?.user?.user_location.id
//     }
//     if (data.location !== '') {
//       userLocationsPayload.location = data.location
//     }
//     if (user?.user?.user_location?.id) {
//       userLocationsPayload.users_permissions_user = user?.user?.id
//     }

//     const addUserLocationPayload = {
//       location: data.location,
//       users_permissions_user: user?.user?.id
//     }

//     setLoading(true)
//     try {
//       //User collection
//       if (image !== null) {
//         const formData = new FormData()
//         formData.append('files', image)
//         formData.append('ref', 'plugin::users-permissions.user')
//         formData.append('refId', `${user?.user?.id}`)
//         formData.append('field', 'imageUrl')

//         const imagePayload = {
//           jwt: user?.jwt,
//           body: formData
//         }

//         await uploadImage(imagePayload)
//       }
//       await updateUserDetails(userPayload).unwrap()

//       //User_OrganisationCollection
//       if (
//         organisationPayload.businessType ||
//         organisationPayload.employeeCount ||
//         organisationPayload.industry ||
//         organisationPayload.name ||
//         organisationPayload.tagline
//       ) {
//         await updateOrgDetails(organisationPayload)
//       }

//       //User_location Collection
//       if (
//         data.location &&
//         (user?.user?.user_location === null ||
//           user?.user?.user_location === undefined)
//       ) {
//         await addUserLocation(addUserLocationPayload)
//       } else if (data.location && user?.user?.user_location) {
//         await editUserLocation(userLocationsPayload)
//       }
//     } catch (error) {
//       setLoading(false)
//     } finally {
//       const data = {
//         id: user?.user?.id,
//         jwt: user?.jwt
//       }
//       await getUser(data)
//         .unwrap()
//         .then(response => {
//           const newData = {
//             jwt: user?.jwt,
//             user: response
//           }
//           dispatch(LoginSuccess({ data: newData }))
//         })
//       setLoading(false)
//       openMenuDialog('Edit Profile Success')
//     }
//   }

//   return (
//     <div className="w-full">
//       <MenuModalHOC
//         menu={activatedMenu}
//         open={openModal}
//         setOpen={setOpenModal}
//       />
//       {account_type === 'organization' ? (
//         <Organisation
//           user={user}
//           image={image}
//           fileInputRef={fileInputRef}
//           loading={loading}
//           data={data}
//           orgData={orgData}
//           setData={setData}
//           setOrgData={setOrgData}
//           setShowPicture={e =>
//             setData({ ...data, showPicture: e.target.checked })
//           }
//           handleCameraClick={() => handleCameraClick()}
//           handleFileChange={handleFileChange}
//           onSubmit={() => onOrganisationSubmit()}
//         />
//       ) : (
//         <Individual
//           user={user}
//           data={data}
//           image={image}
//           loading={loading}
//           fileInputRef={fileInputRef}
//           currentEmployers={currentEmployers}
//           inputedEmployers={inputedEmployers}
//           setInputedEmployers={setInputedEmployers}
//           setEmployersToBeDeleted={setEmployersToBeDeleted}
//           currentSchools={currentSchools}
//           inputedSchools={inputedSchools}
//           setInputedSchools={setInputedSchools}
//           setSchoolsToBeDeleted={setSchoolsToBeDeleted}
//           setData={setData}
//           handleCameraClick={() => handleCameraClick()}
//           handleFileChange={handleFileChange}
//           onSubmit={() => onIndividualSubmit()}
//         />
//       )}
//     </div>
//   )
// }

// export default EditProfile

const EditProfile = () => <div />

export default EditProfile
