import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Link, useNavigate } from 'react-router-dom'
import UserProfileImage from './UserAvatar'
import MenuModalHOC from '../Modals/MenuComponents/MenuModalHOC'
import { updateModalMenu } from '@store/slices/app'
import { getUserLocation } from '@utils/utils'

function MenuComponent({ open, dashboardData, onClose }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  const { user } = useSelector(state => state.auth)
  const [activatedMenu, setActivatedMenu] = useState('')

  const [openModal, setOpenModal] = useState(false)
  const openMenuDialog = menu => {
    dispatch(updateModalMenu(menu))
    setOpenModal(true)
  }

  return (
    <>
      <MenuModalHOC open={openModal} setOpen={setOpenModal} />
      {open && (
        <div
          x-show="open"
          className="absolute w-[100%] pt-6 pb-1 bg-white rounded-lg shadow-lg border 
           mt-5 z-[9999999999999]"
        >
          <div className="flex w-full justify-center items-center gap-3 mb-6">
            {!user?.picture ? (
              <UserProfileImage user={user} />
            ) : (
              <img
                src={user?.picture}
                alt="avatar"
                style={{
                  objectFit: 'cover'
                }}
                className="w-12 h-12 rounded-full overflow-hidden"
              />
            )}
            <div>
              <div className="text-[#58534D] text-lg">
                {user?.account_type === 'organization'
                  ? user?.org_name
                  : user?.first_name}
              </div>
              <div className="text-[#928B80] text-xs whitespace-nowrap">
                {getUserLocation(user, true)}
              </div>
            </div>
          </div>
          <div className="flex w-full justify-center items-center mb-8">
            {isMobile ? (
              <Link to="/dashboard/profile" onClick={() => onClose()}>
                <button
                  data-testid="view-profile-btn"
                  className="bg-[#FF9502] h-[40px] rounded-3xl text-white whitespace-nowrap px-[12px] mx-6 view-profile-btn"
                >
                  View Profile
                </button>
              </Link>
            ) : (
              <button
                data-testid="view-profile-btn"
                onClick={() => {
                  navigate('/dashboard/profile')
                }}
                className="bg-[#FF9502] h-[40px] rounded-3xl text-white whitespace-nowrap px-[12px] w-48 mx-auto view-profile-btn"
              >
                View Profile
              </button>
            )}
          </div>
          <ul className="space-y-3 text-[#140C00]">
            {/* {user?.userType === "organization" && (
              <React.Fragment>
                <hr className="border-[#928B804D]" />
                <li className=" px-4 py-1 mb-2">
                  <div
                    onClick={() =>
                      !user?.isEmailVerified && item.name != "Logout"
                        ? null
                        : openMenuDialog("Add Products")
                    }
                    className={`flex items-center transform transition-colors duration-200 border-r-4 border-transparent cursor-pointer ${!user?.isEmailVerified
                      ? "text-gray-200"
                      : ""
                      }`}
                  >
                    <div className="mr-3">
                      <img
                        src={add_products}
                        className="w-6 h-6 text-[16px] fill-gray-200"
                      />
                    </div>
                    Add Products & Services
                  </div>
                </li>
                <hr className="border-[#928B804D]" />
              </React.Fragment>
            )} */}

            {dashboardData.map((item, index) => (
              <div className="" key={index}>
                <li className=" px-4 py-1 mb-2">
                  <div
                    data-testid={item.testid}
                    onClick={() =>
                      !user?.isEmailVerified && item.name != 'Logout'
                        ? navigate(item.url)
                        : openMenuDialog(item.name)
                    }
                    className={`flex items-center transform transition-colors duration-200 border-r-4 border-transparent cursor-pointer text-[#140C00]`}
                  >
                    <div className="mr-3">
                      <img
                        src={item.icon}
                        className="w-6 h-6 text-[16px] fill-gray-200"
                      />
                    </div>
                    {item.name}
                  </div>
                </li>
                {dashboardData.length === index + 1 ? (
                  <></>
                ) : (
                  <hr className="border-[#928B804D]" />
                )}
              </div>
            ))}
          </ul>
        </div>
      )}
    </>
  )
}

export default MenuComponent
