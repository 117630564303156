import { createSlice } from '@reduxjs/toolkit'
const initialState = ''
const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    setTimer: (state, { payload }) => payload,
    clearTimer: () => ''
  }
})

const { reducer, actions } = timerSlice
export const { setTimer, clearTimer } = actions
export default reducer
