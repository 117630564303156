import { useAppDispatch } from '@hooks/redux'
import { useLazyGetPostByIdQuery } from '@services/posts-service'
import { refreshSinglePost } from '@store/slices/posts'

const usePostRefresh = () => {
  const [getPost] = useLazyGetPostByIdQuery()
  const dispatch = useAppDispatch()

  const refreshPost = async postId => {
    try {
      const response = await getPost(postId).unwrap()
      dispatch(refreshSinglePost({ postId, data: response.post }))
    } catch (e) {}
  }

  return {
    refreshPost
  }
}

export default usePostRefresh
