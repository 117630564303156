import { ReactComponent as SearchIcon } from '@assets/icons/search.svg'
import useDebounce from '@hooks/useDebounce'
import { handlePageScrolling } from '@utils/utils'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { doNothing } from '@utils/helpers'
import SearchDrawer from './SearchDrawer'
import { setSearchResults } from '@store/slices/app'

const SearchBar = ({ hasBackdrop = true, className }) => {
  const dispatch = useDispatch()
  const allUsers = useSelector(state => state.users || [])
  const {
    search: { results = [], query: prevQuery }
  } = useSelector(state => state.app)
  const [isFocused, setIsFocused] = useState(false)
  const [query, setQuery] = useState('')
  const [resultsDrawerVisible, setResultsDrawerVisible] = useState(false)
  const debouncedSearchQuery = useDebounce(query, 1000)?.trim()

  const search = text => {
    setResultsDrawerVisible(true)
    const normalizedQuery = text.toLowerCase()
    const results = allUsers.filter(user => {
      const searchParts = normalizedQuery.split(' ')
      return searchParts.every(part => {
        const trimmedPart = part.trim()
        return (
          user?.address?.toLowerCase().includes(trimmedPart) ||
          user?.city?.toLowerCase().includes(trimmedPart) ||
          user?.country?.toLowerCase().includes(trimmedPart) ||
          user?.state?.toLowerCase().includes(trimmedPart) ||
          user?.first_name?.toLowerCase().includes(trimmedPart) ||
          user?.last_name?.toLowerCase().includes(trimmedPart) ||
          user?.org_name?.toLowerCase().includes(trimmedPart)
        )
      })
    })
    dispatch(setSearchResults({ results, query }))
  }

  useEffect(() => {
    handlePageScrolling(!isFocused)
  }, [isFocused])

  useEffect(() => {
    if (isFocused && debouncedSearchQuery.length) {
      if (prevQuery === debouncedSearchQuery && results.length) {
        if (!resultsDrawerVisible) {
          setResultsDrawerVisible(true)
        }
        return
      }
      search(debouncedSearchQuery)
      return
    } else if (!debouncedSearchQuery?.length && resultsDrawerVisible) {
      setResultsDrawerVisible(false)
      dispatch(setSearchResults({ results: [], query: '' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery, isFocused])

  useEffect(() => {
    if (prevQuery && prevQuery !== debouncedSearchQuery) {
      setQuery(prevQuery)
    }
  }, [prevQuery])

  return (
    <div
      className={clsx('w-[351px] max-w-[351px] h-[45px] relative', className)}
    >
      {/* search backdrop */}
      {hasBackdrop && (
        <div
          className={clsx(
            'fixed top-[120px] left-0 w-full h-[calc(100vh_-_120px)] pointer-events-none opacity-0 bg-_140C00/[.3] z-[2] transition-all duration-150',
            { '!opacity-100 !pointer-events-auto': isFocused }
          )}
        ></div>
      )}
      <div className=" border-_928B80 border rounded-xl bg-white relative w-full h-full overflow-hidden">
        <div className="absolute left-4 top-1/2 -translate-y-1/2 pointer-events-none">
          <SearchIcon />
        </div>
        <input
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            resultsDrawerVisible ? doNothing() : setIsFocused(false)
          }}
          value={query}
          autoComplete="off"
          id="global-search"
          onChange={({ target: { value } }) => setQuery(value)}
          className="w-full h-full pl-[42px] border-none outline-none placeholder:text-_212226/[.4] font-lato text-base md-full:text-sm md-full:leading-[16.8px]"
          placeholder="Search..."
        />
      </div>
      <div className="absolute w-full z-[3] mt-4">
        <SearchDrawer
          open={resultsDrawerVisible}
          setOpen={shouldBeOpen => {
            setResultsDrawerVisible(shouldBeOpen)
            if (!shouldBeOpen) {
              setIsFocused(false)
            }
          }}
          searchResults={results}
          searchText={debouncedSearchQuery}
        />
      </div>
    </div>
  )
}

export default SearchBar

SearchBar.propTypes = {
  hasBackdrop: PropTypes.bool,
  className: PropTypes.string
}
