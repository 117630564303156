import { editPostTypes, postTypes } from '@constants/posts'
import moment from 'moment'

export const postCreationSteps = {
  postDetailsInputStep: 'postDetailsInput',
  askUserForGoalInputChoiceStep: 'askUserForGoalInputChoice',
  postPreviewStep: 'postPreview',
  congratulationsStep: 'congratulations',
  postTypeConfirmationStep: 'postTypeConfirmationStep',
  editEventDetailsStep: 'editEventDetailsStep',
  editGoalDetailsStep: 'editGoalDetailsStep',
  addProgressForGeneralPostStep: 'addProgressForGeneralPostStep',
  addProgressForGoalPostStep: 'addProgressForGoalPostStep',
  addProgressForEventPostStep: 'addProgressForEventPostStep'
}

export const imageIsUrl = image =>
  typeof image === 'string' && image.startsWith('http')

export const getImagePreviewUrl = image => {
  if (image) {
    if (imageIsUrl(image)) {
      return image
    }
    try {
      if (image instanceof FileList) {
        return URL.createObjectURL(image[0])
      }
      if (image instanceof File || image instanceof Blob) {
        return URL.createObjectURL(image)
      }
    } catch (e) {
      console.log(e)
      return ''
    }
  }
}

export const getPostModalTextMappings = (
  editData,
  walkthoughMenu,
  isEditingAProgress
) => {
  const {
    postDetailsInputStep,
    askUserForGoalInputChoiceStep,
    postPreviewStep,
    postTypeConfirmationStep,
    addProgressForGeneralPostStep,
    editGoalDetailsStep,
    addProgressForGoalPostStep,
    editEventDetailsStep,
    addProgressForEventPostStep
  } = postCreationSteps
  return {
    [editEventDetailsStep]: {
      title: 'Edit Post',
      subtitle: 'Update your event date, time and address'
    },
    [addProgressForGoalPostStep]: {
      title: 'Add Progress',
      subtitle: `Please add a progress amount, new description, and also a picture to your current goal post`
    },
    [editGoalDetailsStep]: {
      title: 'Edit Post',
      subtitle:
        'Please specify the amount, deadline and how much you have raised so far'
    },
    [addProgressForGeneralPostStep]: {
      title: 'Add Progress',
      subtitle:
        'Please add a new description, and also a picture to your current post'
    },
    [addProgressForEventPostStep]: {
      title: 'Add Progress',
      subtitle:
        'Please add a new description, and also a picture to your current post'
    },
    [postTypeConfirmationStep]: {
      title: 'Do you want this post to be an Event or Goal?',
      subtitle: ''
    },
    [postDetailsInputStep]: {
      title: isEditingAProgress
        ? 'Edit Progress'
        : !editData || (editData && Object.keys(editData).length === 0)
          ? walkthoughMenu === 'Create Post'
            ? 'Create a Post'
            : 'Make a post'
          : editData?.type === editPostTypes?.addProgress
            ? 'Add Progress'
            : 'Edit Post',
      subtitle: isEditingAProgress
        ? 'Make quick edits'
        : !editData || (editData && Object.keys(editData).length === 0)
          ? walkthoughMenu === 'Create Post'
            ? `Now that you've updated your profile, let's make a post and share them with our trusted Friends & Family`
            : 'Create and share your posts with your Network'
          : editData?.type === editPostTypes?.addProgress
            ? 'Please add a progress amount, new description, and also a picture to your current goal post'
            : 'Make quick edits to your post'
    },
    [askUserForGoalInputChoiceStep]: {
      title: editData ? 'Edit Post' : 'Add a Goal',
      subtitle: editData
        ? 'Please specify the amount, deadline and how much you have raised so far'
        : 'Maoney is all about Building wealth with trusted Friends & Family. Do you want to add a Goal to your post?'
    },
    [postPreviewStep]: {
      title: `Okay let's review`,
      subtitle: ''
    }
  }
}

export const walkthroughMenuTypes = {
  createPost: 'Create Post',
  none: ''
}

export function isDateLessThanToday(dateString, allowToday = false) {
  const dateParts = dateString.split('-')
  const year = parseInt(dateParts[0])
  const month = parseInt(dateParts[1])
  const day = parseInt(dateParts[2])
  const date = new Date(year, month - 1, day)
  const today = new Date()
  if (date.toDateString() === today.toDateString()) {
    return allowToday ? false : 'Error: The date cannot be today.'
  } else if (new Date(dateString) < today) {
    return 'Error: The date cannot be in the past.'
  } else {
    return false // Date is not less than today
  }
}

export const descriptionLimit = 500

export const getSteps = ({
  isProgressEditForGoalPost,
  isProgressEditForGeneralPost,
  isProgressEditForEventPost,
  isNormalEditForGeneralPost,
  isNormalEditForGoalPost,
  isNormalEditForEvent,
  isEditingAProgress,
  postToUpdate
}) => {
  const {
    postDetailsInputStep,
    congratulationsStep,
    postPreviewStep,
    postTypeConfirmationStep,
    editEventDetailsStep,
    editGoalDetailsStep,
    addProgressForGeneralPostStep,
    addProgressForGoalPostStep,
    addProgressForEventPostStep
  } = postCreationSteps

  let steps = [
    postDetailsInputStep,
    postTypeConfirmationStep,
    postPreviewStep,
    congratulationsStep
  ]

  if (isNormalEditForGeneralPost) {
    steps = [postDetailsInputStep, postPreviewStep, congratulationsStep]
  } else if (
    isEditingAProgress &&
    postToUpdate?.data?.type === postTypes.goal
  ) {
    steps = [postDetailsInputStep, postPreviewStep, congratulationsStep]
  } else if (isNormalEditForEvent) {
    steps = [
      postDetailsInputStep,
      editEventDetailsStep,
      postPreviewStep,
      congratulationsStep
    ]
  } else if (isNormalEditForGoalPost) {
    steps = [
      postDetailsInputStep,
      editGoalDetailsStep,
      postPreviewStep,
      congratulationsStep
    ]
  } else if (isProgressEditForGoalPost) {
    steps = [addProgressForGoalPostStep, postPreviewStep, congratulationsStep]
  } else if (isProgressEditForGeneralPost) {
    steps = [
      addProgressForGeneralPostStep,
      postPreviewStep,
      congratulationsStep
    ]
  } else if (isProgressEditForEventPost) {
    steps = [addProgressForEventPostStep, postPreviewStep, congratulationsStep]
  }
  return steps
}

export function getDateTimeISOString(dateString, timeString) {
  const [day, month, year] = dateString.split('/')
  const [hours, minutes, seconds] = timeString.split(':')
  const dateTime = new Date(year, month - 1, day, hours, minutes, seconds)
  const timezoneOffsetInMinutes = dateTime.getTimezoneOffset() * -1 // Convert to positive value
  dateTime.setMinutes(dateTime.getMinutes() + timezoneOffsetInMinutes)
  const isoString = dateTime.toISOString()
  return isoString
}

export function extractDateAndTimeFromISOString(formattedDate) {
  const date = moment(formattedDate).format('YYYY-MM-DD')
  const time = moment(formattedDate).format('HH:MM')
  return { date, time }
}

export function createEventDate(dateString, timeString) {
  const [year, month, day] = dateString.split('-')
  const [hours, minutes] = timeString.split(':')
  // Create Date object with provided date and time
  return new Date(year, month - 1, day, hours, minutes)
}
