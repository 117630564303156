/* eslint-disable react/prop-types */
import React from 'react'
import clsx from 'clsx'
import { postTypes } from '@constants/posts'
import { PrimaryButton } from '@components/Buttons'
import AddGoal from './AddGoal'
import PostEventForm from './PostEventForm'

const activeBtnClassNames = '!bg-primary !border-primary !text-white'

const PostTypeConfirmation = ({ onProceed, postData, setPostData }) => {
  const postIsEvent = postData.type === postTypes.event
  const postIsGoal = postData.type === postTypes.goal
  const setPostType = type => {
    setPostData(prev => ({ ...prev, type }))
  }

  const skip = () => {
    setPostType(postTypes.general)
    onProceed()
  }

  const onEventFormSubmit = data => {
    const { date, time, address } = data
    setPostData(prev => ({
      ...prev,
      required_amount: '',
      deadline: '',
      current_amount: '',
      date,
      time,
      address,
      type: postTypes.event
    }))
    onProceed()
  }

  return (
    <div className="mt-10 px-4" data-testid="post-type-confirmation">
      <div className="w-[380px] xs:w-full mx-auto">
        <div className="flex w-full justify-center">
          <button
            className={clsx(
              'h-14 max-w-48 w-full border border-[#928B80] text-[#140C00] rounded-l-lg',
              { [activeBtnClassNames]: postIsGoal }
            )}
            data-testid="make-post-a-goal"
            onClick={() => setPostType(postTypes.goal)}
          >
            Goal
          </button>
          <button
            className={clsx(
              'h-14 w-full max-w-48 border border-[#928B80] text-[#140C00] rounded-r-lg',
              { [activeBtnClassNames]: postIsEvent }
            )}
            data-testid="make-post-an-event"
            onClick={() => setPostType(postTypes.event)}
          >
            Event
          </button>
        </div>
        <div
          className={clsx('min-h-[100px] md-full:min-h-[305px]', {
            '!md-full:min-h-[356px]': postIsEvent || postIsGoal
          })}
        >
          {!postIsEvent && !postIsGoal && (
            <div className="h-full flex py-[40px] md-full:py-[100px] items-center justify-center flex-col space-y-4">
              <div className="h-[42px] w-[42px] flex items-center justify-center bg-[#928B80] rounded-full">
                <span className="text-[24px] font-bold text-white leading-[32.68px]">
                  i
                </span>
              </div>
              <p className="text-_140C00 text-base leading-6 text-center">
                Skipping this process would allow you to make a Social post, or
                you can choose to make an Event post or a Goal Post
              </p>
            </div>
          )}
          {postIsEvent && (
            <PostEventForm onSubmit={onEventFormSubmit} postData={postData} />
          )}
          {postIsGoal && (
            <AddGoal
              onProceed={onProceed}
              setPostData={setPostData}
              postData={postData}
            />
          )}
        </div>
        {!postIsEvent && !postIsGoal && (
          <div className="pb-8 flex justify-center">
            <PrimaryButton
              text={'Skip'}
              onClick={skip}
              data-testid="skip-btn"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default PostTypeConfirmation
