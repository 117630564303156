import clsx from 'clsx'
import React from 'react'
import PropTypes from 'prop-types'

const hiddenClassNames =
  'fixed opacity-0 top-0 left-0 fixed h-screen w-full pointer-events-none transition-all duration-300 p-4 flex items-center justify-center bg-black/[.6] z-[100]'
const visibleClassNames = '!opacity-100 !pointer-events-auto'

const Backdrop = ({
  children,
  visible,
  backdropIsClickable = false,
  onBackDropClick,
  ...rest
}) => {
  const handleBackDropClick = () => {
    backdropIsClickable && onBackDropClick && onBackDropClick()
  }
  return (
    <div
      className={clsx(hiddenClassNames, {
        [visibleClassNames]: visible,
        'cursor-pointer': backdropIsClickable
      })}
      onClick={handleBackDropClick}
      {...rest}
    >
      <div
        className={clsx('flex items-center justify-center cursor-default', {
          'w-full': !backdropIsClickable
        })}
        onClick={e => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  )
}

Backdrop.propTypes = {
  children: PropTypes.element.isRequired,
  visible: PropTypes.bool,
  backdropIsClickable: PropTypes.bool,
  onBackDropClick: PropTypes.func
}

export default Backdrop
