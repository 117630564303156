import { PrimaryButton } from '@components/Buttons'
import React from 'react'

const Congratulations = ({ onClose }) => (
  <div className="flex flex-col w-full items-center justify-center">
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50" cy="50" r="50" fill="#FF9502" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.4099 64.3338C42.2299 65.0671 43.5593 65.0671 44.3792 64.3338L68.134 43.0893C68.954 42.356 68.954 41.1671 68.134 40.4338C67.3141 39.7005 65.9847 39.7005 65.1647 40.4338L41.4099 61.6782C40.5899 62.4116 40.5899 63.6005 41.4099 64.3338Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.8329 52.149C32.6101 51.367 33.8702 51.367 34.6475 52.149L43.0911 60.6443C43.8683 61.4262 43.8683 62.6941 43.0911 63.476C42.3139 64.258 41.0538 64.258 40.2766 63.476L31.8329 54.9807C31.0557 54.1988 31.0557 52.9309 31.8329 52.149Z"
        fill="white"
      />
    </svg>

    <div className="space-y-[22px] mt-6">
      <h3 className="font-lato text-[32px] leading-[38.4px] text-_140C00 text-center">
        Congratulations
      </h3>
      <p className="text-_58534D text-base leading-6 text-center font-lato">
        You have sent a message to your subscribers
      </p>
    </div>
    <div className="absolute bottom-0 left-0 w-full">
      <PrimaryButton text={'Done'} onClick={onClose} />
    </div>
  </div>
)

export default Congratulations
