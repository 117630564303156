import close from '@assets/icons/close.svg'
import Loader from '@components/Loaders/Loader'
import React from 'react'
import { Modal } from '@mui/material'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import {
  setCommentToDelete,
  setDeletedCommentDetails
} from '@store/slices/posts'
import { useDeleteCommentMutation } from '@services/posts-service'
import { doNothing } from '@utils/helpers'
import usePostRefresh from '@hooks/posts/usePostRefresh'

const DeleteComment = () => {
  const dispatch = useAppDispatch()
  const { commentToDelete } = useAppSelector(state => state.posts)
  const [deleteComment, { isLoading }] = useDeleteCommentMutation()
  const { refreshPost } = usePostRefresh()

  const onClose = () => {
    dispatch(setCommentToDelete(null))
  }

  const onDelete = async () => {
    const commentId = commentToDelete?.comment?.id
    try {
      await deleteComment(commentId).unwrap()
      refreshPost(commentToDelete?.postId)
      dispatch(setDeletedCommentDetails(commentToDelete))
      onClose()
    } catch (e) {
      doNothing()
    }
  }

  return (
    <Modal open={!!commentToDelete} setOpen={onClose}>
      <React.Fragment>
        <div className="flex items-center justify-center h-screen w-screen">
          <div className="rounded-2xl bg-white font-lato text-center max-w-md py-8 px-6 xs:py-4 xs:px-2">
            <div className="flex justify-between">
              <div className="w-10"></div>
              <p className="text-[#140C00] font-lato font-bold text-[32px] xs:text-2xl mt-2">
                Delete
              </p>
              <div
                className="flex items-center justify-center bg-[#928B8033] rounded-full w-10 h-10 cursor-pointer"
                onClick={onClose}
              >
                <img src={close} className="w-4 h-4" />
              </div>
            </div>

            <div className="text-[#58534D] mt-6 xs:text-sm xs:w-40 w-60 mx-auto">
              Are you sure you want to delete this comment
            </div>
            {isLoading ? (
              <Loader type="regular" label="Deleting comment" />
            ) : (
              <div className="flex xs:flex-col-reverse justify-between gap-4 mt-12 mx-6">
                <button
                  onClick={onClose}
                  className="xs:w-full w-44 bg-white border-2 border-[#CC0000] rounded-lg text-[#CC0000] py-4 px-10 font-bold"
                >
                  Cancel
                </button>

                <button
                  onClick={onDelete}
                  className="xs:w-full w-44 bg-[#FF9502] rounded-lg text-white py-4 px-10 font-bold"
                >
                  Yes, Delete
                </button>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    </Modal>
  )
}

export default DeleteComment
