import { ReactComponent as Marker } from '@assets/icons/marker-dark.svg'
import PrimaryButton from '@components/Buttons/PrimaryButton'
import UserProfileImage from '@components/utils/UserAvatar'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { formatSearchResult } from './utils/functions'
import { useRequestForConnectionMutation } from '@services/connections-service'
import useConnections from '@hooks/connections/useConnections'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const SearchResultListItem = ({ result, hasBottomBorder = true }) => {
  const navigate = useNavigate()
  const { user } = useSelector(state => state.auth)
  const { isUserConnection, getUserConnections } = useConnections()

  const [connecting, setConnecting] = useState(false)
  const [requestForConnection] = useRequestForConnectionMutation()
  const { name, location } = formatSearchResult(result)

  const handleConnect = async receiver => {
    setConnecting(true)
    try {
      await requestForConnection({
        sender_id: user.id,
        receiver_id: receiver.id
      }).unwrap()
      await getUserConnections(false)
      setConnecting(false)
    } catch (e) {
      console.log(e)
      setConnecting(false)
    }
  }

  const connection = isUserConnection(result?.id)

  return (
    <div
      className={clsx(
        'py-6 text-left flex flex-col space-y-3 md-full:space-y-0 md-full:flex-row md-full:justify-between w-full md-full:pt-6 md-full:pb-4',
        {
          'border-b border-b-928B80/[.5]': hasBottomBorder
        }
      )}
    >
      <div
        className="flex items-center space-x-4 cursor-pointer flex-1"
        onClick={() => {
          if (result?.id) {
            navigate(`/dashboard/user/${result.id}`)
          }
        }}
      >
        <UserProfileImage user={result} width="70" height="70" />
        <div className="text-_58534D text-sm leading-[16.8px] flex items-center md-full:hidden">
          <span>{name}</span>
          {location && (
            <div className="h-[3px] w-[3px] rounded-[1.5px] bg-_58534D mx-1 flex-shrink-0" />
          )}
          {/* location */}
          <span>{location}</span>
        </div>
        <div className="hidden md-full:block">
          <span className="text-_140C00 text-base leading-6 mb-1">
            {result?.org_name || `${result.first_name} ${result.last_name}`}
          </span>
          {result.bio && (
            <p className="text-_58534D text-sm leading-[16.8px]">
              {result.bio}
            </p>
          )}
          {location && (
            <div className="mt-2 flex space-x-2 items-center">
              <Marker />
              <span className="text-_928B80 text-[12px] leading-[14.4px]">
                {location}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="ml-4 flex-shrink-0 flex">
        {connection && connection.status === 'pending' ? (
          <PrimaryButton
            text="Pending"
            className="!h-8 !px-6 !bg-[#D2D1CF] !rounded-full"
            textClassName="!text-[rgb(146,139,128)]"
            disabled
          />
        ) : connection &&
          (connection.status === 'accepted' ||
            connection.status === 'pending') ? (
          <div />
        ) : (
          <PrimaryButton
            text="Connect"
            className="!h-8 !px-6 !rounded-3xl !w-fit ml-auto md-full:!ml-0"
            onClick={() => handleConnect(result)}
            loading={connecting}
          />
        )}
      </div>
    </div>
  )
}

export default SearchResultListItem

SearchResultListItem.propTypes = {
  result: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    bio: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    org_name: PropTypes.string
  }),
  hasBottomBorder: PropTypes.bool
}
