import React, { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useLazyGetAllUsersQuery } from '@services/users-service'
import { setUsers } from '@store/slices/users'

function Protected({ children }) {
  const { jwt } = useSelector(state => state.auth)
  const members = useSelector(state => state.users || [])
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const [getAllUsers] = useLazyGetAllUsersQuery()

  const fetchMaoneyUsers = async () => {
    try {
      const results = await getAllUsers(null, false).unwrap()
      dispatch(setUsers(results?.users || []))
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    /** Fetch users if user list is empty */
    if (jwt && !members.length) {
      fetchMaoneyUsers()
    }
  }, [])

  return jwt ? children : <Navigate to={`/login?next=${pathname}`} />
}
export default Protected
