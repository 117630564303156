import heic2any from 'heic2any'

const useImages = () => {
  const processImageFile = async file => {
    if (file.type === 'image/heic') {
      try {
        const result = await heic2any({ blob: file })
        const convertedBlob = new Blob([result], { type: 'image/jpeg' })
        return { file: convertedBlob, error: null }
      } catch (error) {
        return { file: null, error: 'Error Processing this image' }
      }
    } else {
      return { file, error: null }
    }
  }

  return {
    processImageFile
  }
}

export default useImages
