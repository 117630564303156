import clsx from 'clsx'
import PropTypes from 'prop-types'
import { PrimaryButton } from '@components/Buttons'

const SearchFilters = ({
  activeFilter,
  setActiveFilter,
  filters,
  activeClassName,
  activeTextClassName,
  inactiveClassName,
  inactiveTextClassName
}) => (
  <div className="space-x-5 703px:!space-x-4 flex">
    {Object.keys(filters).map((filterTypeKey, index) => {
      const filter = filters[filterTypeKey]
      const isActive = activeFilter === filter.id
      return (
        <PrimaryButton
          key={index}
          className={clsx(
            '!bg-_928B80/[.2] !h-8 !rounded-[99px]',
            {
              '!bg-primary': isActive
            },
            { [inactiveClassName]: !isActive, [activeClassName]: isActive }
          )}
          textClassName={clsx(
            'text-sm leading-[16.8px] text-_928B80',
            {
              '!text-white': isActive
            },
            {
              [inactiveTextClassName]: !isActive,
              [activeTextClassName]: isActive
            }
          )}
          text={filter.text}
          onClick={() => setActiveFilter(filter.id)}
        />
      )
    })}
  </div>
)

export default SearchFilters

SearchFilters.propTypes = {
  activeFilter: PropTypes.string,
  setActiveFilter: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.any),
  activeClassName: PropTypes.string,
  activeTextClassName: PropTypes.string,
  inactiveClassName: PropTypes.string,
  inactiveTextClassName: PropTypes.string
}
