import PropTypes from 'prop-types'

/** Errors that are tied to inputs. e.g If the user types in an invalid email
 *
 * error - A value to denote that there's an error
 *
 * text - A string (help text) to help the user resolve the error
 */
const InputError = ({ error, text, style }) => {
  if (!error) {
    return null
  }
  return (
    <p className="text-sm text-[#FF5018] mt-1" style={style}>
      {text}
    </p>
  )
}

export default InputError

InputError.propTypes = {
  error: PropTypes.any,
  text: PropTypes.string,
  style: PropTypes.any
}
