/* eslint-disable no-confusing-arrow */
import PropTypes from 'prop-types'
import clsx from 'clsx'
import LoadableImage from '@components/utils/LoadableImage'
import { useAppDispatch } from '@hooks/redux'
import { setImagesToDisplay } from '@store/slices/posts'

const PostCardImages = ({ images }) => {
  const dispatch = useAppDispatch()
  const showAllImages = index => {
    dispatch(setImagesToDisplay({ images, index }))
  }
  return (
    <div
      className={clsx('h-full w-full', {
        'grid gap-x-[6px] gap-y-[6px] grid-cols-2': images.length >= 2
      })}
    >
      {images.slice(0, 3).map((image, index) => (
        <div
          key={index}
          className={clsx('h-full w-full relative', {
            // when it's 3 images and this is the 1st image
            'row-span-2': images.length >= 3 && index === 0
          })}
        >
          <LoadableImage
            src={image}
            alt={image}
            onClick={e => {
              e.stopPropagation()
              showAllImages(index)
            }}
            className="h-full cursor-pointer w-full object-cover !rounded-[6px]"
          />
          {/* if it's the third image */}
          {index === 2 && images.length > 3 && (
            <div
              role="presentation"
              onClick={e => {
                e.stopPropagation()
                showAllImages(index)
              }}
              className="absolute top-0 cursor-pointer left-0 h-full w-full bg-_140C00/[.5] flex items-center justify-center rounded-[6px]"
            >
              <span className="font-bold text-white text-[18.05px] leading-[21.66px]">
                +{images.length - 3}
              </span>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default PostCardImages

PostCardImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string)
}
