import barcode from '@assets/icons/barcode.svg'
import copy from '@assets/icons/copy.svg'
import mail_outline from '@assets/icons/mail-outline.svg'
import share from '@assets/icons/share.svg'
import logo from '@assets/images/logo.png'
import Loader from '@components/Loaders/Loader'
import BarcodeModal from '@components/Modals/BarcodeModal'
import ShareModal from '@components/Modals/ShareModal'
import Modal from '@components/Modals/Modal'
import routes from '@routes/routesList'
import { getProfileImageUrl } from '@utils/helpers'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useDispatch, useSelector } from 'react-redux'
import { authenticateUser } from '@store/slices/auth'
import { updateWalkthoughMenu } from '@store/slices/app'
import SuccessAlertCard from '@components/utils/SuccessAlertCard'
import { useEditProfileMutation } from '@services/profile-service'
import useUserInfo from '@hooks/users/useUserInfo'
import { useAppSelector } from '@hooks/redux'

function InviteComponent({
  component,
  borderRadius = 'rounded-[12px]',
  borderRadiusRight = 'rounded-r-[12px]',
  borderRadiusLeft = 'rounded-l-[12px]',
  className = '',
  closeModal,
  isOnDashboard = false
}) {
  const dispatch = useDispatch()
  const [updateUserProfile] = useEditProfileMutation()

  const { walkthroughMenu: menu } = useAppSelector(state => state.app)
  const { user } = useSelector(state => state.auth)
  const [isCopied, setIsCopied] = useState(false)
  const { getMemberName } = useUserInfo()

  const [barcodeOpen, setBarcodeOpen] = useState(false)
  const [openShareModal, setOpenShareModal] = useState(false)
  const [isBtnClick, setIsBtnClick] = useState(false)

  const [walkthroughContinueLoader] = useState(false)
  // const { VITE_BASE_WEB_URL, VITE_APP_NAME } = import.meta.env;

  const isWalkthroughTaken = false
  const shareURL = useMemo(() => {
    let extras = `${routes.onboarding}?referred_by=`
    const isIndividual = user?.account_type === 'individual'
    if (isIndividual) {
      extras +=
        encodeURIComponent(
          user?.first_name?.trim()?.replaceAll(' ', '_') || ''
        ) +
        '_' +
        encodeURIComponent(user?.last_name?.trim()?.replaceAll(' ', '_') || '')
    } else {
      extras += encodeURIComponent(
        user.org_name?.trim()?.replaceAll(' ', '_') || ''
      )
    }
    extras += `&referral_code=${user?.referral_code || ''}`
    return window.location.origin + extras
  }, [user])

  const shareMessage = useMemo(
    () =>
      `${getMemberName(user)} is inviting you to Dream Bigger, Grow Faster together at Maoney. Click the link below to Signup`,
    [user]
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCopied(false)
    }, 2000)
    return () => clearTimeout(timer)
  }, [isCopied])

  const handleUpdateTour = async () => {
    setIsBtnClick(true)
    const formData = new FormData()
    formData.append('user', JSON.stringify({ id: user?.id }))
    formData.append('tourCompleted', true)
    formData.append('first_name', user.first_name)
    formData.append('last_name', user.last_name)
    formData.append('org_name', user.org_name)
    try {
      updateUserProfile(formData)
        .then(({ data }) => {
          const newData = {
            ...data,
            picture: getProfileImageUrl(data?.picture)
          }
          closeModal()
          dispatch(updateWalkthoughMenu('Walkthrough Complete'))
          dispatch(authenticateUser({ user: newData }))
        })
        .catch(() => {
          setIsBtnClick(false)
        })
    } catch (error) {
      setIsBtnClick(false)
    }
  }

  return (
    <div
      className="flex flex-col justify-center items-center w-full"
      data-testid="invite-component"
    >
      <Modal
        size="max-w-lg"
        content={<ShareModal link={shareURL} message={shareMessage} />}
        open={openShareModal}
        setOpen={setOpenShareModal}
        title={'Share to'}
      />
      <Modal
        size="max-w-lg"
        open={barcodeOpen}
        setOpen={setBarcodeOpen}
        title={'QR Code'}
        content={<BarcodeModal link={shareURL} logo={logo} />}
      />
      <br />

      {isCopied && (
        <SuccessAlertCard isHideButton={true} msg="Link copied successfully" />
      )}

      <div
        className={clsx(
          'relative border-[#928B80] border-solid w-full',
          className,
          borderRadius
        )}
      >
        <input
          className={clsx(
            `w-full border-[#928B80] border border-r-0 h-12
          focus:outline-none px-2`,
            borderRadiusLeft,
            borderRadiusRight
          )}
          style={{ color: '#646360' }}
          readOnly
          placeholder="https://www.maoney.com/ada..."
          value={shareURL}
          disabled
        />

        <CopyToClipboard
          text={shareURL}
          onCopy={() => {
            setIsCopied(true)
          }}
        >
          <button
            title="Click to copy"
            className={clsx(
              `bg-[#FF9502] text-white h-[48px] flex items-center justify-center gap-2 absolute right-0 top-0`,
              borderRadiusRight,
              borderRadiusLeft
            )}
          >
            <div className="pl-4">Copy</div>
            <img
              className="cursor-pointer pr-4 text-white font-extralight"
              color="#140C00"
              src={copy}
            />
          </button>
        </CopyToClipboard>
      </div>
      <div className="mt-6 w-full">
        <p className="text-[#140C00] text-[18px] xs:text-base font-bold text-center">
          Or Share With
        </p>
        <div className="mt-6 flex justify-around  text-[#58534D] items-center w-full">
          {/* <a
            target={"_blank"}
            href={`mailto:${email}?&subject=Join Maoney today&body=Click the link to join ${data.value}`}
          >
            <div className="flex flex-col justify-center items-center align-middle cursor-pointer">
              <img className="w-[45px] h-[45px]" src={mail_outline} />
              <p className="text-[18px]">Email</p>
            </div>
          </a> */}

          <a
            target={'_blank'}
            // href="#"
            href={`https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to=&body=${shareMessage}%0A%0AClick%20this%20link%20to%20visit%20the%20website:%20${encodeURIComponent(shareURL)}`}
            rel="noreferrer"
          >
            <div className="flex flex-col justify-center items-center cursor-pointer gap-2">
              <img className="w-[45px] h-[45px]" src={mail_outline} />
              <p className="xs:text-sm text-[#58534D]">Email</p>
            </div>
          </a>

          <div
            onClick={() => setBarcodeOpen(true)}
            className="flex flex-col justify-center items-center cursor-pointer gap-2"
          >
            <img className="w-[45px] h-[45px]" src={barcode} />
            <p className="xs:text-sm text-[#58534D]">QR Code</p>
          </div>
          {/* <div className="flex flex-col justify-center items-center align-middle">
                <img className="w-[45px] h-[45px]" src={sms} />
                <p className="text-[18px]">SMS</p>
              </div> */}
          <div
            onClick={() => setOpenShareModal(true)}
            className="flex flex-col justify-center items-center cursor-pointer gap-2"
          >
            <img className="w-[45px] h-[45px]" src={share} />
            <p className="xs:text-sm text-[#58534D]">Others</p>
          </div>
        </div>
      </div>
      {menu === 'Invite Modal' && (
        <div className="mx-auto mb-4 mt-6">
          <div className="flex items-center justify-center gap-2">
            <div className="w-2 h-2 rounded-full bg-[#928B8066]"></div>
            <div className="w-2 h-2 rounded-full bg-[#928B8066]"></div>
            <div className="w-2 h-2 rounded-full bg-[#FF9502]"></div>
          </div>
        </div>
      )}
      {!isWalkthroughTaken && !isOnDashboard && (
        <div className="mt-4 w-full">
          {walkthroughContinueLoader ? (
            <Loader type="regular" label="loading..." />
          ) : (
            <button
              disabled={isBtnClick}
              // className="bg-[#FF9502] h-[54px] w-full text-[18px] text-white rounded-xl "
              className={`h-[54px] w-full text-[18px] text-white rounded-xl ${
                isBtnClick
                  ? 'bg-[#D9D9D9] cursor-not-allowed'
                  : 'bg-[#FF9502] cursor-pointer'
              }`}
              onClick={handleUpdateTour}
            >
              Done
            </button>
          )}
        </div>
      )}
      {component}
    </div>
  )
}

InviteComponent.propTypes = {
  //  component,
  // borderRadius = 'rounded-[12px]',
  // borderRadiusRight = 'rounded-r-[12px]',
  // borderRadiusLeft = 'rounded-l-[12px]',
  // className = '',
  // closeModal,
  // isOnDashboard = false

  component: PropTypes.node,
  borderRadius: PropTypes.string,
  borderRadiusRight: PropTypes.string,
  borderRadiusLeft: PropTypes.string,
  className: PropTypes.string,
  closeModal: PropTypes.any,
  isOnDashboard: PropTypes.bool
}

export default InviteComponent
