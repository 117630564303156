import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  connections: [],
  topTenSocial: [],
  connectionRequestToCancel: { visible: false, populatedConnection: null }
}

/** Reducer to store the connections of the currently logged in user */
const connectionsSlice = createSlice({
  name: 'connections',
  initialState,
  reducers: {
    setConnections: (state, action) => {
      state.connections = action.payload
    },
    setTopTenSocial: (state, action) => {
      state.topTenSocial = action.payload
    },
    setConnectionRequestToCancel: (state, action) => {
      state.connectionRequestToCancel = {
        visible: true,
        populatedConnection: action.payload
      }
    },
    clearConnectionRequestToCancel: state => {
      state.connectionRequestToCancel = {
        visible: false,
        populatedConnection: state.connectionRequestToCancel.populatedConnection
      }
    },
    invalidateConnection: (state, action) => {
      const connectionId = action.payload
      const newConnections = [...state.connections].map(conn => {
        if (conn.id === connectionId) {
          return { ...conn, invalid: true }
        }
        return conn
      })
      state.connections = newConnections
    },
    reValidateConnection: (state, action) => {
      const connectionId = action.payload
      const newConnections = [...state.connections].map(conn => {
        if (conn.id === connectionId) {
          return { ...conn, invalid: false }
        }
        return conn
      })
      state.connections = newConnections
    },
    removeConnection: (
      state,
      action
      // action: PayloadAction<{ connectionId: number }>
    ) => {
      const connectionId = action.payload
      const newConnections = [...state.connections].filter(
        conn => conn.id !== connectionId
      )
      state.connections = newConnections
    },
    editConnectionMuteStatus: (
      state,
      action
      // action: PayloadAction<{ connectionId: number; newState: boolean }>
    ) => {
      const { connectionId, newState } = action.payload
      const newConnections = [...state.connections].map(conn => {
        if (conn.id === connectionId) {
          return { ...conn, muted: newState }
        }
        return conn
      })
      state.connections = newConnections
    },
    editConnectionStatus: (
      state,
      action
      // action: PayloadAction<{
      //   connectionId: number
      //   newState: 'accepted' | 'rejected'
      // }>
    ) => {
      const { connectionId, newState } = action.payload
      const newConnections = [...state.connections].map(conn => {
        if (conn.id === connectionId) {
          return { ...conn, status: newState }
        }
        return conn
      })
      state.connections = newConnections
    },
    insertConnection: (state, action) => {
      state.connections = [...state.connections, action.payload]
    }
  }
})

export const {
  setConnections,
  setTopTenSocial,
  setConnectionRequestToCancel,
  clearConnectionRequestToCancel,
  invalidateConnection,
  reValidateConnection,
  removeConnection,
  editConnectionMuteStatus,
  editConnectionStatus,
  insertConnection
} = connectionsSlice.actions

export default connectionsSlice.reducer
