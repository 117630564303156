import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import toast from 'react-hot-toast'

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
const firebaseConfig = {
  apiKey: 'AIzaSyBdoPLDevyLLNFCZxbc5FRevBKAlzxMmjI',
  authDomain: 'maoney2.firebaseapp.com',
  projectId: 'maoney2',
  storageBucket: 'maoney2.appspot.com',
  messagingSenderId: '404000784587',
  appId: '1:404000784587:web:21984e6c279cdfcf161b4e',
  measurementId: 'G-TH8FPR74QB'
}

const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)
const setupNotifications = async () => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission()

    if (permission === 'granted') {
      // Get the FCM token
      const token = await getToken(messaging)
    } else {
      // console.log('Notification permission denied.')
    }
    // Handle foreground notifications
    onMessage(messaging, payload => {
      toast.success(
        `${payload?.notification?.title}: ${payload?.notification?.body}`
      )
      // Handle the notification or update your UI
    })
  } catch (error) {
    // console.error('Error setting up notifications:', error)
  }
}

const getFcmToken = async () => {
  try {
    const token = await getToken(messaging)
    return token
  } catch (e) {
    return ''
  }
}

export { messaging, setupNotifications, getFcmToken }
