/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { ReactComponent as Camera } from '@assets/icons/camera.svg'
import { ReactComponent as CameraWhite } from '@assets/icons/camera-white.svg'
import next from '@assets/icons/next.svg'
import prev from '@assets/icons/prev.svg'
import { getImagePreviewUrl } from './utils'
import toast from 'react-hot-toast'
import { acceptedImages, maxPostImgCount } from '@constants/posts'
import PostImageDots from './PostImageDots'
import useImages from '@hooks/general/useImages'
import Loader from '@components/Loader'

const PostImagePreview = ({ imgFile }) => {
  const blob = getImagePreviewUrl(imgFile)
  return (
    <img
      src={blob}
      alt="Blob for post image"
      data-testid="post-image-blob"
      className="h-full w-full flex-shrink-0 object-cover select-none rounded-[6px]"
    />
  )
}

const PostImageUploadError = ({ onRetry }) => (
  <div className="w-full h-[185px] bg-white rounded-[12px] border border-FF5018 flex flex-col items-center justify-center space-y-2">
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_18997_71819)">
        <path
          d="M41.527 19.7611H36.5842L33.6258 16.7522C33.6258 16.7522 33.6112 16.7375 33.6039 16.7375L33.5892 16.7227C33.1499 16.2802 32.5567 16 31.883 16H25.732C25.0144 16 24.3773 16.3097 23.9306 16.8038V16.8112L21.0382 19.7611H15.9856C14.6235 19.7611 13.5251 20.8378 13.5251 22.2094V37.1136C13.5251 38.4852 14.6235 39.5988 15.9856 39.5988H41.527C42.8817 39.5988 43.9874 38.4852 43.9874 37.1136V22.2094C43.9874 20.8378 42.8817 19.7611 41.527 19.7611ZM28.7563 35.8746C25.029 35.8746 21.9901 32.8068 21.9901 29.0457C21.9901 25.2773 25.029 22.2168 28.7563 22.2168C32.4908 22.2168 35.5224 25.2773 35.5224 29.0457C35.5224 32.8068 32.4908 35.8746 28.7563 35.8746ZM41.0656 23.7065C40.5018 23.7065 40.0405 23.2419 40.0405 22.6667C40.0405 22.0914 40.5018 21.6268 41.0656 21.6268C41.6295 21.6268 42.0908 22.0914 42.0908 22.6667C42.0908 23.2419 41.6295 23.7065 41.0656 23.7065Z"
          fill="#FF5018"
        />
        <path
          d="M28.7555 23.8838C25.929 23.8838 23.6443 26.192 23.6443 29.046C23.6443 31.8926 25.929 34.2083 28.7555 34.2083C31.5747 34.2083 33.8667 31.9 33.8667 29.046C33.8667 26.192 31.5747 23.8838 28.7555 23.8838Z"
          fill="#FF5018"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5 3.73333C22.0641 3.73333 15.8918 6.28999 11.3409 10.8409C6.78999 15.3918 4.23333 21.5641 4.23333 28C4.23333 34.4359 6.78999 40.6082 11.3409 45.1591C15.8918 49.71 22.0641 52.2667 28.5 52.2667C34.9359 52.2667 41.1082 49.71 45.6591 45.1591C50.21 40.6082 52.7667 34.4359 52.7667 28C52.7667 21.5641 50.21 15.3918 45.6591 10.8409C41.1082 6.28999 34.9359 3.73333 28.5 3.73333ZM0.5 28C0.5 20.5739 3.44999 13.452 8.70101 8.20101C13.952 2.94999 21.0739 0 28.5 0C35.9261 0 43.048 2.94999 48.299 8.20101C53.55 13.452 56.5 20.5739 56.5 28C56.5 35.4261 53.55 42.548 48.299 47.799C43.048 53.05 35.9261 56 28.5 56C21.0739 56 13.952 53.05 8.70101 47.799C3.44999 42.548 0.5 35.4261 0.5 28Z"
        fill="#FF5018"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.3024 8.20181C48.6523 8.55186 48.8489 9.02657 48.8489 9.52154C48.8489 10.0165 48.6523 10.4912 48.3024 10.8413L11.3424 47.8013C11.1702 47.9796 10.9642 48.1218 10.7365 48.2196C10.5087 48.3174 10.2638 48.3689 10.0159 48.3711C9.76809 48.3732 9.52229 48.326 9.29288 48.2321C9.06347 48.1383 8.85506 47.9997 8.67979 47.8244C8.50452 47.6491 8.36592 47.4407 8.27206 47.2113C8.1782 46.9819 8.13097 46.7361 8.13313 46.4883C8.13528 46.2404 8.18678 45.9955 8.28461 45.7677C8.38244 45.54 8.52464 45.334 8.70293 45.1618L45.6629 8.20181C46.013 7.85186 46.4877 7.65527 46.9827 7.65527C47.4776 7.65527 47.9523 7.85186 48.3024 8.20181Z"
        fill="#FF5018"
      />
      <defs>
        <clipPath id="clip0_18997_71819">
          <rect
            width="32"
            height="25"
            fill="white"
            transform="translate(12.5 16)"
          />
        </clipPath>
      </defs>
    </svg>
    <div>
      <p className="text-base leading-6 text-center text-FF5018">
        You can&apos;t upload more than 10 Pictures.
      </p>
      <div className="flex items-center justify-center">
        <button
          onClick={onRetry}
          className="underline text-FF5018 font-bold text-base leading-[19.2px] text-center"
          type="button"
        >
          Click to try again
        </button>
      </div>
    </div>
  </div>
)

const PostImageSelect = ({
  setter,
  images = [],
  processingImages,
  setProcessingImages
}) => {
  const [hasMounted, setHasMounted] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)
  const [postError, setPostError] = useState(false)
  const { processImageFile } = useImages()

  const newImagePickerRef = useRef(null)
  const editImagePickerRef = useRef(null)

  const hasSpaceForMore = images.length < maxPostImgCount

  const addNewImages = async event => {
    const allowedNoOfImages = maxPostImgCount - images.length
    if (
      Array.from(event.target.files).length > maxPostImgCount ||
      allowedNoOfImages === 0
    ) {
      setPostError(true)
      return
    }
    const files = Array.from(event.target.files).slice(0, allowedNoOfImages)
    const goodImages = files.filter(file => !!getImagePreviewUrl(file))
    const processedImages = []
    setProcessingImages(true)
    await Promise.all(
      goodImages.map(async goodImage => {
        const { file, error } = await processImageFile(goodImage)
        if (file) {
          processedImages.push(file)
        }
        if (error) {
          console.log({ error })
        }
      })
    )
    setProcessingImages(false)
    setter([...images, ...processedImages])
    if (processedImages.length < files.length) {
      toast.error('There was an issue processing some images')
      return
    }
  }

  const editImage = event => {
    const file = event.target.files[0]
    if (getImagePreviewUrl(file)) {
      const newImages = images.map((img, index) => {
        if (index === imageIndex) {
          return file
        }
        return img
      })
      setter(newImages)
    } else {
      toast.error('There was an issue processing this image')
    }
  }

  const removeImage = () => {
    const newImages = images.filter((img, index) => index !== imageIndex)
    setter(newImages)
  }

  const isEmpty = images.length === 0

  useEffect(() => {
    if (images.length && hasMounted) {
      setImageIndex(images.length - 1)
    }
  }, [images?.length])

  useEffect(() => {
    if (!hasMounted) {
      setTimeout(() => {
        setHasMounted(true)
      }, 1000)
    }
  }, [])

  return (
    <>
      {postError && (
        <PostImageUploadError
          onRetry={() => {
            setPostError(false)
          }}
        />
      )}
      <div
        className={clsx(
          'w-[380px] relative transition-all duration-200 overflow-hidden',
          {
            'h-[185px] rounded-[12px]': !isEmpty,
            'border border-_928B80 rounded-lg h-[54px] shadow-[0px_2px_16px_0px_rgba(0,0,0,0.08)]':
              isEmpty,
            hidden: postError
          }
        )}
      >
        {/* no images */}
        {isEmpty && (
          <div
            className="flex items-center h-full justify-center space-x-4 cursor-pointer"
            onClick={() => newImagePickerRef?.current?.click()}
          >
            <span className="text-_58534D text-base">Visualize your Post</span>
            <Camera />
          </div>
        )}
        {/* when there are images */}
        {!isEmpty && (
          <div
            className="h-full w-full flex no-scrollbar transition-all duration-200"
            style={{ transform: `translateX(-${imageIndex * 100}%)` }}
          >
            {images.map((image, index) => (
              <PostImagePreview
                key={index}
                imgFile={image}
                onImgClick={() => {
                  editImagePickerRef?.current?.click()
                }}
              />
            ))}
          </div>
        )}
        {/* image pickers */}
        <input
          type="file"
          data-testid="post-image-input"
          accept={acceptedImages}
          multiple={hasSpaceForMore}
          onChange={e => {
            addNewImages(e)
          }}
          ref={newImagePickerRef}
          className="opacity-0 pointer-events-none hidden"
        />
        <input
          type="file"
          data-testid="post-image-edit-input"
          accept={acceptedImages}
          onChange={e => {
            editImage(e)
          }}
          ref={editImagePickerRef}
          className="opacity-0 pointer-events-none hidden"
        />
        {/* overlay */}
        {processingImages && (
          <div className="absolute z-[3] top-0 left-0 rounded-[6px] w-full h-full bg-_140C00/[.3] flex items-center justify-center">
            <div role="status">
              <svg
                aria-hidden="true"
                className="w-7 h-7 animate-spin text-white fill-primary/[.6]"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          </div>
        )}
        {!isEmpty && (
          <>
            <div
              className="absolute top-0 left-0 rounded-[6px] w-full h-full bg-_140C00/[.3] flex items-center justify-center"
              onClick={() => editImagePickerRef?.current?.click()}
            >
              {hasSpaceForMore && (
                <button
                  type="button"
                  className="relative"
                  onClick={e => {
                    e.stopPropagation()
                    newImagePickerRef?.current?.click()
                  }}
                >
                  <CameraWhite />

                  <div className="absolute -top-[3px] -right-[6.51px] h-3 w-3 rounded-full flex items-center justify-center bg-primary">
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.38837 1.30612C5.3884 1.2552 5.3784 1.20477 5.35894 1.15772C5.33949 1.11066 5.31095 1.0679 5.27497 1.03187C5.23898 0.995846 5.19626 0.967258 5.14923 0.947743C5.10219 0.928228 5.05178 0.918167 5.00086 0.918135C4.94994 0.918103 4.89952 0.928102 4.85246 0.947558C4.80541 0.967015 4.76264 0.995549 4.72662 1.03153C4.69059 1.06751 4.662 1.11024 4.64249 1.15727C4.62297 1.2043 4.61291 1.25472 4.61288 1.30564L4.61291 4.61253L1.3065 4.61202C1.2036 4.61202 1.10491 4.6529 1.03215 4.72566C0.959394 4.79842 0.918518 4.8971 0.918519 5C0.91852 5.1029 0.959397 5.20158 1.03216 5.27435C1.10492 5.34711 1.2036 5.38798 1.3065 5.38799L4.61292 5.38754L4.61247 8.69395C4.61247 8.7449 4.6225 8.79535 4.642 8.84242C4.6615 8.8895 4.69008 8.93227 4.72611 8.96829C4.76213 9.00432 4.8049 9.0329 4.85198 9.0524C4.89905 9.0719 4.9495 9.08193 5.00045 9.08193C5.10335 9.08193 5.20203 9.04106 5.27479 8.9683C5.31082 8.93227 5.3394 8.8895 5.3589 8.84243C5.37839 8.79536 5.38843 8.74491 5.38843 8.69396L5.38792 5.38754L8.69482 5.38757C8.79765 5.38751 8.89625 5.34659 8.96892 5.27383C9.04159 5.20107 9.08238 5.10243 9.08232 4.99959C9.08225 4.89675 9.04134 4.79816 8.96858 4.72549C8.89582 4.65281 8.79717 4.61202 8.69433 4.61208L5.38792 4.61253L5.38837 1.30612Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </button>
              )}
            </div>
            <PostImageDots
              images={images}
              activeImageIndex={imageIndex}
              onClickDot={idx => setImageIndex(idx)}
            />
          </>
        )}
        {/* controls */}
        {!isEmpty && (
          <>
            <img
              src={prev}
              className={clsx(
                'post-prev-btn cursor-pointer absolute transform -translate-y-1/2 top-1/2 left-2',
                {
                  'opacity-0 pointer-events-none': imageIndex <= 0
                }
              )}
              onClick={e => {
                e.stopPropagation()
                setImageIndex(imageIndex - 1)
              }}
            />
            <img
              src={next}
              className={clsx(
                'post-next-btn cursor-pointer absolute transform translate-y-1/2 top-1/3 right-2',
                {
                  'opacity-0 pointer-events-none': !(
                    imageIndex <
                    images?.length - 1
                  )
                }
              )}
              onClick={e => {
                e.stopPropagation()
                setImageIndex(imageIndex + 1)
              }}
            />
          </>
        )}
        {/* remove image */}
        {!isEmpty && (
          <button
            className="absolute top-1 right-1 bg-[#FF5018] h-4 w-4 rounded-full flex items-center justify-center"
            type="button"
            onClick={removeImage}
          >
            <svg
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.84884 0.883106C7.89688 0.835128 7.93499 0.778159 7.96101 0.71545C7.98703 0.652742 8.00045 0.585522 8.00049 0.517629C8.00053 0.449737 7.9872 0.382501 7.96126 0.31976C7.93531 0.25702 7.89727 0.200003 7.84929 0.151966C7.80132 0.10393 7.74435 0.0658131 7.68164 0.0397932C7.61893 0.0137733 7.55171 0.000359507 7.48382 0.000317778C7.41593 0.000276049 7.34869 0.0136072 7.28595 0.03955C7.22322 0.0654928 7.1662 0.103539 7.11816 0.151517L4.00042 3.26931L0.88358 0.151517C0.786567 0.0545022 0.654989 -1.02222e-09 0.517792 0C0.380594 1.02222e-09 0.249016 0.0545022 0.152003 0.151517C0.0549896 0.248532 0.000488282 0.380112 0.000488281 0.517312C0.00048828 0.654511 0.0549896 0.786091 0.152003 0.883106L3.26974 4L0.152003 7.11689C0.103967 7.16493 0.0658625 7.22196 0.0398656 7.28472C0.0138686 7.34748 0.000488281 7.41475 0.000488281 7.48269C0.000488281 7.55062 0.0138686 7.61789 0.0398656 7.68065C0.0658625 7.74342 0.103967 7.80044 0.152003 7.84848C0.249016 7.9455 0.380594 8 0.517792 8C0.585725 8 0.652993 7.98662 0.715755 7.96062C0.778517 7.93462 0.835544 7.89652 0.88358 7.84848L4.00042 4.73069L7.11816 7.84848C7.21518 7.94538 7.34671 7.99977 7.48382 7.99968C7.62093 7.9996 7.7524 7.94505 7.84929 7.84803C7.94619 7.75102 8.00057 7.61949 8.00049 7.48237C8.0004 7.34525 7.94586 7.21379 7.84884 7.11689L4.7311 4L7.84884 0.883106Z"
                fill="white"
              />
            </svg>
          </button>
        )}
      </div>
    </>
  )
}

export default PostImageSelect
