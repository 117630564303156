import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { authService } from '@services/auth-service'
import { connectionsService } from '@services/connections-service'
import { postsService } from '@services/posts-service'
import { profileService } from '@services/profile-service'
import { usersService } from '@services/users-service'
import appReducer from './slices/app'
import authReducer from './slices/auth'
import connectionsReducer from './slices/connections'
import mutesReducer from './slices/mutes'
import notifReducer from './slices/notif'
import notificationsReducer from './slices/notifications'
import postsReducer from './slices/posts'
import timerReducer from './slices/timer'
import usersReducer from './slices/users'

// persist
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  connections: connectionsReducer,
  mutes: mutesReducer,
  notifications: notificationsReducer,
  notif: notifReducer,
  posts: postsReducer,
  timer: timerReducer,
  users: usersReducer,

  [authService.reducerPath]: authService.reducer,
  [connectionsService.reducerPath]: connectionsService.reducer,
  [postsService.reducerPath]: postsService.reducer,
  [usersService.reducerPath]: usersService.reducer,
  [profileService.reducerPath]: profileService.reducer
})

const persistConfig = {
  key: 'maoney',
  storage,
  blacklist: ['postsService', 'connections', 'connectionsService', 'posts']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredActionPaths: ['meta.baseQueryMeta', 'meta.arg']
      }
    }).concat([
      authService.middleware,
      connectionsService.middleware,
      postsService.middleware,
      usersService.middleware,
      profileService.middleware
    ])
  },
  devTools: import.meta.env.VITE_ENV !== 'production'
})

if (window.Cypress) {
  window['store'] = store
}

// eslint-disable-next-line arrow-body-style
export const setupStore = preloadedState => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware(getDefaultMiddleware) {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          ignoredActionPaths: ['meta.baseQueryMeta', 'meta.arg']
        }
      }).concat([
        authService.middleware,
        connectionsService.middleware,
        postsService.middleware,
        usersService.middleware,
        profileService.middleware
      ])
    }
  })
}

const persistor = persistStore(store)
export { persistor, store }
