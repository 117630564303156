import { ReactComponent as HomeBlack } from '@assets/icons/home_black.svg'
import new_post from '@assets/icons/newPost.svg'

export const SmallScreenList = ({ onPostTrigger }) => (
  <>
    <li className=" px-5" data-testid="dashboard-menu-Home">
      <a
        href="/dashboard/home"
        className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent py-[6px]"
      >
        <div className="mr-3">
          <HomeBlack className="w-6 h-6" />
        </div>
        Home
      </a>
    </li>
    <hr className="border-[#928B804D]" />
    <li className=" px-5" data-testid="dashboard-menu-Post">
      <a
        onClick={onPostTrigger}
        href="#"
        className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent py-[6px]"
      >
        <div className="mr-3">
          <img src={new_post} className="w-6 h-6" />
        </div>
        Post
      </a>
    </li>
    {/* <hr className="border-[#928B804D]" />
      <li className=" px-5">
        <a
          href="#"
          className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent py-[6px]"
        >
          <div className="mr-3">
            <img className="w-6 h-6" src={iconBanking} />
          </div>
          Banking
        </a>
      </li> */}
    <hr className="border-[#928B804D]" />
    {/* <li className=" px-5">
        <a
          href="#"
          className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent py-[6px]"
        >
          <div className="mr-3">
            <img className="w-6 h-6" src={comment} />
          </div>
          Chats
        </a>
      </li>
      <hr className="border-[#928B804D]" /> */}
    {/* <li className=" px-5">
        <a
          href="#"
          className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent py-[6px]"
        >
          <div className="mr-3">
            <img className="w-6 h-6" src={iconBell} />
          </div>
          Notifications
        </a>
      </li> */}
    {/* <hr className="border-[#928B804D]" /> */}
  </>
)
