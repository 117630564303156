/* eslint-disable react-hooks/exhaustive-deps */
import arrow_down from '@assets/icons/arrow_down.svg'
import iconBell from '@assets/icons/bell.svg'
import bellActive from '@assets/icons/bellActive.svg'
import home from '@assets/icons/home-bw.svg'
import home_color from '@assets/icons/home.svg'
import { ReactComponent as CloseIcon } from '@assets/icons/menu-close.svg'
import { ReactComponent as MenuIcon } from '@assets/icons/menu.svg'
import { ReactComponent as NewPost } from '@assets/icons/newPost.svg'
import new_post from '@assets/icons/new_post.svg'
import { OnboardLogo } from '@components/utils/Logo'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal as Models } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import InviteModal from '../Modals/InviteModal'
import Modal from '../Modals/Modal'

import bell from '@assets/icons/bell-black.svg'
import logout from '@assets/icons/logout.svg'
import settings from '@assets/icons/settings-white.svg'
import { SearchBar } from '@components/pages/Dashboard/Search'
import InviteComponent from '@components/pages/Dashboard/home/InviteComponent'
import CreatePost from '@components/posts/create'
import MenuComponent from '@components/utils/MenuComponent'
import UserBrand from '@components/utils/UserBrand'
import useConnections from '@hooks/connections/useConnections'
import useAppSize from '@hooks/general/useAppSize'
import usePosts from '@hooks/posts/usePosts'
import { updateWalkthoughMenu } from '@store/slices/app'
import { logUserOut } from '@store/slices/auth'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { getLoggedInPersonName } from '../../utils/helpers'
import MenuModalHOC from '../Modals/MenuComponents/MenuModalHOC'
import Tour from '../Tour'
import UserProfileImage from '../utils/UserAvatar'
import MobileMenu from './MobileMenu'
import { SmallScreenList } from './SmallScreenList'

export const dashboardMenuData = [
  {
    name: 'Notifications',
    url: '/dashboard/notifications',
    icon: bell,
    testid: 'notifications-link'
  },
  {
    name: 'Settings',
    url: '/dashboard/settings/profile',
    icon: settings,
    testid: 'settings-link'
  },
  {
    name: 'Logout',
    url: '',
    icon: logout,
    testid: 'logout-link'
  }
]

function Header() {
  const [menuOpen, setMenuOpen] = useState(false)
  const [inviteOpen, setInviteOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const dropdownRef = useRef(null)
  const { isMobile, isTablet, isDesktop } = useAppSize()
  const [postOpen, setPostOpen] = useState(false)
  const { postToUpdate } = usePosts()
  const { walkthroughMenu: menu } = useSelector(state => state.app)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { connections } = useConnections()
  const dashboardData = dashboardMenuData
  const isDashboard = location.pathname === '/dashboard/home'
  const isNotifs = location.pathname === '/dashboard/notifications'
  const notifs = useSelector(state => state.notifications)

  const filteredNotifs = useMemo(() => {
    const topicsWithUserCheck = [
      'post_comment_edited',
      'event_register',
      'added_progress_post',
      'edited_post',
      'created_post',
      'post_comment_added'
    ]

    const validUserIds = new Set(
      connections?.map(item => [item.sender_id, item.receiver_id]).flat() || []
    )

    return notifs.filter(item => {
      if (topicsWithUserCheck.includes(item.topic)) {
        return validUserIds.has(item.user_id)
      }
      return true
    })
  }, [notifs, connections])

  const unreadCount = filteredNotifs?.filter(notif => !notif?.isRead).length

  const { user } = useSelector(state => state.auth)
  const { isEmailVerified, city = '', country = '' } = user || {}

  const openMenuDialog = menu => {
    dispatch(updateWalkthoughMenu(menu))
    setOpenModal(true)
  }

  useEffect(() => {
    if (postToUpdate?.data) {
      setPostOpen(true)
    } else {
      !postOpen && setPostOpen(false)
    }
  }, [postToUpdate])

  useEffect(() => {
    if (menu === 'Create Post') {
      setPostOpen(true)
    } else if (menu === 'Invite Modal') {
      setInviteOpen(true)
    } else {
      setInviteOpen(false)
      setPostOpen(false)
    }
  }, [menu])

  //menu closes when user clicks anywhere outside menu
  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setMenuOpen(false)
    }
  }
  useEffect(() => {
    if (!isMobile) {
      setMenuOpen(false)
    }
  }, [isMobile])

  const handleCloseModal = () => {
    setInviteOpen(false)
  }
  const name = getLoggedInPersonName(user)
  return (
    <div className="flex justify-center items-center pt-[120px] xs:pt-[70px]">
      <Tour />
      <>
        <Models open={postOpen} setOpen={setPostOpen}>
          <CreatePost open={postOpen} onClose={() => setPostOpen(false)} />
        </Models>
      </>

      <Modal
        open={inviteOpen}
        setOpen={setInviteOpen}
        onClose={() => {}}
        size="max-w-6xl"
        title=""
        content={
          <InviteModal>
            <InviteComponent
              borderRadius="rounded-[10px]"
              borderRadiusRight="rounded-r-[10px]"
              borderRadiusLeft="rounded-l-[10px]"
              closeModal={handleCloseModal}
            />
          </InviteModal>
        }
      />
      <MenuModalHOC open={openModal} setOpen={setOpenModal} />
      <nav
        className="bg-white  border-gray-200 
          px-2  fixed top-0 z-[99] h-[120px] xs:h-[70px]
       font-lato shadow-md drop-shadow-sm  w-full 
       flex justify-between items-center"
      >
        <div className="flex items-center justify-between w-full mr-10 xs:mr-6 max-w-7xl xl:mx-auto">
          <div
            data-testid="header-logo-wrapper"
            className="flex align-middle
           justify-center items-center gap-10 mr-5"
          >
            <Link
              to={'/'}
              className="flex items-center justify-start mx-10 xs:mx-6 xl:ml-0"
            >
              <OnboardLogo
                width="w-[36px]"
                height="h-[36px]"
                vertical={isDesktop ? true : false}
              />
            </Link>
            {(isDesktop || isTablet) && (
              <div
                className={
                  'flex justify-between items-center align-middle rounded-xl'
                }
              >
                <div className="703px:block hidden">
                  <SearchBar />
                </div>
              </div>
            )}
          </div>
          {isDesktop ? (
            <div
              className="flex items-center justify-start
             align-middle font-lato gap-14"
            >
              <div
                data-testid="desktop-header-home-link"
                onClick={() => navigate('/dashboard/home')}
                className="flex flex-col items-center cursor-pointer gap-1 px-0.5 pb-1"
              >
                {isDashboard ? (
                  <img
                    className={`text-[#928B80] w-6 h-6  hover:text-[#928B99]/90 `}
                    src={home_color}
                  />
                ) : (
                  <img
                    className={`text-[#928B80] w-6 h-6  hover:text-[#928B99]/90 `}
                    src={home}
                  />
                )}

                <p
                  className={` text-sm ${
                    isDashboard ? ' text-[#FF9502]' : 'text-[#928B80]'
                  }`}
                >
                  Home
                </p>
              </div>

              <div
                data-testid="create-post-btn"
                onClick={() => {
                  setPostOpen(true)
                }}
                className="flex flex-col items-center cursor-pointer gap-1 px-0.5 pb-1"
              >
                <img
                  className="text-[#928B80] w-6 h-6  hover:text-[#928B99]/90"
                  src={new_post}
                />

                <p className="text-[#928B80] text-sm">Post</p>
              </div>
              <div
                data-testid="notifications-btn"
                style={{ position: 'relative' }}
                onClick={() => navigate('/dashboard/notifications')}
                className="flex flex-col items-center cursor-pointer gap-1 px-0.5 pb-1"
              >
                {unreadCount > 0 && (
                  <span
                    style={{
                      backgroundColor: '#FF9502',
                      color: '#fff',
                      borderRadius: '1000px',
                      position: 'absolute',
                      right: '15px',
                      top: '-10px',
                      width: '20px',
                      height: '20px',
                      alignItems: 'center',
                      textAlign: 'center',
                      fontSize: '14px'
                    }}
                  >
                    {unreadCount}
                  </span>
                )}
                {isNotifs ? (
                  <>
                    <img
                      className={`text-[#928B80] w-6 h-6  hover:text-[#928B99]/90 `}
                      src={bellActive}
                    />

                    <p className={` text-sm text-[#FF9502]`}>Notifications</p>
                  </>
                ) : (
                  <>
                    <img
                      className={`text-[#928B80] w-6 h-6  hover:text-[#928B99]/90 `}
                      src={iconBell}
                    />
                    <p className={` text-sm text-[#928B80]`}>Notifications</p>
                  </>
                )}
              </div>
              <div
                x-data="{ open: false }"
                className="bg-white w-64   flex justify-end items-center"
              >
                <div
                  ref={dropdownRef}
                  data-testid="header-menu-trigger"
                  onClick={() => setMenuOpen(!menuOpen)}
                  className={`relative border-b-4  border-transparent`}
                >
                  <div className="flex items-center space-x-6 cursor-pointer -mt-4">
                    <h3
                      className={clsx(
                        'text-[#58534D] text-[18px] font-lato line-clamp-1'
                      )}
                      data-testid="header-name-highlight"
                    >
                      Hello {name}
                    </h3>
                    <div className="flex items-center space-x-3">
                      <div>
                        {user?.picture?.length ? (
                          <UserBrand
                            user={user}
                            showLocation={false}
                            showName={false}
                            size={48}
                            navigatesToProfile={false}
                          />
                        ) : (
                          <div className="flex flex-col justify-between items-center text-center">
                            <UserProfileImage user={user} />
                          </div>
                        )}
                      </div>
                      <svg
                        width="16"
                        height="10"
                        viewBox="0 0 16 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.6743 2.05239L8.54307 9.75654C8.24288 10.0812 7.7579 10.0812 7.45693 9.75654L0.325723 2.05239C-0.108574 1.58378 -0.108574 0.821355 0.325723 0.351914C0.76002 -0.116694 1.46488 -0.116694 1.89917 0.351914L8.00039 6.94239L14.1 0.351914C14.5351 -0.116694 15.24 -0.116694 15.6743 0.351914C16.1086 0.821355 16.1086 1.58378 15.6743 2.05239Z"
                          fill="#58534D"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="flex justify-end -mt-[32px]">
                    <MenuComponent
                      dashboardData={dashboardData}
                      open={menuOpen}
                      onClose={() => setMenuOpen(false)}
                      //setMenuOpen={setMenuOpen}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              x-data="{ open: false }"
              className="flex justify-end items-center"
            >
              <NewPost
                className="create-post-btn-mobile"
                onClick={() => setPostOpen(true)}
                style={{ width: 50, marginRight: 1 }}
              />
              <div
                data-testid="mobile-menu-btn"
                onClick={() => {
                  !isMobile && setMenuOpen(!menuOpen)
                }}
                className={`relative border-b-4 w-[125%] border-transparent }`}
              >
                {menuOpen ? (
                  <div className="flex justify-end items-end  cursor-pointer">
                    <div
                      onClick={() =>
                        isMobile && (navigate(-1), setMenuOpen(!menuOpen))
                      }
                    >
                      <CloseIcon />
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-end items-end   cursor-pointer">
                    <div
                      data-testid="mobile-menu-trigger-btn"
                      onClick={() => setMenuOpen(true)}
                    >
                      <MenuIcon />
                    </div>
                  </div>
                )}

                {!isMobile && menuOpen && (
                  <div
                    x-show="open"
                    className={clsx(
                      `absolute  
                     bg-white rounded-lg shadow-lg border mt-5 z-[999999999]`,
                      { 'right-[10px] w-[320px]': isTablet }
                    )}
                  >
                    <div className="flex flex-col mx-4">
                      {isMobile && (
                        <div
                          // onClick={() => navigate("/dashboard/search")}
                          className="flex justify-around mt-5  items-center align-middle w-[3/4] border-2 rounded-xl"
                        >
                          <FontAwesomeIcon
                            className="cursor-pointer h-6 text-[#000000]/50 font-extralight pl-3"
                            icon={faSearch}
                          />

                          <input
                            type="text"
                            placeholder="Search..."
                            className=" border-none outline-none w-full text-[rgba(33,34,38,0.4)] text-base border-transparent focus:border-transparent focus:ring-0 rounded-r-xl"
                          />
                        </div>
                      )}
                      <div className="flex w-full justify-center items-center gap-3 mb-6 mt-8">
                        {!user?.picture ? (
                          <UserProfileImage user={user} />
                        ) : (
                          <img
                            src={user?.picture}
                            alt="avatar"
                            style={{
                              objectFit: 'cover'
                            }}
                            className="w-12 h-12 rounded-full overflow-hidden"
                          />
                        )}
                        {/* <div>
                          <div className="text-[#58534D] text-lg">{name}</div>
                          <div className="text-[#928B80] text-xs whitespace-nowrap">
                            {city + ', ' + country}
                          </div>
                        </div> */}
                      </div>
                      <div className="flex w-full justify-center items-center mb-8">
                        {isMobile ? (
                          <Link
                            to="/dashboard/profile"
                            onClick={() => setMenuOpen(false)}
                          >
                            <button
                              data-testid="view-profile-btn"
                              className="bg-[#FF9502] h-[40px] rounded-3xl text-white whitespace-nowrap px-[12px] mx-6"
                            >
                              View Profile
                            </button>
                          </Link>
                        ) : (
                          <button
                            onClick={() => {
                              navigate('/dashboard/profile')
                            }}
                            data-testid="view-profile-btn"
                            className="bg-[#FF9502] h-[40px] rounded-3xl text-white whitespace-nowrap px-[12px] w-full mx-auto"
                          >
                            View Profile
                          </button>
                        )}
                      </div>
                    </div>
                    <ul className="space-y-3 text-[#140C00] py-2">
                      <hr className="border-[#928B804D]" />
                      {(isMobile || isTablet) && (
                        <SmallScreenList
                          onPostTrigger={() => setPostOpen(true)}
                        />
                      )}
                      {dashboardData.map((item, index) => (
                        <div key={index}>
                          <li
                            data-testid={`dashboard-menu-${item.name}`}
                            className=" px-5"
                            onClick={() => {
                              if (item.menu === 'Logout') {
                                dispatch(logUserOut())
                                return
                              }
                              !isEmailVerified && item.name != 'Logout'
                                ? navigate('/dashboard/settings/profile')
                                : openMenuDialog(item.name)
                            }}
                          >
                            <div
                              className={`flex items-center transform transition-colors duration-200 border-r-4 border-transparent cursor-pointer py-[12px] text-[#140C00]`}
                            >
                              <div className="mr-3">
                                <img
                                  src={item.icon}
                                  className="w-6 h-6 text-[16px]"
                                />
                              </div>
                              {item.name}
                            </div>
                          </li>
                          {dashboardData.length === index + 1 ? (
                            <></>
                          ) : (
                            <hr className="border-[#928B804D]" />
                          )}
                        </div>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </nav>
      <MobileMenu
        show={menuOpen}
        onClose={() => setMenuOpen(false)}
        onPostCreationStart={() => setPostOpen(true)}
        dashboardData={dashboardData}
        setOpenModal={setOpenModal}
      />
    </div>
  )
}

export default Header
