import { ReactComponent as Search } from '@assets/icons/search-small.svg'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatSearchResult } from './utils/functions'

const SearchResultText = ({ name, location, searchTerm }) => {
  const searchTermNameIndex = name
    .toLowerCase()
    .indexOf(searchTerm.toLowerCase())
  const searchTermLocationIndex = location
    .toLowerCase()
    .indexOf(searchTerm.toLowerCase())

  if (searchTermNameIndex === -1 && searchTermLocationIndex === -1) {
    return (
      <div className="text-_58534D text-sm leading-[16.8px] flex items-center">
        <span>{name}</span>
        {location && (
          <div className="h-[3px] w-[3px] rounded-[1.5px] bg-_58534D mx-1 flex-shrink-0" />
        )}
        {/* location */}
        <span>{location}</span>
      </div>
    )
  }

  return (
    <div className="text-_58534D text-sm leading-[16.8px] flex items-center">
      {searchTermNameIndex === -1 ? (
        <span>{name}</span>
      ) : (
        <span>
          {name.substring(0, searchTermNameIndex)}
          <span className="text-black font-medium">
            {name.substring(
              searchTermNameIndex,
              searchTermNameIndex + searchTerm.length
            )}
          </span>
          {name.substring(searchTermNameIndex + searchTerm.length)}
        </span>
      )}
      {/* location */}

      {location && (
        <div className="h-[3px] w-[3px] rounded-[1.5px] bg-_58534D mx-1" />
      )}
      {searchTermLocationIndex === -1 ? (
        <span>{location}</span>
      ) : (
        <span>
          {location.substring(0, searchTermLocationIndex)}
          <span className="text-black font-medium">
            {location.substring(
              searchTermLocationIndex,
              searchTermLocationIndex + searchTerm.length
            )}
          </span>
          {location.substring(searchTermLocationIndex + searchTerm.length)}
        </span>
      )}
    </div>
  )
}

export const SearchDrawerItem = ({ result, searchText, id, onClose }) => {
  const navigate = useNavigate()
  const resultDisplay = useMemo(() => formatSearchResult(result), [result])
  return (
    <button className="flex space-x-[11px] items-center h-6">
      <Search />
      <div
        onClick={() => {
          if (id > 0) {
            navigate(`/dashboard/user/${id}`, { replace: true })
            onClose()
          }
        }}
        className="text-[#928B80] text-[14px] leading-6"
      >
        <SearchResultText
          location={resultDisplay.location}
          name={resultDisplay.name}
          searchTerm={searchText}
        />
      </div>
    </button>
  )
}

SearchResultText.propTypes = {
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired
}

SearchDrawerItem.propTypes = {
  result: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired
  }).isRequired,
  searchText: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired
}
