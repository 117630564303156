import React from 'react'
import Skeleton from 'react-loading-skeleton'
import PropTypes from 'prop-types'
import PaginationSkeleton from '../navigation/PaginationSkeleton'

const SocialAssetSkeleton = ({ withButton = false }) => (
  <tr className="h-[80px] flex justify-between items-center">
    <td className="w-full">
      <div className="flex items-center justify-start cursor-pointer gap-3 pl-4">
        <Skeleton height={40} width={40} circle />
        <div>
          <Skeleton height={24} width={100} />
          <Skeleton height={32} width={102.49} />
        </div>
      </div>
    </td>
    <td className="text-sm font-medium text-gray-900 flex ml-auto pr-6 justify-items-end">
      {withButton ? (
        <Skeleton width={92} height={32} borderRadius={24} />
      ) : (
        <div className="relative mt-1">
          <button
            className="relative w-20 cursor-pointer flex space-x-2 bg-white py-2 pl-3 text-right focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
            id="headlessui-listbox-button-:r7:"
            type="button"
            aria-haspopup="listbox"
            aria-expanded="false"
            data-headlessui-state=""
          >
            <Skeleton height={19} width={24} />
            <Skeleton width={10} height={19} />
          </button>
        </div>
      )}
    </td>
  </tr>
)

const SocialAssetListSkeleton = () => (
  <div className="rounded-xl w-full bg-[#ffffff] flex flex-col overflow-hidden">
    <div className="flex justify-center items-center text-center align-middle m-6 gap-2">
      <Skeleton height={36} width={189.63} />
      <Skeleton height={36} width={27.84} />
    </div>
    <div className="flex justify-between items-center text-center mt-8 mx-4">
      <Skeleton height={38} width={143.5} borderRadius={12} />
      <Skeleton height={38} width={91.3} borderRadius={12} />
    </div>
    {/* list */}
    <div className="mr-3 w-full  flex flex-col justify-start items-start">
      <table className="w-full">
        <tbody className="bg-white divide-y divide-gray-200 w-full">
          <SocialAssetSkeleton withButton />
          <SocialAssetSkeleton />
          <SocialAssetSkeleton withButton />
          <SocialAssetSkeleton />
          <SocialAssetSkeleton withButton />
        </tbody>
      </table>
      <PaginationSkeleton />
    </div>
  </div>
)

SocialAssetSkeleton.propTypes = {
  withButton: PropTypes.bool
}

export default SocialAssetListSkeleton
