import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation, useSearchParams } from 'react-router-dom'

const unprotectedRoutes = [
  '/',
  '/privacy-policy',
  '/blog',
  '/faqs',
  '/blog/:slug',
  '/terms'
]

function UnProtected({ children }) {
  const { jwt } = useSelector(state => state.auth)
  const location = useLocation()
  const [params] = useSearchParams()
  const nextPage = params.get('next')

  return jwt && !unprotectedRoutes.includes(location.pathname) ? (
    <Navigate to={nextPage || '/dashboard/home'} />
  ) : (
    children
  )
}
export default UnProtected
