import React from 'react'
import PropTypes from 'prop-types'
import Header from '@components/navigation/Header'

const Content = ({ children }) => (
  <div className="min-h-screen">
    <Header />
    {children}
  </div>
)

Content.propTypes = {
  children: PropTypes.element
}

export default Content
