import React from 'react'
import Skeleton from 'react-loading-skeleton'
import HeaderSkeleton from '../../navigation/HeaderSkeleton'

const NotificationsSkeleton = () => (
  <div className="h-screen overflow-y-hidden">
    <HeaderSkeleton />
    {/* content */}
    <div className="mt-28 xs:mt-14 mb-10 lg:mx-4">
      <div className="flex xs:flex-col xs:gap-8 gap-10 justify-center lg:h-[100vh] sm:h-[auto]">
        {/* social assets */}
        <div className="shrink-0 basis-[280px] lg:basis-[300px] xs:basis-auto">
          <div className="shadow-lg xs:rounded-none rounded-xl w-full bg-white font-lato">
            <div className="flex flex-col justify-between items-center text-center">
              <div className="mt-10">
                <Skeleton className="h-14 w-14 rounded-full" />
                <div className="mt-4">
                  <Skeleton height={24} width={100} />
                </div>
                <div className="mt-8 xs:mt-4 flex justify-center items-center gap-2 mb-8">
                  <Skeleton height={24} width={24} circle />
                  <Skeleton width={150} height={20} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* notifications */}
        <div className="basis-[440px] self-start lg:basis-[480px] xl:basis-[540px] xs:basis-auto w-981 h-769 bg-white rounded-12">
          <div className="flex flex-col space-y-10 p-4">
            <Skeleton height={50} />
            <Skeleton height={50} />
            <Skeleton height={50} />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default NotificationsSkeleton
