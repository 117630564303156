import apiConfig from '@config/apiConfig'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { customBaseQuery } from './util'

export const usersService = createApi({
  reducerPath: 'usersService',
  baseQuery: customBaseQuery,
  endpoints: builder => ({
    getAllUsers: builder.query({
      query: () => apiConfig.User.GetUsers
    })
  })
})

export const { useGetAllUsersQuery, useLazyGetAllUsersQuery } = usersService
