/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/prop-types */
import InputError from '@components/Forms/InputError'
import { minPostImgCount } from '@constants/posts'
import { containsBadWords } from '@utils/utils'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import PostImageEdit from './PostImageEdit'
import PostImageSelect from './PostImageSelect'
import { descriptionLimit } from './utils'

const PostDetailsInputForm = ({
  setPostData,
  postData = {},
  onProceed,
  dataToEdit,
  dataToEditIndex
}) => {
  const [processingImages, setProcessingImages] = useState(false)
  const isProgressEdit = !!dataToEditIndex
  const isEdit = !!dataToEdit
  const { walkthroughMenu: menu } = useSelector(state => state.app)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue
  } = useForm({
    defaultValues: {
      purpose: postData.purpose,
      description: postData.description,
      // media: transformPostMedia(postData.media),
      current_amount: postData.current_amount
    }
  })

  useEffect(() => {
    setValue('current_amount', postData?.current_amount || '')
    setValue('purpose', postData.purpose || '')
    setValue('description', postData.description)
    if (postData.media) {
      setValue('media', postData.media)
    }
  }, [])

  const onSubmit = data => {
    setPostData(prev => ({
      ...prev,
      ...data,
      media: data.media
    }))
    onProceed()
  }

  const description = watch('description')

  const validateNotEmpty = value =>
    value.trim().length > 0 || 'This field is required'

  const submitBtnTitle =
    menu === 'Create Post' || isEdit
      ? postData?.type === 'goal'
        ? 'Next'
        : 'Review'
      : 'Next'

  return (
    <form
      className="px-4 font-lato flex flex-col justify-center items-center mt-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="pb-[80px] md-full:pb-0 w-full">
        <div className="pt-6 flex items-center justify-center w-full text-left">
          {!isProgressEdit && (
            <div className="flex flex-col gap-2 w-full max-w-sm">
              <label className="text-[#140C00]" htmlFor="title">
                Purpose
              </label>
              <input
                placeholder="To buy a Car"
                data-testid="post-purpose-input"
                {...register('purpose', {
                  maxLength: 100,
                  validate: {
                    notEmpty: validateNotEmpty,
                    noProfanity: value =>
                      containsBadWords(value) ||
                      'Contains inappropriate language'
                  }
                })}
                className="rounded-lg w-full border text-base border-[#928b80] p-4 text-[#140C00] placeholder:text-[#928B80] invalid:border-red-600 focus:border-[#140C00] ring-0"
                type="text"
              />
              <InputError
                text={
                  errors.purpose?.message ||
                  'Please enter a valid purpose not exceeding 100 characters'
                }
                error={errors.purpose}
              />
            </div>
          )}
        </div>
        <div className="pt-6 flex items-center justify-center w-full text-left">
          <div className="flex flex-col gap-2 w-full max-w-sm">
            <label className="text-[#140C00]" htmlFor="content">
              Description
            </label>
            <textarea
              data-testid="post-description-input"
              placeholder="Hello guys, I’ve started saving to buy a car. This is a Dodge Charger, my dream car and I believe I would be able to save and buy the car."
              {...register('description', {
                validate: {
                  notEmpty: validateNotEmpty,
                  noProfanity: value =>
                    containsBadWords(value) || 'Contains inappropriate language'
                }
              })}
              onChange={e => {
                if (e.target.value.length <= descriptionLimit) {
                  setValue('description', e.target.value)
                } else {
                  setValue('description', description)
                  toast.error(
                    `Content is more than limit: ${descriptionLimit} characters`
                  )
                }
              }}
              rows={3}
              className="rounded-lg w-full border border-[#928b80] p-4 text-[#140C00] placeholder:text-[#928B80] invalid:border-red-600 focus:border-[#140C00] ring-0"
            />
            <p className="text-right text-sm text-[#140C00] pr-2">
              {description.length}/{descriptionLimit}
            </p>

            <InputError
              text={
                errors.description?.message ||
                'Please enter a valid description'
              }
              error={errors.description}
              style={{ marginTop: '-20px' }}
            />
          </div>
        </div>
        <div className="mb-4 w-full mx-auto mt-6 max-w-sm flex items-center justify-center">
          <Controller
            control={control}
            name="media"
            rules={{ validate: v => v && v.length > 0, required: true }}
            render={({ field: { onChange, value } }) =>
              dataToEdit ? (
                <PostImageEdit
                  images={value || []}
                  setter={onChange}
                  imagesCanBeDeleted={value?.length > minPostImgCount}
                  imagesCanBeChanged={false}
                />
              ) : (
                <PostImageSelect
                  images={value || []}
                  setter={onChange}
                  processingImages={processingImages}
                  setProcessingImages={setProcessingImages}
                />
              )
            }
          />
        </div>
        <div className="mx-auto flex items-center justify-center">
          <InputError text={'Please select an image'} error={errors.media} />
        </div>
        {menu === 'Create Post' && (
          <div className="mx-auto mt-2 mb-4">
            <div className="flex items-center justify-center gap-2">
              <div className="w-2 h-2 rounded-full bg-[#928B8066]"></div>
              <div className="w-2 h-2 rounded-full bg-[#FF9502]"></div>
              <div className="w-2 h-2 rounded-full bg-[#928B8066]"></div>
            </div>
          </div>
        )}
      </div>
      <div className="mt-4 flex items-center justify-center w-full xs:rounded-b-2xl xs:absolute bg-white py-2 md-full:py-0 bottom-0 left-0 px-[1.5rem] md-full:px-0">
        <div className="w-full max-w-sm">
          <button
            disabled={processingImages}
            data-testid="post-details-submit-btn"
            type="submit"
            // disabled={!isValidated}
            className="w-full bg-[#FF9502] hover:bg-[#FF9502]/80 rounded-lg text-white font-bold px-10 py-4 cursor-pointer"
          >
            {submitBtnTitle}
          </button>
        </div>
      </div>
    </form>
  )
}

export default PostDetailsInputForm
