import React from 'react'
import HeaderSkeleton from '../navigation/HeaderSkeleton'
import Skeleton from 'react-loading-skeleton'
import PostListSkeleton from '../home/PostListSkeleton'

const AllPostsSkeleton = ({ withHeader = true }) => (
  <div className="h-screen overflow-y-hidden">
    {withHeader && <HeaderSkeleton />}
    <div className="mt-12 xs:mt-14 mb-10 lg:mx-4">
      <div className="flex xs:flex-col xs:gap-8 gap-10 justify-center lg:h-[100vh] sm:h-[auto]">
        {/* social assets */}
        <div className="shrink-0 basis-[280px] lg:basis-[300px] xs:basis-auto">
          <div className="shadow-lg xs:rounded-none rounded-xl w-full bg-white font-lato">
            <div className="flex flex-col justify-between items-center text-center">
              <div className="mt-10">
                <Skeleton className="h-14 w-14 rounded-full" />
                <div className="mt-4">
                  <Skeleton height={24} width={100} />
                </div>
                <div className="mt-8 xs:mt-4 flex justify-center items-center gap-2 mb-8">
                  <Skeleton height={24} width={24} circle />
                  <Skeleton width={150} height={20} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="basis-[440px] lg:basis-[480px] xl:basis-[540px] xs:basis-auto h-[inherit] pb-5">
          <PostListSkeleton />
        </div>
        <div className="shrink-0 basis-[320px] xl:basis-[360px] hidden lg:block h-[min-content]">
          <div className="pt-6 pb-2 px-4 font-lato bg-white rounded-xl w-full">
            <div className="flex flex-col space-y-2 items-center justify-center w-full">
              <Skeleton height={28} width={200} />
              <Skeleton height={28} width={100} />
            </div>
            <div className="mt-10">
              {/* single post */}
              <div className="py-4 border-b border-b-[#928B8066] last:border-0">
                <div className="flex items-center gap-3">
                  <Skeleton height={40} width={40} circle />
                  <Skeleton height={28} width={140} />
                </div>
                <div className="flex items-center gap-4 mt-4">
                  <Skeleton width={128} height={88} borderRadius={10} />
                  <div className="flex flex-col gap-2">
                    <Skeleton height={14} width={80} />
                    <Skeleton height={14} width={120} />
                  </div>
                </div>
                <div className="flex items-center gap-1 mt-2">
                  <div className="flex">
                    <Skeleton height={10} width={10} circle />
                    <Skeleton
                      height={10}
                      width={10}
                      circle
                      className="ml-[-5px]"
                    />
                  </div>
                  <Skeleton height={5} width={50} />
                </div>
              </div>
              <div className="py-4 border-b border-b-[#928B8066] last:border-0">
                <div className="flex items-center gap-3">
                  <Skeleton height={40} width={40} circle />
                  <Skeleton height={28} width={140} />
                </div>
                <div className="flex items-center gap-4 mt-4">
                  <Skeleton width={128} height={88} borderRadius={10} />
                  <div className="flex flex-col gap-2">
                    <Skeleton height={14} width={80} />
                    <Skeleton height={14} width={120} />
                  </div>
                </div>
                <div className="flex items-center gap-1 mt-2">
                  <div className="flex">
                    <Skeleton height={10} width={10} circle />
                    <Skeleton
                      height={10}
                      width={10}
                      circle
                      className="ml-[-5px]"
                    />
                  </div>
                  <Skeleton height={5} width={50} />
                </div>
              </div>
              <div className="py-4 border-b border-b-[#928B8066] last:border-0">
                <div className="flex items-center gap-3">
                  <Skeleton height={40} width={40} circle />
                  <Skeleton height={28} width={140} />
                </div>
                <div className="flex items-center gap-4 mt-4">
                  <Skeleton width={128} height={88} borderRadius={10} />
                  <div className="flex flex-col gap-2">
                    <Skeleton height={14} width={80} />
                    <Skeleton height={14} width={120} />
                  </div>
                </div>
                <div className="flex items-center gap-1 mt-2">
                  <div className="flex">
                    <Skeleton height={10} width={10} circle />
                    <Skeleton
                      height={10}
                      width={10}
                      circle
                      className="ml-[-5px]"
                    />
                  </div>
                  <Skeleton height={5} width={50} />
                </div>
              </div>
              <div className="py-4 border-b border-b-[#928B8066] last:border-0">
                <div className="flex items-center gap-3">
                  <Skeleton height={40} width={40} circle />
                  <Skeleton height={28} width={140} />
                </div>
                <div className="flex items-center gap-4 mt-4">
                  <Skeleton width={128} height={88} borderRadius={10} />
                  <div className="flex flex-col gap-2">
                    <Skeleton height={14} width={80} />
                    <Skeleton height={14} width={120} />
                  </div>
                </div>
                <div className="flex items-center gap-1 mt-2">
                  <div className="flex">
                    <Skeleton height={10} width={10} circle />
                    <Skeleton
                      height={10}
                      width={10}
                      circle
                      className="ml-[-5px]"
                    />
                  </div>
                  <Skeleton height={5} width={50} />
                </div>
              </div>
              <div className="py-4 border-b border-b-[#928B8066] last:border-0">
                <div className="flex items-center gap-3">
                  <Skeleton height={40} width={40} circle />
                  <Skeleton height={28} width={140} />
                </div>
                <div className="flex items-center gap-4 mt-4">
                  <Skeleton width={128} height={88} borderRadius={10} />
                  <div className="flex flex-col gap-2">
                    <Skeleton height={14} width={80} />
                    <Skeleton height={14} width={120} />
                  </div>
                </div>
                <div className="flex items-center gap-1 mt-2">
                  <div className="flex">
                    <Skeleton height={10} width={10} circle />
                    <Skeleton
                      height={10}
                      width={10}
                      circle
                      className="ml-[-5px]"
                    />
                  </div>
                  <Skeleton height={5} width={50} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default AllPostsSkeleton
