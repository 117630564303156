import invite from '@assets/images/invite.png'
import PropTypes from 'prop-types'

const InviteModal = ({ children, size }) => (
  <div className="flex flex-col justify-between items-center text-center">
    <div className="flex flex-col justify-between text-center items-center">
      <p className="font-lato font-medium !text-[18px] max-w-[calc(100%_-_150px)] md-full:!text-[24px] text-[#140C00]">
        Invite Your Trusted Friends & Family!
      </p>
      <p className="font-lato text-[18px] xs:text-base text-[#58534D] text-center mt-4">
        Unleash the fun on Maoney! Invite your trusted circle and let's build
        wealth together!
      </p>
    </div>
    <div className={size ? `h-[${size}] w-[${size}] mt-6` : 'w-52 mt-6'}>
      <img src={invite} alt="Invite" />
    </div>
    <div className="w-96 xs:w-auto">{children}</div>
  </div>
)

InviteModal.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string
}

InviteModal.defaultProps = {
  children: null,
  size: null
}

export default InviteModal
