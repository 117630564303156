import { getMemberInitials, getMemberName } from '@utils/helpers'
import React from 'react'
import clsx from 'clsx'

function hashString(str) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i)
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

function UserProfileImage({
  user,
  width = '50',
  height = '50',
  small,
  border = false,
  element
}) {
  // Ensure user is not null or undefined
  if (
    !user ||
    (!user?.firstName &&
      !user?.lastName &&
      !user?.first_name &&
      !user?.last_name &&
      !user?.org_name)
  ) {
    return null // Or render a default profile image
  }

  // Combine first and last name
  const username = getMemberName(user)

  // Generate hash from the combined username
  const hash = hashString(username)

  // Define colors array
  const colors = ['#FF9502'] // Add more colors as needed

  // Get background color based on hash
  const getBackgroundColor = () => {
    const index = hash % colors.length
    return colors[index]
  }

  const containerStyle = {
    background: getBackgroundColor(),
    width: `${width}px`, // Use px as the unit for width
    height: `${height}px` // Use px as the unit for height
  }

  return (
    <div
      style={containerStyle}
      className={clsx(
        'flex items-center relative justify-center rounded-full text-white flex-shrink-0 default-profile-picture',
        {
          '!border-[2px] !border-white': border
        }
      )}
    >
      <p style={small ? { fontSize: '12px' } : {}}>{getMemberInitials(user)}</p>
      {element && element}
    </div>
  )
}

export default UserProfileImage
