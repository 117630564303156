/* eslint-disable react-hooks/exhaustive-deps */
import close from '@assets/icons/close.svg'
import Loader from '@components/Loaders/Loader'
import usePosts from '@hooks/posts/usePosts'
import { Modal } from '@mui/material'
import { useDeletePostMutation } from '@services/posts-service'
import { setPostToDelete } from '@store/slices/posts'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const DeletePost = () => {
  const { removePost } = usePosts()
  const [loading, setLoading] = useState(false)
  const [deletePost] = useDeletePostMutation()
  const dispatch = useDispatch()
  const { postToDelete } = useSelector(state => state.posts)

  const onClose = () => {
    dispatch(setPostToDelete(null))
  }

  const handleDeletePost = async () => {
    setLoading(true)
    try {
      await deletePost(postToDelete.id).unwrap()
      removePost(postToDelete.id)
      setLoading(false)
      onClose()
    } catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!postToDelete && loading) {
      setLoading(false)
    }
  }, [postToDelete])

  return (
    <Modal open={!!postToDelete} onClose={onClose}>
      <React.Fragment>
        <div
          className="flex items-center justify-center h-screen w-screen"
          data-testid="delete-post-modal"
        >
          <div className="rounded-2xl bg-white font-lato text-center max-w-md py-8 px-6 xs:py-4 xs:px-2">
            <div className="flex justify-between">
              <div className="w-10"></div>
              <p className="text-[#140C00] font-lato font-bold text-[32px] xs:text-2xl mt-2">
                Delete
              </p>
              <div
                className="flex items-center justify-center bg-[#928B8033] rounded-full w-10 h-10 cursor-pointer"
                onClick={() => onClose()}
              >
                <img src={close} className="w-4 h-4" />
              </div>
            </div>

            <div className="text-[#58534D] mt-6 xs:text-sm xs:w-40 w-60 mx-auto">
              Are you sure you want to delete this post
            </div>
            {loading ? (
              <Loader type="regular" label="Deleting post" />
            ) : (
              <div className="flex xs:flex-col-reverse justify-between gap-4 mt-12 mx-6">
                <button
                  onClick={() => {
                    onClose()
                  }}
                  data-testid="delete-post-modal-cancel"
                  className="xs:w-full w-44 bg-white border-2 border-[#CC0000] rounded-lg text-[#CC0000] py-4 px-10 font-bold"
                >
                  Cancel
                </button>

                <button
                  onClick={() => handleDeletePost()}
                  data-testid="delete-post-modal-confirm"
                  className="xs:w-full w-44 bg-[#FF9502] rounded-lg text-white py-4 px-10 font-bold"
                >
                  Yes, Delete
                </button>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    </Modal>
  )
}

export default DeletePost
