import { createSlice } from '@reduxjs/toolkit'

const initialState = []

const notificationsSlice = createSlice({
  name: 'notifs',
  initialState,
  reducers: {
    setNotifs: (state, { payload }) => {
      return payload
    },
    clearNotifs: () => {
      return []
    },
    updateNotifs: (state, { payload }) => {
      const currentDate = new Date()
      state.push({
        ...payload,
        isRead: false,
        timestamp: currentDate.toISOString()
      })
    },
    markNotifsAsRead: state => {
      return state.map(notif => ({
        ...notif,
        isRead: true
      }))
    },
    deleteNotifsByIds: (state, { payload: idsToDelete }) => {
      return state.filter(notif => !idsToDelete.includes(notif.id))
    },
    markNotifsAsReadByIds: (state, { payload: idsToMarkAsRead }) => {
      return state.map(notif =>
        idsToMarkAsRead.includes(notif.id) ? { ...notif, isRead: true } : notif
      )
    }
  }
})

const { actions } = notificationsSlice
export const {
  setNotifs,
  clearNotifs,
  updateNotifs,
  markNotifsAsRead,
  deleteNotifsByIds,
  markNotifsAsReadByIds
} = actions
export default notificationsSlice.reducer
