import { createSlice } from '@reduxjs/toolkit'

const initialState = []

/** Reducer to store all the users in the system */
const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action) => [...action.payload]
  }
})

export const { setUsers } = usersSlice.actions

export default usersSlice.reducer
