import { useDispatch } from 'react-redux'
import {
  refreshSinglePost,
  setPostToDelete,
  setPostToUpdate,
  setPosts
} from '@store/slices/posts'
import { postActions } from '@constants/posts'
import { useAppSelector } from '@hooks/redux'

const usePosts = () => {
  const { user } = useAppSelector(state => state.auth)
  const { posts, postToUpdate } = useAppSelector(state => state.posts)
  const dispatch = useDispatch()

  /**
   * function to set post to edit
   * updateType can be "add-progress" or "update"
   */
  const setPostToEdit = (postId, updateType, index) => {
    const post = posts.find(post => post.id === postId)
    if (post) {
      const dataSet = {
        data: post,
        updateType,
        index
      }
      dispatch(setPostToUpdate(dataSet))
    }
  }

  /** Set post to delete */
  const initiatePostDeletion = post => {
    dispatch(setPostToDelete(post))
  }

  /**
   * function to reset edit post state
   */
  const clearPostToEdit = () => {
    dispatch(setPostToUpdate(null))
  }

  // ** Insert Post
  const insertPost = newPost => {
    const postIndex = posts?.findIndex(post => post?.id === newPost.id)
    if (postIndex !== -1 || newPost.user_id === user.id) {
      // The feed with the given postId already exists, no need to update
      return
    }
    const updatedPosts = [newPost, ...posts]
    dispatch(setPosts(updatedPosts))
  }

  //** Upsert Posts. Replace posts entirely */
  const upsertPosts = newPosts => {
    dispatch(setPosts(newPosts))
  }

  // ** Remove a single post
  const removePost = postId => {
    dispatch(setPosts([...posts.filter(post => post.id !== postId)]))
  }

  //** Edit posts based on user action i.e like, unlike, comment*/
  const editPostsBasedOnAction = (action, postId, userId) => {
    const post = posts.find(item => item.id === postId)
    if (post) {
      let newPost
      if (action === postActions.like) {
        newPost = {
          ...post,
          likes: [...(post.likes || []), { post_id: postId, user_id: userId }]
        }
      } else {
        newPost = {
          ...post,
          likes: (post.likes || []).filter(like => like.user_id !== userId)
        }
      }
      dispatch(refreshSinglePost({ postId, data: newPost }))
    }
  }

  return {
    setPostToEdit,
    insertPost,
    upsertPosts,
    editPostsBasedOnAction,
    clearPostToEdit,
    postToUpdate,
    removePost,
    initiatePostDeletion
  }
}

export default usePosts
