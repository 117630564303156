import { useAppDispatch, useAppSelector } from '@hooks/redux'
import { clearImagesToDisplay } from '@store/slices/posts'
import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import next from '@assets/icons/next.svg'
import prev from '@assets/icons/prev.svg'

const ImageDisplayModal = () => {
  const dispatch = useAppDispatch()
  const [index, setIndex] = useState(0)
  const { imagesModalState } = useAppSelector(state => state.posts)
  const { visible, images, index: activeIndex } = imagesModalState

  const onClose = () => {
    dispatch(clearImagesToDisplay())
    setTimeout(() => {
      if (index !== 0) {
        setIndex(0)
      }
    }, 150)
  }

  const goToPrev = () => {
    setIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1))
  }

  const goToNext = () => {
    setIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1))
  }

  useEffect(() => {
    setIndex(activeIndex)
    if (visible) {
      const html = document.querySelector('html')
      if (html) {
        html.classList.add('overflow-y-hidden')
      }
    } else {
      const html = document.querySelector('html')
      if (html) {
        html.classList.remove('overflow-y-hidden')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  return (
    <div
      className={clsx(
        'flex items-center justify-center',
        'opacity-0 pointer-events-none transition-all duration-75 bg-[black] fixed top-0 left-0 h-screen w-full z-[4000]',
        {
          '!pointer-events-auto !opacity-100': visible
        }
      )}
    >
      <div className="max-w-[90%]">
        <img
          src={(images || [])[index]}
          alt="Post image"
          className="object-contain max-w-full w-full max-h-[80vh] md-full:max-h-screen"
        />
      </div>
      {/* left bar */}
      <div className="hover:bg-white/[.01] absolute left-0 w-[30px] md-full:w-[70px] pl-2 h-full flex items-center justify-center transition-all duration-200">
        <img
          src={prev}
          className={clsx(
            'h-[40px] cursor-pointer md-full:h-[55px] select-none',
            {
              'opacity-0 pointer-events-none': index <= 0
            }
          )}
          onClick={goToPrev}
        />
      </div>
      {/* right bar */}
      <div className="hover:bg-white/[.05] absolute right-0 w-[30px] md-full:w-[70px] pr-2 h-full flex items-center justify-center transition-all duration-200">
        <img
          src={next}
          className={clsx(
            'h-[40px] cursor-pointer md-full:h-[55px] select-none',
            {
              'opacity-0 pointer-events-none': index === images.length - 1
            }
          )}
          onClick={goToNext}
        />
      </div>
      {/* exit button */}
      <button
        type="button"
        onClick={onClose}
        className="h-10 w-10 md-full:h-[50px] md-full:w-[50px] rounded-full flex items-center justify-center bg-white/[.1] absolute top-3 left-3"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          className="h-5 w-5 md-full md-full:h-[30px] md-full:w-[30px]"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.2273 2.95558C14.3051 2.87793 14.3668 2.78574 14.4089 2.68425C14.451 2.58276 14.4727 2.47397 14.4728 2.3641C14.4728 2.25422 14.4513 2.1454 14.4093 2.04387C14.3673 1.94233 14.3057 1.85005 14.2281 1.77231C14.1504 1.69457 14.0582 1.63288 13.9567 1.59077C13.8552 1.54866 13.7465 1.52695 13.6366 1.52688C13.5267 1.52681 13.4179 1.54839 13.3163 1.59037C13.2148 1.63236 13.1225 1.69393 13.0448 1.77158L7.99897 6.8174L2.95461 1.77158C2.7976 1.61457 2.58465 1.52637 2.36261 1.52637C2.14056 1.52637 1.92761 1.61457 1.7706 1.77158C1.6136 1.92859 1.52539 2.14154 1.52539 2.36358C1.52539 2.58562 1.6136 2.79857 1.7706 2.95558L6.81642 7.99994L1.7706 13.0443C1.69286 13.1221 1.63119 13.2143 1.58912 13.3159C1.54705 13.4175 1.52539 13.5264 1.52539 13.6363C1.52539 13.7463 1.54705 13.8551 1.58912 13.9567C1.63119 14.0583 1.69286 14.1506 1.7706 14.2283C1.92761 14.3853 2.14056 14.4735 2.36261 14.4735C2.47255 14.4735 2.58142 14.4519 2.68299 14.4098C2.78457 14.3677 2.87686 14.306 2.95461 14.2283L7.99897 9.18249L13.0448 14.2283C13.2018 14.3851 13.4147 14.4731 13.6366 14.473C13.8585 14.4729 14.0712 14.3846 14.2281 14.2276C14.3849 14.0706 14.4729 13.8577 14.4728 13.6358C14.4726 13.4139 14.3843 13.2011 14.2273 13.0443L9.18151 7.99994L14.2273 2.95558Z"
            fill="white"
          />
        </svg>
      </button>
    </div>
  )
}

export default ImageDisplayModal
