import logo from "@assets/images/logo.png";
import { classNames } from "@utils/utils";

export const OnboardLogo = ({
  width = "w-[50px]",
  height = "h-[47px]",
  vertical = false,
}) => (
  <>
    <div className="w-full">
      <div className={`flex items-center gap-2 ${vertical ? "flex-col" : ""}`}>
        <img
          className={classNames(width, height, "inline xs:w-10 xs:h-10")}
          src={logo}
          alt="Maoney"
        />
        <div className="font-semibold text-[#140C00] font-montserrat xs:text-lg">
          Maoney
        </div>
      </div>
    </div>
  </>
);
