const TourButton = ({ showTour, setShowTour, timeIcon, setStartTour }) => (
  <div
    className={`box ${
      showTour ? ' md:block lg:block' : 'hidden'
    } fixed  bottom-0 right-0 text-right  z-[2000]`}
  >
    <button
      onClick={() => {
        setStartTour(true)
        setShowTour(false)
      }}
      className="flex justify-center items-center outline focus:bg-[#FF9502]/[0.8] outline-white bg-[#FF9502] rounded-3xl md:h-10 lg:h-10 h-7 md:w-[142px] lg:w-[142px] w-auto gap-3"
    >
      <img src={timeIcon} className="w-6 h-6 object-cover flex-shrink-0" />
      {/* Conditionally render the <p> tag based on screen size */}
      <p className="text-white text-lato order-1 leading-5 hidden md:block lg:block">
        Quick Tour
      </p>
    </button>
  </div>
)

export default TourButton
