import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isTourOpen: false,
  walkthroughMenu: null,
  modalMenu: null,
  search: { query: '', results: [] }
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startTour: state => {
      state.isTourOpen = true
    },
    endTour: state => {
      state.isTourOpen = false
    },
    updateWalkthoughMenu: (state, action) => {
      state.walkthroughMenu = action.payload
    },
    setSearchResults: (state, action) => {
      state.search = action.payload
    },
    updateModalMenu: (state, action) => {
      state.modalMenu = action.payload
    }
  }
})

export const {
  startTour,
  endTour,
  updateModalMenu,
  updateWalkthoughMenu,
  setSearchResults
} = appSlice.actions

export default appSlice.reducer
