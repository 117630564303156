export const messageSubscribersSteps = {
  messageInputStep: 'messageInputStep',
  reviewStep: 'reviewStep',
  paymentInfoStep: 'paymentInfoStep',
  congratulationsStep: 'congratulationsStep'
}

export const messageSubscribersTextMappings = {
  [messageSubscribersSteps.messageInputStep]: {
    title: 'Message Subscribers',
    subtitle: 'What message would you like to send to your subscribers?'
  },
  [messageSubscribersSteps.reviewStep]: {
    title: `Okay let's Review`
  },
  [messageSubscribersSteps.paymentInfoStep]: {
    title: 'Share Message',
    subtitle: (
      <p className="text-base leading-6 text-center max-w-[369px] mx-auto text-[#58534D] font-lato">
        To share, you&apos;ll have to pay{' '}
        <span className="font-bold text-black">200points</span> to reach out to
        your subscribers on Email, WhatsApp and SMS!
      </p>
    )
  }
}

export const steps = [
  messageSubscribersSteps.messageInputStep,
  messageSubscribersSteps.reviewStep,
  messageSubscribersSteps.paymentInfoStep,
  messageSubscribersSteps.congratulationsStep
]
