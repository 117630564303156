import {
  useDisconnectAssetMutation,
  useLazyGetUserConnectionsQuery,
  useRequestForConnectionMutation,
  useUpdateConnectStatusMutation
} from '@services/connections-service'
import {
  editConnectionStatus,
  invalidateConnection,
  removeConnection,
  reValidateConnection,
  setConnections,
  insertConnection
} from '@store/slices/connections'
import { generateRandomId, getOtherUserIdFromConnection } from '@utils/helpers'
import moment from 'moment'
import { useMemo } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'

const useConnections = () => {
  const { connections } = useSelector(state => state.connections)
  const { user } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [fetchConnections, { isLoading: loadingConnections }] =
    useLazyGetUserConnectionsQuery()
  const [updateConnectionStatus] = useUpdateConnectStatusMutation()
  const [disconnectAsset] = useDisconnectAssetMutation()
  const [requestForConnection] = useRequestForConnectionMutation()

  const getUserConnections = async (cache = false) => {
    try {
      const assets = await fetchConnections({ userId: user.id }, cache).unwrap()
      const results = assets?.userConnections?.results || []
      dispatch(setConnections(results))
    } catch (e) {
      console.log(e, 'fetch user connections error')
      // toast.error('There was an issue fetching your social assets')
    }
  }

  const validConnections = useMemo(
    () => connections?.filter(connection => !connection.invalid),
    [connections]
  )

  const socialAssets = useMemo(
    () => validConnections?.filter(item => item?.status === 'accepted'),
    [validConnections]
  )

  /**
   * A function to check if a user is a connection of the logged in user
   * @param {number|object} asset
   */
  const isUserConnection = id => {
    if (typeof id === 'object') {
      id = getOtherUserIdFromConnection(id, user) || id?.id
    }
    return connections.find(
      asset => asset?.sender_id == id || asset?.receiver_id === id
    )
  }

  const getConnectionType = id => {
    const connection = validConnections.find(
      conn => conn.sender_id === id || conn.receiver_id === id
    )
    if (connection) {
      return connection.status
    }
  }

  /** Use to accept, reject a connection request */
  const onUpdateConnectionStatus = async (
    connection,
    newStatus,
    optimisticCallback,
    onFailCallback
  ) => {
    const currStatus = connection.status
    dispatch(
      editConnectionStatus({
        connectionId: connection.id,
        newState: newStatus
      })
    )
    optimisticCallback && optimisticCallback()
    try {
      await updateConnectionStatus({
        connection_id: connection?.id,
        status: newStatus
      }).unwrap()
    } catch (e) {
      toast.error('Unable to complete request')
      dispatch(
        editConnectionStatus({
          connectionId: connection.id,
          newState: currStatus
        })
      )
      onFailCallback && onFailCallback()
    }
  }

  /** Use to cancel connection request or disconnect an asset */
  const cancelConnection = async (
    connectionId,
    optimisticCallback,
    onFailCallback
  ) => {
    dispatch(invalidateConnection(connectionId))
    optimisticCallback && optimisticCallback()
    try {
      await disconnectAsset({ connection_id: connectionId }).unwrap()
      dispatch(removeConnection(connectionId))
    } catch (e) {
      toast.error('Unable to complete request')
      dispatch(reValidateConnection(connectionId))
      onFailCallback && onFailCallback()
    }
  }

  /** Use to request for connection */
  const connectWithUser = async (
    profile,
    optimisticCallback,
    onFailCallback
  ) => {
    const data = {
      id: generateRandomId(10),
      sender_id: user?.id,
      receiver_id: profile?.id,
      group_id: null,
      status: 'pending',
      created_at: moment().toISOString(),
      updated_at: moment().toISOString(),
      categorization: 'Others'
    }
    try {
      optimisticCallback && optimisticCallback()
      dispatch(insertConnection(data))
      await requestForConnection({
        sender_id: user?.id,
        receiver_id: Number(profile?.id)
      }).unwrap()
    } catch (e) {
      dispatch(removeConnection(data.id))
      onFailCallback && onFailCallback()
    }
  }

  /** Valid connections => connections that have not been deleted or.... */
  return {
    getUserConnections,
    isUserConnection,
    getConnectionType,
    socialAssets,
    allConnections: connections,
    connections: validConnections,
    loadingConnections,
    onUpdateConnectionStatus,
    cancelConnection,
    connectWithUser
  }
}

export default useConnections
