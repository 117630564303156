import twitter from '@assets/icons/TwitterXlogo.svg'
import facebook from '@assets/icons/facebook-colored.svg'
import instagram from '@assets/icons/instagram-colored.svg'
import linkedin from '@assets/icons/linkedin-colored.svg'

import gmail from '@assets/icons/gmail-colored.svg'
import messenger from '@assets/icons/messenger-colored.svg'
import whatsapp from '@assets/icons/whatsapp-colored.svg'

export const shareSocials = [
  {
    name: 'Linkedin',
    url: (url, text) =>
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&title=${text}`,
    icon: linkedin
  },
  {
    name: 'Messenger',
    url: (url, text) =>
      `https://www.messenger.com/login?text=${text}%0A${encodeURIComponent(url)}`,
    icon: messenger
  },
  {
    name: 'Whatsapp',
    url: (url, text) =>
      `https://api.whatsapp.com/send?text=${text}%0A${encodeURIComponent(url)}`,
    icon: whatsapp
  },
  {
    name: 'Gmail',
    url: (url, text) =>
      `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to=&body=${text}%0A%0AClick%20this%20link%20to%20visit%20the%20website:%20${encodeURIComponent(url)}`,
    icon: gmail
  },
  {
    name: 'Facebook',
    url: (url, text) =>
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${text}`,
    icon: facebook
  },
  {
    name: 'Instagram',
    url: (url, text) =>
      `https://www.instagram.com/sharer/sharer.php?u=${encodeURIComponent(url)}&caption=${text}`,
    icon: instagram
  },
  {
    name: 'X',
    url: (url, text) =>
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${text}`,
    icon: twitter
  }
]
