export function organizeComments(comments) {
  const organizedComments = []
  const commentMap = {}

  const clonedComments = comments?.map(comment => ({ ...comment }))

  clonedComments?.forEach(comment => {
    commentMap[comment?.id] = comment
    comment.replies = []
  })

  clonedComments?.forEach(comment => {
    if (comment?.parent_comment_id && commentMap[comment?.parent_comment_id]) {
      commentMap[comment?.parent_comment_id]?.replies?.push(comment)
    } else {
      organizedComments?.push(comment)
    }
  })

  return organizedComments
}

export const transformPostMedia = postMedia => {
  if (postMedia && typeof postMedia[0] === 'string') {
    return [postMedia]
  }
  return postMedia || []
}
