/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import next from '@assets/icons/next.svg'
import prev from '@assets/icons/prev.svg'
import { getImagePreviewUrl } from './utils'
import PostImageDots from './PostImageDots'
import toast from 'react-hot-toast'
import { acceptedImages } from '@constants/posts'

const PostImagePreview = ({ imgFile }) => {
  const blob = getImagePreviewUrl(imgFile)
  return (
    <img
      src={blob}
      alt="Blob for post image"
      className="h-full w-full flex-shrink-0 object-cover select-none rounded-[6px]"
    />
  )
}

const PostImageEdit = ({
  setter,
  images = [],
  imagesCanBeChanged,
  imagesCanBeDeleted
}) => {
  const [hasMounted, setHasMounted] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)

  const editImagePickerRef = useRef(null)

  const onDelete = () => {
    const newImages = images.filter((img, index) => index !== imageIndex)
    setter(newImages)
  }

  const isEmpty = images.length === 0

  const editImage = event => {
    const file = event.target.files[0]
    if (getImagePreviewUrl(file)) {
      const newImages = images.map((img, index) => {
        if (index === imageIndex) {
          return file
        }
        return img
      })
      setter(newImages)
    } else {
      toast.error('There was an issue processing this image')
    }
  }

  useEffect(() => {
    if (images.length && hasMounted) {
      setImageIndex(images.length - 1)
    }
  }, [images?.length])

  useEffect(() => {
    if (!hasMounted) {
      setTimeout(() => {
        setHasMounted(true)
      }, 1000)
    }
  }, [])

  return (
    <div
      className={clsx(
        'w-[380px] relative transition-all duration-200 overflow-hidden',
        {
          'h-[185px] rounded-[12px]': !isEmpty,
          'border border-_928B80 rounded-lg h-[54px] shadow-[0px_2px_16px_0px_rgba(0,0,0,0.08)]':
            isEmpty
        }
      )}
    >
      {/* when there are images */}
      {!isEmpty && (
        <div
          className="h-full w-full flex no-scrollbar transition-all duration-200"
          style={{ transform: `translateX(-${imageIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <PostImagePreview key={index} imgFile={image} />
          ))}
        </div>
      )}
      {/* overlay */}
      {!isEmpty && (
        <>
          <div
            className="absolute top-0 left-0 rounded-[6px] w-full h-full bg-_140C00/[.3] flex items-center justify-center"
            onClick={() => {
              if (imagesCanBeChanged) {
                editImagePickerRef?.current?.click()
              }
            }}
          >
            {images.length > 2 && imagesCanBeDeleted && (
              <button
                type="button"
                onClick={e => {
                  e.stopPropagation()
                  onDelete()
                }}
              >
                <div className="h-10 w-10 bg-FF5018 rounded-full flex items-center justify-center">
                  <svg
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 3.85714H2.5L4 21H16L17.5 3.85714H1M10 8.14286V16.7143M13.75 8.14286L13 16.7143M6.25 8.14286L7 16.7143M7 3.85714L7.75 1H12.25L13 3.85714"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </button>
            )}
          </div>
          <PostImageDots
            images={images}
            activeImageIndex={imageIndex}
            onClickDot={idx => setImageIndex(idx)}
          />
        </>
      )}
      {/* controls */}
      {!isEmpty && (
        <>
          <img
            src={prev}
            className={clsx(
              'post-prev-btn cursor-pointer absolute transform -translate-y-1/2 top-1/2 left-2',
              {
                'opacity-0 pointer-events-none': imageIndex <= 0
              }
            )}
            onClick={e => {
              e.stopPropagation()
              setImageIndex(imageIndex - 1)
            }}
          />
          <img
            src={next}
            className={clsx(
              'post-next-btn cursor-pointer absolute transform translate-y-1/2 top-1/3 right-2',
              {
                'opacity-0 pointer-events-none': !(
                  imageIndex <
                  images?.length - 1
                )
              }
            )}
            onClick={e => {
              e.stopPropagation()
              setImageIndex(imageIndex + 1)
            }}
          />
        </>
      )}
      <input
        type="file"
        data-testid="post-image-edit-input hidden"
        accept={acceptedImages}
        onChange={e => {
          editImage(e)
        }}
        ref={editImagePickerRef}
        className="opacity-0 pointer-events-none"
      />
    </div>
  )
}

export default PostImageEdit
