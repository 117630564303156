const TourStartModal = ({
  setIsOpen,
  enableTour,
  cuate,
  isEnd,
  onGoAgain,
  onDone,
}) => {
  return (
    <div className="w-full ">
      <img src={cuate} className="w-[100px] h-[103px] mx-auto" />
      <p className="text-[32px] font-lato font-bold text-[#140C00] mt-5 text-center">
        {isEnd ? "Congrats" : "Welcome"}
      </p>
      <p className="text-[#58534D] font-lato text-[18px] xs:text-base leading-6 px-10 mt-4 text-center">
        {isEnd
          ? "Our Tour is completed. Anytime you feel like taking the tour again click on the Icon below. Have fun exploring!  "
          : "We’re going to take you on a quick tour. Sit tight and enjoy the ride!"}
      </p>
      <div className="grid grid-cols-2 w-full gap-8 mb-3 mt-10 max-w-xs mx-auto">
        <button
          onClick={() => (isEnd ? onGoAgain() : setIsOpen(false))}
          className="text-[#FF9502] font-lato xs:text-[14px] h-9 leading-6 font-bold bg-white border border-[#FF9502] rounded-lg"
        >
          {isEnd ? "Go again" : "Cancel"}
        </button>
        <button
          onClick={() => (isEnd ? onDone() : enableTour())}
          className="text-white font-lato xs:text-[14px] h-9 leading-6 font-bold bg-[#FF9502] rounded-lg"
        >
          {isEnd ? "Okay, done" : "Okay, let’s start"}
        </button>
      </div>
    </div>
  );
};
export default TourStartModal;
