import React from 'react'
import Skeleton from 'react-loading-skeleton'

const PaginationSkeleton = () => (
  <div className="pagination_box flex gap-3 align-middle justify-center self-center mt-[10px] pb-[30px]">
    <Skeleton height={32} width={25.41} />
    <Skeleton height={32} width={25.41} />
    <Skeleton height={32} width={25.41} />
    <Skeleton height={32} width={25.41} />
    <Skeleton height={32} width={25.41} />
    <Skeleton height={32} width={25.41} />
    <Skeleton height={32} width={25.41} />
  </div>
)

export default PaginationSkeleton
