import { createSlice } from '@reduxjs/toolkit'
import { formatLocation, getProfileImageUrl } from '@utils/helpers'

const initialState = {
  user: null,
  jwt: '',
  loyaltyPoints: ''
}

/** Reducer to manage the auth state and data of the currently logged in user */
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticateUser: (state, action) => {
      const userData = action.payload.user
      state.user = {
        ...userData,
        picture: getProfileImageUrl(userData.picture),
        country: formatLocation(userData.country),
        zip_code: formatLocation(userData.zip_code),
        state: formatLocation(userData.state)
      }
      if (action.payload.jwt) {
        state.jwt = action.payload.jwt
      }
    },
    setLoyaltyPoints: (state, action) => {
      state.loyaltyPoints = action.payload
    },
    logUserOut: state => {
      localStorage.clear()
      state.user = null
      state.jwt = ''
      setTimeout(() => {
        localStorage.clear()
      }, 1000)
    }
  }
})

export const { authenticateUser, logUserOut, setLoyaltyPoints } =
  authSlice.actions

export default authSlice.reducer
