import { shareSocials } from '@constants/utils'
import React from 'react'

const ShareModal = ({ link, message }) => (
  <div className="flex justify-between items-center align-middle w-full my-10">
    <div className="grid grid-cols-4 gap-14 w-full ">
      {shareSocials?.map((item, index) => (
        <a
          key={index}
          target={'_blank'}
          href={item.url(link, message)}
          rel="noreferrer"
        >
          <div
            className="w-[48px] h-[37px] flex flex-col text-center items-center mb-6 gap-2"
            key={index}
          >
            <img src={item.icon} alt={item.name} />
            <p className="font-lato text-[#140C00] text-[16px]">{item.name}</p>
          </div>
        </a>
      ))}
    </div>
  </div>
)

export default ShareModal
