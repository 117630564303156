import React from 'react'
import HeaderSkeleton from '../navigation/HeaderSkeleton'
import Skeleton from 'react-loading-skeleton'

const PostDetailSkeleton = ({ withHeader = true }) => (
  <div className="h-screen overflow-y-hidden">
    {withHeader && <HeaderSkeleton />}
    <div className="px-6 font-lato">
      <div className="flex w-full flex-col 860px:flex-row mx-auto max-w-7xl 860px:mx-auto py-8 860px:py-10">
        <div className="bg-white rounded-t-[12px] 860px:!rounded-tr-none 860px:rounded-bl-[12px] px-4 860px:px-6 860px:w-1/2 860px:h-[calc(100vh_-_200px)] 860px:justify-end">
          <div className="pt-4 860px:pt-[26px] flex flex-col space-y-4 860px:space-y-[27px]">
            <Skeleton width={97.32} height={38.2} borderRadius={8} />
            <div className="h-[400px] w-full relative">
              <Skeleton height={400} borderRadius={6} />
            </div>
          </div>
        </div>
        {/* post detail section */}
        <div className="bg-white no-scrollbar 860px:rounded-tr-[12px] rounded-bl-[12px] 860px:!rounded-bl-none rounded-br-[12px] 860px:w-1/2 overflow-y-auto flex-shrink-0 860px:h-[calc(100vh_-_200px)] 860px:border-l 860px:border-[#928B80]/[.5]">
          {/* post brief details */}
          <div className="pt-4 860px:pt-[60px]">
            <div className="justify-between flex items-center xs:flex-col xs:justify-start xs:space-y-3 xs:items-start">
              <div className="flex space-x-[10px] pl-4 860px:pl-[41px]">
                <Skeleton height={40} width={40} circle />

                <div>
                  <Skeleton height={24} width={100} />
                  <Skeleton height={22} width={70} />
                </div>
              </div>
            </div>
            {/* description */}
            <div className="my-6 pl-4 860px:pl-[41px] pr-6">
              <Skeleton height={21.6} width={150} />
              <Skeleton height={24} width={200} />
              <Skeleton height={24} width={250} />
              <Skeleton height={24} width={200} />
            </div>
            {/* metrics */}

            <div className=" pl-4 860px:pl-[41px] pr-6 flex h-[38px] font-lato items-center justify-between gap-1 w-full border-y border-y-[#928B80]/[.5]">
              <div className="flex relative justify-between items-center gap-[-10px]">
                <div className="flex">
                  <Skeleton width={15} height={15} borderRadius={4} />
                  <Skeleton
                    width={15}
                    height={15}
                    borderRadius={4}
                    className="ml-[-5px]"
                  />
                </div>
                <Skeleton height={14} width={50} />
              </div>
              <div className="text-[#928B80] text-sm text-right flex space-x-4">
                <Skeleton height={20} width={70} />
                <Skeleton height={20} width={70} />
              </div>
            </div>
            {/* end metrics */}
            {/* actions */}
            <div className=" pl-4 860px:pl-[41px] pr-6 flex justify-between items-center py-6 border-b border-b-[#928B80]/[.5]">
              <Skeleton height={30} width={100} />

              <Skeleton height={30} width={100} />

              <Skeleton height={30} width={100} />
            </div>
            {/* end actions */}
          </div>
        </div>
        {/* end post detail section */}
      </div>
    </div>
  </div>
)

export default PostDetailSkeleton
