import apiConfig from '@config/apiConfig'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import toast from 'react-hot-toast'

let isReloadingDueToInvalidToken = false

export const apiHeaders = (headers, { getState }) => {
  const { jwt } = getState()?.auth || {}
  //   headers.set('Content-Type', 'application/json')
  //   headers.set('Accept', 'application/json')
  if (jwt) {
    headers.set('Authorization', `${jwt}`)
  }
  return headers
}

export const onAPISuccess = message => {
  if (message) {
    toast.success(message)
  }
}
export const onAPIError = (error, shouldToast = true) => {
  const handleExplicitError = msg => {
    if (!exemptedToastPaths.includes(window.location.pathname) && shouldToast) {
      toast.error(msg)
    } else {
      return msg
    }
  }
  const exemptedToastPaths = ['/login', '/signup']
  // error.status, error.data
  let errorMsg = ''
  if (error.status === 'FETCH_ERROR' && !error.data) {
    errorMsg = 'Network error: Please check your connection'
  } else {
    if (error.data && !errorMsg) {
      const statusCode = error.status
      if (error.data.message) {
        errorMsg = error.data.message
      } else {
        if (error.data.error && typeof error.data.error === 'string') {
          errorMsg = error.data.error
        }
      }
      if (errorMsg && typeof errorMsg === 'string') {
        if (
          errorMsg.toLowerCase()?.includes('invalid token') &&
          !isReloadingDueToInvalidToken
        ) {
          toast.error('Session expired', { duration: 4000 })
          isReloadingDueToInvalidToken = true
          setTimeout(() => {
            localStorage.clear()
            window.location.reload()
          }, 2000)
          return
        } else {
          return handleExplicitError(errorMsg)
        }
      }
      switch (statusCode) {
        case 400:
          errorMsg = 'Bad Request'
          break
        case 401:
          errorMsg = 'Unauthorized: Please log in.'
          break
        case 403:
          errorMsg = 'Forbidden: You do not have access.'
          break
        case 404:
          errorMsg = 'Not Found: The requested resource could not be found.'
          break
        case 500:
          errorMsg = 'Internal Server Error: Please try again later.'
          break
        default:
          errorMsg = error?.response?.data?.message || 'Server error.'
          break
      }
    } else if (error.request) {
      // No response received from the server
      errorMsg = 'Network error: Please check your connection.'
    } else if (error.code === 'ECONNABORTED') {
      // Request timeout
      errorMsg = 'Request timeout: Please try again.'
    } else {
      errorMsg = 'Something went wrong'
    }
  }
  return handleExplicitError(errorMsg)
}

export const getUrl = args => {
  if (typeof args === 'string') {
    return args
  }
  if (typeof args == 'object' && 'url' in args) {
    return args.url
  }
  return ''
}

export const getCustomBaseQuery =
  baseQuery => async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions)
    if (result.error) {
      const url = getUrl(args)
      if (
        url?.includes('get-user-connections') ||
        url.includes('loyalty/allocate')
        // result?.error?.data?.status === 404)
      ) {
        console.log('silent error', result.error)
      } else {
        onAPIError(result.error)
      }
    } else {
      if (result?.data?.message) {
        onAPISuccess(result?.data?.message)
      }
    }
    return result
  }

export const customBaseQuery = getCustomBaseQuery(
  fetchBaseQuery({
    baseUrl: apiConfig.BaseUrl || 'https://test-api-gateway.maoney.com',
    prepareHeaders: apiHeaders
  })
)

export const POST = 'POST'
export const PATCH = 'PATCH'
export const DELETE = 'DELETE'
export const PUT = 'PUT'
