import apiConfig from '@config/apiConfig'
import { createApi } from '@reduxjs/toolkit/query/react'
import { POST, customBaseQuery } from './util'

export const authService = createApi({
  reducerPath: 'authService',
  baseQuery: customBaseQuery,
  endpoints: builder => ({
    login: builder.mutation({
      query: data => ({
        url: apiConfig.Onboarding.Login,
        method: POST,
        body: data
      })
    }),
    signup: builder.mutation({
      query: data => ({
        url: apiConfig.Onboarding.SignUp,
        method: POST,
        body: data
      })
    }),
    sendOtp: builder.mutation({
      query: data => ({
        url: apiConfig.Onboarding.SendOtp,
        method: POST,
        body: data
      })
    }),
    verifyOtp: builder.mutation({
      query: data => ({
        url: apiConfig.Onboarding.VerifyOTP,
        method: POST,
        body: data
      })
    }),
    resendOtp: builder.mutation({
      query: data => ({
        url: apiConfig.Onboarding.ResendOTP,
        method: POST,
        body: data
      })
    }),
    getUserGoogleProfile: builder.query({
      query: ({ id }) => ({
        url: `${apiConfig.GoogleApi}?access_token=${id}`
      })
    }),
    googleProfile: builder.mutation({
      query: body => {
        const user = { user: body }
        return {
          url: apiConfig.User.GoogleLogin,
          method: POST,
          body: user
        }
      }
    }),
    changeIdentifier: builder.mutation({
      query: ({ userId, type, old_identifier, new_identifier }) => ({
        url: `${apiConfig.User.ChangeIdentifier}`,
        method: 'POST',
        body: {
          user_id: userId,
          identifier_type: type,
          old_identifier,
          new_identifier
        }
      })
    })
  })
})

export const {
  useLoginMutation,
  useSignupMutation,
  useVerifyOtpMutation,
  useResendOtpMutation,
  useSendOtpMutation,
  useLazyGetUserGoogleProfileQuery,
  useGoogleProfileMutation,
  useChangeIdentifierMutation
} = authService
