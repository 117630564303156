import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { deepClone } from '@utils/helpers'

// interface StateType {
//   pageCount: number
//   posts: any[]
//   postToUpdate: null | { data: any; updateType: 'add-progress' | 'default' }
//   postToDelete: null | any
//   commentToDelete: null | {postId: number; comment:number;}
//   shareModalState: { visible: boolean; post: any }
// }

// const initialState: StateType = {
const initialState = {
  pageCount: 0,
  posts: [],
  postToUpdate: null, // {data:post, updateType:"add-progress"|"update"}
  postToDelete: null, // ...post
  commentToDelete: null, // ...comment
  shareModalState: { visible: false, post: null },
  imagesModalState: { visible: false, images: [], index: 0 },
  postToBeNotifiedAbout: null,
  subscribersMessagePost: null,
  deletedCommentDetails: null,
  userPostsPreview: { userId: null, data: [] }
}

/** Reducer to manage posts */
const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setUserPostsPreview: (state, action) => {
      console.log(action.payload, 'posts preview')
      state.userPostsPreview = action.payload
    },
    setLatestPosts: (state, action) => {
      const previousPostIds = state.posts.map(p => p.id)

      const newPosts = action.payload.filter(
        post => !previousPostIds.includes(post.id)
      )
      state.posts = [...newPosts, ...state.posts]
    },
    setNewPostsWithPage: (state, action) => {
      const previousPostIds = state.posts.map(p => p.id)
      const newPosts = action.payload.posts.filter(
        post => !previousPostIds.includes(post.id)
      )
      const page = action.payload.page
      state.posts = [...state.posts, ...newPosts]
      state.pageCount = page
    },
    setPosts: (state, action) => {
      state.posts = action.payload
    },
    setPostToUpdate: (
      state,
      action
      // action: PayloadAction<{
      //   data: any
      //   updateType: 'add-progress' | 'default'
      // } | null>
    ) => {
      state.postToUpdate = action.payload
    },
    setPostToDelete: (state, action) => {
      state.postToDelete = action.payload
    },
    setPostToShare: (state, action) => {
      state.shareModalState = { visible: true, post: action.payload }
    },
    clearPostToShare: state => {
      state.shareModalState = {
        visible: false,
        post: state.shareModalState.post
      }
    },
    setCommentToDelete: (state, action) => {
      state.commentToDelete = action.payload
    },
    setDeletedCommentDetails: (state, action) => {
      state.deletedCommentDetails = action.payload
    },
    setImagesToDisplay: (state, action) => {
      state.imagesModalState = {
        visible: true,
        images: action.payload.images,
        index: action.payload.index || 0
      }
    },
    clearImagesToDisplay: state => {
      state.imagesModalState = {
        visible: false,
        images: state.imagesModalState.images,
        index: 0
      }
    },
    refreshSinglePost: (
      state,
      action
      // action: PayloadAction<{ postId: number; data: any }>
    ) => {
      const newPosts = [...(state.posts || [])].map(post => {
        if (post.id === action.payload.postId) {
          return action.payload.data
        } else {
          return post
        }
      })
      state.posts = newPosts
    },
    updatePostsByLikeAction: (
      state,
      action
      // action: PayloadAction<{
      //   action: 'like' | 'unlike'
      //   commentId: number
      //   userId: number
      // }>
    ) => {
      const { action: userAction, commentId, userId } = action.payload
      const clonedFeedsListing = deepClone(state.posts)
      const feedIndex = clonedFeedsListing.findIndex(
        feed =>
          feed.comments &&
          feed.comments.some(comment => comment.id === commentId)
      )
      if (feedIndex !== -1) {
        const clonedFeed = { ...clonedFeedsListing[feedIndex] }
        const commentIndex = clonedFeed.comments.findIndex(
          comment => comment.id === commentId
        )
        if (commentIndex !== -1) {
          const clonedComment = { ...clonedFeed.comments[commentIndex] }
          if (userAction === 'like') {
            if (!clonedComment.likes) {
              clonedComment.likes = []
            }
            clonedComment.likes.push({
              comment_id: commentId,
              user_id: userId
            })
          } else if (userAction === 'unlike') {
            clonedComment.likes = clonedComment.likes.filter(
              like =>
                !(like.comment_id === commentId && like.user_id === userId)
            )
          }
          clonedFeed.comments[commentIndex] = clonedComment
          clonedFeedsListing[feedIndex] = clonedFeed
          state.posts = clonedFeedsListing
        }
      }
    },
    setPostToBeNotifiedAbout: (state, action) => {
      state.postToBeNotifiedAbout = action.payload
    },
    setSubscribersMessagePost: (state, action) => {
      state.subscribersMessagePost = action.payload
    }
  }
})

export const {
  setNewPostsWithPage,
  setPosts,
  setPostToUpdate,
  setPostToDelete,
  setPostToShare,
  clearPostToShare,
  setCommentToDelete,
  refreshSinglePost,
  updatePostsByLikeAction,
  setImagesToDisplay,
  clearImagesToDisplay,
  setLatestPosts,
  setDeletedCommentDetails,
  setPostToBeNotifiedAbout,
  setSubscribersMessagePost,
  setUserPostsPreview
} = postsSlice.actions

export default postsSlice.reducer
