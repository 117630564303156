import DeleteComment from '@components/posts/DeleteComment'
import DeletePost from '@components/posts/DeletePost'
import ImageDisplayModal from '@components/posts/ImageDisplayModal'
import MessageSubscribers from '@components/posts/message-subscribers'
import SharePost from '@components/posts/SharePost'
import { Outlet } from 'react-router-dom'

function Dashboard() {
  return (
    <div className="h-[100vh] w-full flex flex-col">
      <ImageDisplayModal />
      <DeletePost />
      <SharePost />
      <DeleteComment />
      <MessageSubscribers />
      <div className="flex-grow h-full">
        <Outlet />
      </div>
    </div>
  )
}

export default Dashboard
