import { PrimaryButton } from '@components/Buttons'
import validators from '@utils/validators'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

const defaultValues = {
  message: ''
}

const MessageInput = ({ data, setData, onProceed }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm({ defaultValues })

  useEffect(() => {
    setValue('message', data.message)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onSubmit = ({ message }) => {
    setData(prev => ({ ...prev, message }))
    onProceed()
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="pt-5 md-full:pt-[64px] w-full space-y-2 flex flex-col"
    >
      <label className="font-lato text-base leading-6 text-_140C00">
        Write your message here
      </label>
      <textarea
        cols={5}
        placeholder="Hello guys, I've started saving to buy a car. This is a
        Dodge Charger, my dream car and I believe I would
        be able to save and buy the car."
        {...register('message', { validate: validators.validateString })}
        className={clsx(
          'resize-none h-[161px] rounded-lg focus:outline-none border-_928B80 border outline-none shadow-post-card-tooltip p-4 font-lato text-base leading-6 placeholder:text-[#928B80]',
          {
            '!border-red-500': !!errors.message
          }
        )}
      />
      <div className="absolute bottom-0 left-0 px-5 md-full:px-[99px] w-full">
        <PrimaryButton type="submit" text={'Review'} />
      </div>
    </form>
  )
}

export default MessageInput
