import arrow_left from '@assets/icons/arrow_left.svg'
import close from '@assets/icons/close.svg'
import Loader from '@components/Loaders/Loader'
import { Dialog, Transition } from '@headlessui/react'
import { updateWalkthoughMenu } from '@store/slices/app'
import { authenticateUser } from '@store/slices/auth'
import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useLazyGetUserProfileQuery,
  useUpdateUserDetailsMutation
} from '@services/profile-service'

const Modal = ({
  title,
  content,
  setOpen,
  open,
  onClose = setOpen,
  size = 'max-w-3xl',
  showBackButton = false,
  BackButtonClick = () => {}
}) => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth)
  const { walkthroughMenu: menu } = useSelector(state => state.app)
  const [walkthroughContinueLoader, setWalkthroughContinueLoader] =
    useState(false)
  const [updateUserDetails] = useUpdateUserDetailsMutation()
  const [getUserProfile] = useLazyGetUserProfileQuery()

  const isWalkthroughTaken = true
  const onWalkthroughComplete = async () => {
    setWalkthroughContinueLoader(true)
    const userPayload = {
      userId: user?.id,
      walkthroughStage: 3,
      isWalkthroughTaken: true,
      tourCompleted: true
    }
    try {
      await updateUserDetails(userPayload)
    } catch (error) {
      console.log(error)
    } finally {
      await getUserProfile(user?.id)
        .unwrap()
        .then(response => {
          dispatch(authenticateUser({ user: { ...user, ...response } }))
        })
      setWalkthroughContinueLoader(false)
      dispatch(updateWalkthoughMenu('Walkthrough Complete'))
      setOpen(false)
    }
  }

  return (
    <>
      {open && (
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-[100] " onClose={onClose}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="w-rounded-2xl">
              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="maoney-modal flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel
                      className={`relative transform overflow-hidden rounded-3xl
                      bg-white text-left shadow-xl transition-all xs:my-8 xs:w-full xs:max-w-lg  ${size}`}
                    >
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative">
                        <div className=" absolute top-0 w-full flex items-center p-4 pr-8">
                          {isWalkthroughTaken && (
                            <>
                              {menu === 'Update Profile' && (
                                <div className="flex-shrink-0 w-10 h-10 bg-[#FF9502] rounded-full flex items-center justify-center text-white font-lato text-center text-2xl float-left">
                                  1
                                </div>
                              )}
                              {menu === 'Invite Modal' && (
                                <div className="flex-shrink-0 w-10 h-10 bg-[#FF9502] rounded-full flex items-center justify-center text-white font-lato text-center text-2xl float-left">
                                  3
                                </div>
                              )}
                            </>
                          )}
                          {!isWalkthroughTaken && title === 'QR Code' && (
                            <div className="mt-4 w-full">
                              {walkthroughContinueLoader ? (
                                <Loader type="regular" label="loading..." />
                              ) : (
                                <div
                                  className="flex items-center justify-center float-right w-[36px] h-[37px] cursor-pointer mb-3"
                                  onClick={onWalkthroughComplete}
                                >
                                  <img src={close} />.
                                </div>
                              )}
                            </div>
                          )}
                          {showBackButton && (
                            <p onClick={() => BackButtonClick('')}>
                              <img
                                src={arrow_left}
                                className="cursor-pointer"
                              />
                            </p>
                          )}
                          <div className="flex-grow"></div>
                          {isWalkthroughTaken && (
                            <div
                              onClick={() => {
                                setOpen(false)
                                // dispatch(updateWalkthroughMenu(null));
                              }}
                              className="flex items-center justify-center float-right w-[36px] h-[37px] cursor-pointer mb-3 bg-[#928B8033] rounded-full"
                            >
                              <img src={close} />
                            </div>
                          )}
                        </div>
                        <div className="sm:flex sm:items-center sm:justify-center">
                          <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title
                              as="h3"
                              className="webheading4 text-center !text-[18px] md-full:!text-[24px]"
                            >
                              {title}
                            </Dialog.Title>
                            <div className={`${!!title && 'mt-2'}`}>
                              {content}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  )
}

export default Modal
