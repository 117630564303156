/* eslint-disable react/prop-types */
import bx_time from '@assets/icons/bx_time.svg'
import comment from '@assets/icons/chat-icon.svg'
import goal_post from '@assets/icons/goal_post.svg'
import like_frame from '@assets/icons/like-frame.svg'
import like from '@assets/icons/like.svg'
import love from '@assets/icons/love.svg'
import { ReactComponent as Calendar } from '@assets/icons/post-calendar.svg'
import { ReactComponent as Clock } from '@assets/icons/post-clock.svg'
import { ReactComponent as Marker } from '@assets/icons/post-marker.svg'
import share_icon from '@assets/icons/share-icon.svg'
import Spinner from '@components/Loaders/Spinner'
import UserProfileImage from '@components/utils/UserAvatar'
import { editPostTypes, postTypes } from '@constants/posts'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import usePosts from '@hooks/posts/usePosts'
import useUserInfo from '@hooks/users/useUserInfo'
import {
  useAddProgressToPostMutation,
  useCreatePostMutation,
  useEditPostMutation,
  useEditProgressMutation,
  useLazyGetPostsQuery
} from '@services/posts-service'
import { currencyFormat, dateFormater } from '@utils/utils'
import moment from 'moment'
import { useMemo } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { createEventDate, getImagePreviewUrl } from './utils'
import PostCardImages from '../PostCardImages'
import { setLatestPosts } from '@store/slices/posts'

/** isProgressEdit -> You're adding progress */
/** isEditingAProgress -> You're editing a progress */

const PostPreview = ({
  postData,
  onProceed,
  dataToEdit,
  dataToEditIndex,
  isProgressEdit,
  isEditingAProgress
}) => {
  const { getMemberLocation, getMemberName } = useUserInfo()
  const {
    purpose,
    description,
    media,
    required_amount: amount,
    deadline,
    current_amount: amountSoFar,
    hasDonation,
    date,
    time,
    address,
    type,
    event_date_time,
    event_address
  } = postData

  const { postToUpdate, upsertPosts } = usePosts()
  const isAddProgress = postToUpdate?.updateType === editPostTypes.addProgress

  const dispatch = useDispatch()
  const { posts: feeds } = useSelector(state => state.posts)
  const { walkthroughMenu: menu } = useSelector(state => state.app)
  const { user } = useSelector(state => state.auth)

  const [fetchPosts] = useLazyGetPostsQuery()
  const [editPost, { isLoading: isEditingPost }] = useEditPostMutation()
  const [addProgressToPost, { isLoading: isAddingProgressToPost }] =
    useAddProgressToPostMutation()
  const [editProgress, { isLoading: isEditingProgress }] =
    useEditProgressMutation()
  const [createPost, { isLoading }] = useCreatePostMutation()

  const getLatestPosts = async () => {
    try {
      const response = await fetchPosts().unwrap()
      const latestPosts = response?.posts || []
      dispatch(setLatestPosts(latestPosts))
    } catch (e) {
      console.log(e)
    }
  }

  const handleCreatePost = async () => {
    try {
      const formData = new FormData()
      formData.append('user_id', user?.id)
      formData.append('purpose', postData.purpose)
      formData.append('description', postData.description)
      formData.append(
        'required_amount',
        parseInt(postData.required_amount) || 0
      )
      formData.append(
        'deadline',
        postData.deadline || new Date('2022-01-01').toISOString().split('T')[0]
      )
      formData.append('current_amount', parseInt(postData.current_amount) || 0)
      formData.append('bank_id', 0)
      postData.media.map(image => {
        formData.append('media', image)
      })
      formData.append('type', postData.type)
      if (postData.type === postTypes.event) {
        const { date, time, address } = postData
        formData.append('event_address', address)
        formData.append(
          'event_date_time',
          createEventDate(date, time).toISOString()
        )
      }
      const response = await createPost(formData).unwrap()
      if (response?.success) {
        getLatestPosts()
        onProceed()
      } else {
        toast.error('There was an error. Please try again')
      }
    } catch (error) {
      toast.error('There was an error')
      console.error(error)
    }
  }

  const createProgress = async payload => {
    const postId = postToUpdate?.data?.id
    try {
      const response = await addProgressToPost({
        postId,
        requiredObject: payload
      }).unwrap()
      if (response?.success) {
        onProceed()
        // update feeds
        const newFeeds = feeds.map(feed => {
          if (feed.id === postId) {
            return { ...feed, ...response.post }
          }
          return feed
        })
        upsertPosts(newFeeds)
      } else {
        toast.error('There was an error. Please try again')
      }
    } catch (e) {
      toast.error('There was an error. Please try again')
    }
  }

  const onEditPost = async payload => {
    const postId = postToUpdate?.data?.id
    const delete_urls = postToUpdate.data.media[dataToEditIndex].filter(
      item => !postData.media.includes(item)
    )
    if (delete_urls.length) {
      // payload is formdata at this point
      payload.append('delete_urls', delete_urls.join(', '))
    }
    try {
      const response = await editPost({
        postId,
        requiredObject: payload
      }).unwrap()
      if (response?.success) {
        // update feeds
        const newFeeds = feeds.map(feed => {
          if (feed.id === postId) {
            return { ...feed, ...response.post }
          }
          return feed
        })
        upsertPosts(newFeeds)
        onProceed()
      } else {
        toast.error('There was an error. Please try again')
      }
    } catch (e) {
      toast.error('There was an error. Please try again')
    }
  }

  const onEditProgress = async () => {
    const postId = postToUpdate?.data?.id
    try {
      const delete_urls = postToUpdate.data.media[dataToEditIndex].filter(
        item => !postData.media.includes(item)
      )
      const payload = {
        description: postData.description,
        progress_index: dataToEditIndex
      }
      if (delete_urls.length) {
        payload.delete_urls = delete_urls
      }
      const response = await editProgress({
        postId,
        requiredObject: payload
      }).unwrap()
      if (response?.success) {
        onProceed()
        // update feeds
        const newFeeds = feeds.map(feed => {
          if (feed.id === postId) {
            return { ...feed, ...response.post }
          }
          return feed
        })
        upsertPosts(newFeeds)
      } else {
        toast.error('There was an error. Please try again')
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onSubmit = () => {
    const updateData = new FormData()
    const payload = { ...postData }
    if (payload?.current_amount?.length) {
      payload.current_amount = Number(payload?.current_amount)
    }
    if (payload.deadline) {
      payload.deadline = moment(payload.deadline)?.format('YYYY-MM-DD')
    }
    payload.updated_at = moment()?.toISOString()
    updateData.append('user_id', user?.id || payload.user_id)
    updateData.append('bank_id', payload.bank_id || 0)
    updateData.append('purpose', payload.purpose)
    updateData.append('description', payload.description)
    const images = payload.media.filter(item => item instanceof File)
    images.map(image => {
      updateData.append('media', image)
    })
    updateData.append('required_amount', parseInt(payload.required_amount) || 0)
    updateData.append(
      'deadline',
      payload.deadline || new Date('2022-01-01').toISOString().split('T')[0]
    )
    updateData.append('current_amount', parseInt(payload.current_amount) || 0)
    updateData.append('updated_at', moment()?.toISOString())
    if (postData.type === postTypes.event) {
      if (isProgressEdit) {
        updateData.append('event_address', payload.event_address)
        updateData.append('event_date_time', payload.event_date_time)
      } else {
        const { date, time, address } = payload
        updateData.append('event_address', address)
        updateData.append(
          'event_date_time',
          createEventDate(date, time).toISOString()
        )
      }
    }
    updateData.append('type', postData.type)
    // if (postToUpdate && !isAddProgress) {
    //   const updateUrlIndexes = []
    //   payload.media.forEach((item, index) => {
    //     if (item instanceof File) {
    //       updateUrlIndexes.push(index)
    //     }
    //   })
    //   const updateUrls = updateUrlIndexes.map(
    //     index => postToUpdate.data.media[dataToEditIndex][index]
    //   )
    //   if (updateUrls.length) {
    //     updateData.append('update_urls', updateUrls.join(', '))
    //   }
    // }
    // if it's add progress
    if (isAddProgress) {
      createProgress(updateData)
      return
    }
    // if it's an edit
    if (dataToEdit) {
      // if it's progress being edited
      if (isEditingAProgress) {
        onEditProgress()
      }
      // or it's the main post
      else {
        onEditPost(updateData)
      }
    } else {
      handleCreatePost()
    }
  }

  const formattedUrl = user?.picture

  const blobs = useMemo(
    () => media.map(item => getImagePreviewUrl(item)),
    [media]
  )

  const loading =
    isLoading || isEditingProgress || isEditingPost || isAddingProgressToPost

  return (
    <div className="h-full flex flex-col " data-testid="post-preview-card">
      <div className="shadow-[0_9px_26px_0_rgba(20,12,0,0.24)] font-lato rounded-md bg-[#ffffff] mt-8 mb-5 w-full md-full:w-[300px] mx-auto">
        <div className="p-4">
          <div className="flex items-start flex-row">
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center gap-1">
                {/* <img
                  src={user?.picture}
                  className="w-6 h-6 rounded-full overflow-hidden"
                /> */}
                {!user?.picture ? (
                  <UserProfileImage
                    user={user}
                    width={30}
                    height={30}
                    small={true}
                  />
                ) : (
                  <img
                    data-testid="user-profile-picture"
                    src={formattedUrl}
                    alt="avatar"
                    className="w-6 h-6 rounded-full overflow-hidden"
                  />
                )}
                <div>
                  <p
                    data-testid="user-name"
                    className="text-[11px] text-[#140C00] line-clamp-1"
                  >
                    {getMemberName(user)}
                  </p>
                  {user?.location_status === 0 && (
                    <p
                      className="text-[10px] text-[#928B80]"
                      data-testid="user-location"
                    >
                      {getMemberLocation(user)}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {hasDonation && (
              <div className="flex gap-2">
                <div className="flex gap-1">
                  <div className="flex items-center justify-center py-0.5 px-2 rounded-md border-[0.5px] border-[#FF9502] text-[8px] text-[#FF9502] whitespace-nowrap">
                    View Donors
                  </div>
                  <div className="flex items-center justify-center py-0.5 px-2 rounded-md bg-[#FF9502] text-[8px] text-white">
                    Donate
                  </div>
                </div>
                <div className="">
                  <FontAwesomeIcon
                    className="h-2 w-2 align-top"
                    ariaHidden="true"
                    icon={faEllipsisV}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="mt-3">
            <p
              className="text-[#140C00] text-[11px] text-left"
              data-testid="post-purpose"
            >
              {purpose}
            </p>
            {type === postTypes.event && (
              <div className="mt-[6px] mb-[10.63px] flex flex-col space-y-[9px]">
                <div className="flex items-center space-x-6">
                  {/* date */}
                  <div className="flex items-center space-x-1">
                    <Calendar />
                    <span
                      data-testid="event-date"
                      className="text-[7.79px] leading-[12.24px] text-[#58534D]"
                    >
                      By:{' '}
                      {moment(isProgressEdit ? event_date_time : date).format(
                        'MMM Do, YYYY'
                      )}
                    </span>
                  </div>
                  {/* time */}
                  <div className="flex items-center space-x-1">
                    <Clock />
                    <span
                      data-testid="event-time"
                      className="text-[7.79px] leading-[12.24px] text-[#58534D]"
                    >
                      {isProgressEdit
                        ? moment(event_date_time).format('hh:mm A')
                        : time}
                    </span>
                  </div>
                </div>
                <div className="flex items-center space-x-1">
                  <Marker />
                  <span
                    data-testid="event-address"
                    className="text-[7.94px] text-_140C00"
                  >
                    {isProgressEdit ? event_address : address}
                  </span>
                </div>
              </div>
            )}
            {amount != null && amount !== '' && amount !== 0 && (
              <div className="flex items-center gap-4 mt-1.5">
                <div className="flex gap-1">
                  <img
                    src={goal_post}
                    className="w-3"
                    data-testid="goal-icon"
                  />
                  <div
                    className="text-[#58534D] text-[8px]"
                    data-testid="goal-amount"
                  >
                    {currencyFormat(parseInt(amount))}
                  </div>
                </div>
                <div className="flex gap-1">
                  <img
                    src={bx_time}
                    className="w-3"
                    data-testid="goal-deadline-icon"
                  />
                  <div
                    className="text-[#58534D] text-[8px]"
                    data-testid="goal-deadline"
                  >
                    By: {dateFormater(new Date(deadline))}
                  </div>
                </div>
              </div>
            )}
          </div>

          <p
            className="text-[#58534D] text-[10px] mt-3.5 text-left"
            data-testid="post-description"
          >
            {description}
          </p>
          {type === postTypes.event && (
            <div className="flex space-x-[15px] mb-1 mt-4 items-center">
              <button
                data-testid="participants-btn"
                type="button"
                className="border-[0.56px] flex-1 h-[17.45px] border-primary rounded-[5.57px] px-[8.91px] flex items-center justify-center"
              >
                <span className="font-lato text-[7.79px] text-primary">
                  Participants
                </span>
              </button>
              <button
                data-testid="register-btn"
                type="button"
                className="h-[17.45px] bg-primary flex-1 rounded-[5.57px] px-[13.36px] flex items-center justify-center"
              >
                <span className="font-lato text-[7.79px] text-white">
                  Register
                </span>
              </button>
            </div>
          )}
          <div className="mt-3 h-32">
            <PostCardImages images={blobs} />
            {/* <img
              id="displayImageId"
              className="w-full h-32 rounded object-cover"
              alt="No Image"
              src={blob || media || image}
            /> */}
          </div>

          {amountSoFar !== '' && amountSoFar !== 0 && (
            <div className="w-full mt-3">
              <div
                className={`text-[#58534D] text-[8px] text-left`}
                data-testid="goal-amount-so-far"
              >
                {currencyFormat(parseInt(amountSoFar)) ?? ''} so far
              </div>

              {amountSoFar?.length && (
                <div className="flex items-center w-full gap-1.5">
                  <div className="h-1 w-full bg-[#3C3C432E] rounded-full">
                    <div
                      className="h-1 bg-[#FF9502] rounded-full"
                      style={{
                        width:
                          +amountSoFar > +amount
                            ? '100%'
                            : (
                                (parseInt(amountSoFar) / parseInt(amount)) *
                                100
                              ).toFixed() + '%'
                      }}
                    ></div>
                  </div>
                  <div className="text-[#58534D] text-[8px] text-right">
                    {+amountSoFar > +amount
                      ? '100%'
                      : (
                          (parseInt(amountSoFar) / parseInt(amount)) *
                          100
                        ).toFixed() + '%'}
                  </div>
                </div>
              )}
              <hr className="h-px bg-[#928B80] border-0 mt-2.5"></hr>
            </div>
          )}

          <div className="flex items-center justify-between gap-1 w-full my-2">
            <div className="flex relative justify-between items-center gap-1">
              <div className="flex">
                <img src={love} />
                <img className="ml-[-5px]" src={like_frame} />
              </div>
              <div className="text-[#928B80] text-[10px]">
                <span id={`liked_`}>{postData?.likesCount}</span> people
              </div>
            </div>
            <div className="text-[#928B80] text-[10px] text-right">
              {postData?.comments?.length ?? 0} comments .{' '}
              {postData?.shares?.length ?? 0} Shares
            </div>
          </div>

          <hr className="mt-2.5 h-px bg-[#928B80] border-0 mb-3"></hr>
          <div className="flex justify-between items-center ">
            <div className="flex items-center gap-1.5 text-[10px] text-[#58534D]">
              <img src={like} className="w-3" />
              <p>Like</p>
            </div>

            <div className="flex items-center gap-1.5 text-[10px] text-[#58534D]">
              <img src={comment} className="w-3" />
              <p>Comment</p>
            </div>
            <div className="flex items-center gap-1.5 text-[10px] text-[#58534D]">
              <img src={share_icon} className="w-3" />
              <p>Share</p>
            </div>
          </div>
        </div>
      </div>
      {menu === 'Create Post' && (
        <div className="mx-auto mb-4 mt-auto">
          <div className="flex items-center justify-center gap-2">
            <div className="w-2 h-2 rounded-full bg-[#928B8066]"></div>
            <div className="w-2 h-2 rounded-full bg-[#FF9502]"></div>
            <div className="w-2 h-2 rounded-full bg-[#928B8066]"></div>
          </div>
        </div>
      )}
      <div className="flex items-center justify-center w-full mt-auto">
        <div className="w-full max-w-sm pb-5">
          <button
            data-testid="preview-submit-btn"
            disabled={loading}
            onClick={onSubmit}
            style={{
              backgroundColor: '#FF9502'
            }}
            className="w-full rounded-lg text-white font-bold px-10 py-4 cursor-pointer h-[56px]"
          >
            {loading ? (
              <Spinner className={'!h-5 !scale-75'} />
            ) : postToUpdate?.data ? (
              postToUpdate?.updateType === 'add-progress' ? (
                'Post'
              ) : (
                'Save Changes'
              )
            ) : (
              'Post'
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default PostPreview
