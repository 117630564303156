/* eslint-disable react/prop-types */
import clsx from 'clsx'
import React from 'react'

const PostImageDots = ({ images, activeImageIndex, onClickDot }) => (
  <div className="absolute bottom-2 left-1/2 -translate-x-1/2 flex space-x-[6px]">
    {Array(images.length)
      .fill(0)
      .map((img, index) => (
        <div
          onClick={() => onClickDot(index)}
          className={clsx('h-2 w-2 rounded-full bg-white/[.6] cursor-pointer', {
            '!bg-primary': activeImageIndex === index
          })}
          key={index}
        ></div>
      ))}
  </div>
)

export default PostImageDots
