import React from "react";

const NoMatch = () => {
  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>404 - Not Found</h2>
      <p style={styles.message}>
        Sorry, the page you are looking for might not exist.
      </p>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
    backgroundColor: "#f0f0f0",
  },
  heading: {
    fontSize: "2em",
    color: "#333",
    marginBottom: "10px",
  },
  message: {
    fontSize: "1.2em",
    color: "#555",
  },
};

export default NoMatch;
