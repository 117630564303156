import ErrorBoundary from '@components/ErrorFallback/ErrorBoundary'
import Protected from '@components/navigation/Protected'
import UnProtected from '@components/navigation/UnProtected'
import NoMatch from '@components/pages/Landing/pages/NoMatch'
import { lazy } from '@constants/react-lazy-with-reload'
import { Outlet } from 'react-router'
import routes from './routesList'

// *** Outside of Dashboard ***
const DataDeletion = lazy(() => import('@page/Landing/data-deletion'))
const ComingSoon = lazy(() => import('@page/ComingSoon'))
const Landing = lazy(() => import('@page/Landing'))
const Login = lazy(() => import('@page/Landing/login'))
const SignUp = lazy(() => import('@page/Landing/signup'))
const PrivacyPolicy = lazy(() => import('@page/Landing/privacy-policy'))
const Terms = lazy(() => import('@page/Landing/terms'))
const OnboardSignupDialogPage = lazy(() => import('@page/Landing/onboarding'))
const Blog = lazy(() => import('@page/Landing/blog'))
const Article = lazy(() => import('@page/Landing/blog/article'))
const FrequentlyAskedQuestions = lazy(() => import('@page/Landing/faqs'))

// *** Inside Dashboard ***
import Dashboard from '@components/pages/Dashboard/Dashboard'
const Home = lazy(() => import('@page/Dashboard/home'))
const SearchResultList = lazy(() => import('@page/Dashboard/search'))
const Notifications = lazy(() => import('@page/Dashboard/notifications'))
const OtherUserProfile = lazy(
  () => import('@page/Dashboard/profile/OtherUserProfile')
)
const Profile = lazy(() => import('@page/Dashboard/profile'))
const UserPosts = lazy(() => import('@page/Dashboard/posts/UserPosts'))
const Settings = lazy(
  () => import('@components/pages/Dashboard/settings/Settings')
)
const BuyPoints = lazy(() => import('@page/Dashboard/points/BuyPoints'))
const EditProfile = lazy(() => import('@page/Dashboard/settings/EditProfile'))
const EditNotifications = lazy(
  () => import('@page/Dashboard/settings/EditNotifications')
)
const Security = lazy(() => import('@page/Dashboard/settings/Security'))
const PostDetail = lazy(() => import('@page/Dashboard/posts/PostDetail'))
// const Chats = lazy(() => import('@pages/Chat'))
// const TribePublicProfile = lazy(() => import('@pages/TribePublicProfile'))
// const MobileMenu = lazy(() => import('@pages/MobileMenu'))
// const Callback = lazy(() => import('@pages/Onboarding/callback'))
// const Invite = lazy(() => import('@pages/Onboarding/invite'))
// const SecureAccount = lazy(() => import('@pages/Onboarding/password'))
// const Share = lazy(() => import('@pages/Onboarding/share'))
// const EditProfile = lazy(() => import('@pages/Settings/EditProfile'))
// const Security = lazy(() => import('@pages/Settings/Security'))
// const TribeProfile = lazy(() => import('@pages/TribeProfile'))
// const MemberProfile = lazy(
//   () => import('../components/Landing/pages/MemberProfile')
// )

// **** Unused **** */

// import Banking from '../components/Dashboard/Banking'
// import Wallets from '../components/Dashboard/Banking/Wallets'
// import WalletsDashboard from '../components/Dashboard/Banking/Wallets/WalletsDashboard'
// import RewardsDetail from '../components/Dashboard/Banking/Wallets/RewardsDetail'
// import AddWallets from '../components/Dashboard/Banking/Wallets/AddWallets'
// import CardDetail from '../components/Dashboard/Banking/Wallets/CardDetail'
// import Congrats from '../components/Dashboard/Banking/components/Congrats'
// import MoneyTransfers from '../components/Dashboard/Banking/MoneyTransfers'
// import Donations from '../components/Dashboard/Banking/Donations'
// import DonationLists from '../components/Dashboard/Banking/Donations/DonationsLists'
// import DonationsInfo from '../components/Dashboard/Banking/Donations/DonationsInfo'
// import DonationsRequest from '../components/Dashboard/Banking/Donations/DonationsRequest'
// import LendBorrowSteps from '../components/Dashboard/Banking/LendBorrow/LendBorrowSteps'
// import LendBorrow from '../components/Dashboard/Banking/LendBorrow'
// import AddCategory from '../components/Dashboard/Banking/LendBorrow/AddCategory'
// import CategoryDetail from '../components/Dashboard/Banking/LendBorrow/CategoryDetail'
// import SelectFeatureLendBorrow from '../components/Dashboard/Banking/LendBorrow/SelectFeatureLendBorrow'
// import AddExistingLoans from '../components/Dashboard/Banking/AddExistingLoans'
// import AddExistingLoansSteps from '../components/Dashboard/Banking/AddExistingLoans/AddExistingLoansSteps'
// import SelectWhoYouAre from '../components/Dashboard/Banking/AddExistingLoans/SelectWhoYouAre'
// import BorrowFromMany from '../components/Dashboard/Banking/BorrowFromMany'
// import LoanNotifications from '../components/Dashboard/Banking/LoanNotifications'
// import LoanConractDetail from '../components/Dashboard/Banking/LoanNotifications/LoanContractDetail'
// import LoanNotificationList from '../components/Dashboard/Banking/LoanNotifications/LoanNotificaitonsList'
// import ManageLoans from '../components/Dashboard/Banking/ManageLoans'
// import ManageLoansDashboard from '../components/Dashboard/Banking/ManageLoans/ManageLoansDashboard'
// import LoanDetail from '../components/Dashboard/Banking/ManageLoans/LoanDetail'
// import LoansList from '../components/Dashboard/Banking/ManageLoans/LoansList'
// import Transactions from '../components/Dashboard/Banking/Transactions'

/** Skeleton */
import DashboardSkeleton from '@components/Skeletons/dashboard/home'
import NotificationsSkeleton from '@components/Skeletons/dashboard/home/notifications'
import AllPostsSkeleton from '@components/Skeletons/dashboard/post/AllPostsSkeleton'
import PostDetailSkeleton from '@components/Skeletons/dashboard/post/PostDetailSkeleton'
import ProfilePageSkeleton from '@components/Skeletons/dashboard/profile'
import EditProfileSkeleton from '@components/Skeletons/dashboard/settings/EditProfileSkeleton'
import ManageSecuritySkeleton from '@components/Skeletons/dashboard/settings/ManageSecuritySkeleton'
import Content from '@components/navigation/Content'

const MaoneyRoutes = [
  {
    path: '/',
    element: (
      <UnProtected>
        <Outlet></Outlet>
      </UnProtected>
    ),
    children: [
      {
        path: '/',
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <Landing />
          </ErrorBoundary>
        )
      },
      {
        path: '/coming-soon',
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <ComingSoon />
          </ErrorBoundary>
        )
      },
      {
        path: '/privacy-policy',
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <PrivacyPolicy />
          </ErrorBoundary>
        )
      },
      {
        path: '/terms',
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <Terms />
          </ErrorBoundary>
        )
      },
      {
        path: '/data-deletion',
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <DataDeletion />
          </ErrorBoundary>
        )
      },
      {
        path: routes.onboarding,
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <OnboardSignupDialogPage />
          </ErrorBoundary>
        )
      },
      {
        path: routes.signup,
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <SignUp />
          </ErrorBoundary>
        )
      },
      {
        path: '/login',
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <Login />
          </ErrorBoundary>
        )
      },
      {
        path: '/faqs',
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <FrequentlyAskedQuestions />
          </ErrorBoundary>
        )
      },
      {
        path: '/blog',
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <Blog />
          </ErrorBoundary>
        )
      },
      {
        path: '/blog/:slug',
        element: (
          <ErrorBoundary useLoaderAsFallback>
            <Article />
          </ErrorBoundary>
        )
      }
    ]
  },
  // / ################## END UNPROTECTED ROUTES ##################
  {
    path: '/dashboard',
    element: (
      <Protected>
        <Dashboard />
      </Protected>
    ),
    children: [
      {
        path: 'home',
        element: (
          <ErrorBoundary customFallBackComponent={<DashboardSkeleton />}>
            <Content>
              <Home />
            </Content>
          </ErrorBoundary>
        )
      },
      {
        path: 'search',
        element: (
          <ErrorBoundary>
            <Content>
              <SearchResultList />
            </Content>
          </ErrorBoundary>
        )
      },
      {
        path: 'notifications',
        element: (
          <ErrorBoundary customFallBackComponent={<NotificationsSkeleton />}>
            <Content>
              <Notifications />
            </Content>
          </ErrorBoundary>
        )
      },
      {
        path: 'user/:id',
        element: (
          <ErrorBoundary customFallBackComponent={<ProfilePageSkeleton />}>
            <Content>
              <OtherUserProfile />
            </Content>
          </ErrorBoundary>
        )
      },
      {
        path: 'post/user/:id',
        element: (
          <ErrorBoundary customFallBackComponent={<AllPostsSkeleton />}>
            <Content>
              <UserPosts />
            </Content>
          </ErrorBoundary>
        )
      },
      {
        path: 'profile',
        element: (
          <ErrorBoundary customFallBackComponent={<ProfilePageSkeleton />}>
            <Content>
              <Profile />
            </Content>
          </ErrorBoundary>
        )
      },
      {
        path: 'settings/profile',
        element: (
          <ErrorBoundary customFallBackComponent={<EditProfileSkeleton />}>
            <Content>
              <Settings>
                <EditProfile />
              </Settings>
            </Content>
          </ErrorBoundary>
        )
      },
      {
        path: 'settings/security',
        element: (
          <ErrorBoundary customFallBackComponent={<ManageSecuritySkeleton />}>
            <Content>
              <Settings>
                <Security />
              </Settings>
            </Content>
          </ErrorBoundary>
        )
      },
      {
        path: 'settings/notifications/:notifType',
        element: (
          <ErrorBoundary customFallBackComponent={<ManageSecuritySkeleton />}>
            <Content>
              <Settings>
                <EditNotifications />
              </Settings>
            </Content>
          </ErrorBoundary>
        )
      },
      {
        path: 'posts/:postId',
        element: (
          <ErrorBoundary customFallBackComponent={<PostDetailSkeleton />}>
            <Content>
              <PostDetail />
            </Content>
          </ErrorBoundary>
        )
      },
      {
        path: 'points/purchase',
        element: (
          <ErrorBoundary customFallBackComponent={<PostDetailSkeleton />}>
            <Content>
              <BuyPoints />
            </Content>
          </ErrorBoundary>
        )
      }
    ]
  },
  {
    path: '*',
    element: <NoMatch />
  }
  //   children: [
  //     {
  //       path: 'profile',
  //       element: (
  //         <ErrorBoundary customFallBackComponent={<ProfilePageSkeleton />}>
  //           <Content>
  //             <Profile />
  //           </Content>
  //         </ErrorBoundary>
  //       )
  //     },
  //     {
  //       path: 'chats',
  //       element: (
  //         <ErrorBoundary>
  //           <Content>
  //             <Chats />
  //           </Content>
  //         </ErrorBoundary>
  //       )
  //     },

  //     {
  //       path: 'chats/thread/:roomId',
  //       element: (
  //         <ErrorBoundary>
  //           <Content>
  //             <Chats />
  //           </Content>
  //         </ErrorBoundary>
  //       )
  //     },

  //     {
  //       path: 'user/:id',
  //       element: (
  //         <ErrorBoundary customFallBackComponent={<ProfilePageSkeleton />}>
  //           <Content>
  //             <MemberProfile />
  //           </Content>
  //         </ErrorBoundary>
  //       )
  //     },
  //     {
  //       path: 'post/user/:id',
  //       element: (
  //         <ErrorBoundary customFallBackComponent={<AllPostsSkeleton />}>
  //           <Content>
  //             <UserPost />
  //           </Content>
  //         </ErrorBoundary>
  //       )
  //     },

  //     {
  //       path: 'menu',
  //       element: (
  //         <ErrorBoundary>
  //           <Content>
  //             <MobileMenu />
  //           </Content>
  //         </ErrorBoundary>
  //       )
  //     },
  //     {
  //       path: 'tribe/:id',
  //       element: (
  //         <ErrorBoundary>
  //           <Content>
  //             <TribePublicProfile />
  //           </Content>
  //         </ErrorBoundary>
  //       )
  //     },
  //     {
  //       path: 'mytribe/:id',
  //       element: (
  //         <ErrorBoundary>
  //           <Content>
  //             <TribeProfile />
  //           </Content>
  //         </ErrorBoundary>
  //       )
  //     },
  //     {

  //   ]
  // },
]

export default MaoneyRoutes

// {
//   path: 'banking',
//   element: <Banking />,
//   children: [
//     {
//       path: '',
//       element: <Navigate to="wallets" replace />
//     },
//     {
//       path: 'wallets',
//       element: <Wallets />,
//       children: [
//         {
//           path: '',
//           element: <WalletsDashboard />
//         },
//         {
//           path: 'rewards/:category',
//           element: <RewardsDetail />
//         },
//         {
//           path: 'add',
//           element: <AddWallets />
//         },
//         {
//           path: 'card_detail/:bankNumber',
//           element: <CardDetail />
//         }
//       ]
//     },
//     {
//       path: 'money_transfers',
//       element: <MoneyTransfers />
//     },
//     {
//       path: 'donations',
//       element: <Donations />,
//       children: [
//         {
//           path: '',
//           element: <DonationLists />
//         },
//         {
//           path: ':donationsId',
//           element: <DonationsInfo />
//         },
//         {
//           path: 'request',
//           element: <DonationsRequest />
//         }
//       ]
//     },
//     {
//       path: 'lend_borrow',
//       element: <LendBorrow />,
//       children: [
//         {
//           path: '',
//           element: <SelectFeatureLendBorrow />
//         },
//         {
//           path: ':lend_borrow_type',
//           element: <LendBorrowSteps />
//         },
//         {
//           path: 'add_category',
//           element: <AddCategory />
//         },
//         {
//           path: 'category_detail:categoryId',
//           element: <CategoryDetail />
//         }
//       ]
//     },
//     {
//       path: 'add_existing_loans',
//       element: <AddExistingLoans />,
//       children: [
//         {
//           path: '',
//           element: <SelectWhoYouAre />
//         },
//         {
//           path: ':selectedWhoAmI',
//           element: <AddExistingLoansSteps />
//         }
//       ]
//     },
//     {
//       path: 'borrow_from_many',
//       element: <BorrowFromMany />
//     },
//     {
//       path: 'loan_notifications',
//       element: <LoanNotifications />,
//       children: [
//         {
//           path: '',
//           element: <LoanNotificationList />
//         },
//         {
//           path: ':loanProposalId',
//           element: <LoanConractDetail />
//         }
//       ]
//     },
//     {
//       path: 'manage_loans',
//       element: <ManageLoans />,
//       children: [
//         {
//           path: '',
//           element: <ManageLoansDashboard />
//         },
//         {
//           path: ':actionType',
//           element: <LoansList />
//         },
//         {
//           path: 'detail/:loanProposalId',
//           element: <LoanDetail />
//         }
//       ]
//     },
//     {
//       path: 'transactions',
//       element: <Transactions />
//     },
//     {
//       path: 'congrats/:service/:content',
//       element: <Congrats />
//     }
//   ]
// },
