/* eslint-disable react/prop-types */
import { PrimaryButton } from '@components/Buttons'
import InputError from '@components/Forms/InputError'
import CustomWidthTooltip from '@components/utils/CustomWidthTooltip'
import { postTypes } from '@constants/posts'
import { containsBadWords } from '@utils/utils'
import { useMemo, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import PostImageSelect from './PostImageSelect'
import { descriptionLimit } from './utils'

const { general, goal, event } = postTypes

const AddProgressToPost = ({
  prevPostData,
  postData,
  setPostData,
  onProceed,
  postType
}) => {
  const [processingImages, setProcessingImages] = useState(false)

  const fields = useMemo(() => {
    if (postType === goal) {
      return ['current_amount', 'description', 'media']
    } else if (postType === general || postType === event) {
      return ['description', 'media']
    }
    return []
  }, [postType])

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue,
    watch
  } = useForm({
    defaultValues: {
      current_amount: postData?.current_amount || '',
      description: postData?.description || '',
      media: postData?.media || []
    }
  })

  const description = watch('description')

  const onSubmit = data => {
    // let media always be an array of files
    setPostData({
      ...prevPostData,
      media: data.media,
      description: data.description,
      current_amount: data.current_amount
    })
    onProceed()
  }

  const validateNotEmpty = value => value.trim().length > 0

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="pt-[56px] flex flex-col max-w-[380px] mx-auto space-y-6"
    >
      {fields.includes('current_amount') && (
        <div className="flex flex-col gap-2 w-full max-w-sm">
          <label
            className="text-[#140C00] flex items-center gap-5"
            htmlFor="current_amount"
          >
            <p>How much so far</p>
            <CustomWidthTooltip
              title="Input how much you have so far either in amount or in percentage"
              arrow
              placement="top"
            >
              <div className="w-4 h-4 bg-[#928B80] rounded-full text-xs text-white font-bold font-sans flex items-center justify-center">
                ?
              </div>
            </CustomWidthTooltip>
          </label>
          <Controller
            control={control}
            name="current_amount"
            rules={{ validate: v => v > 0 }}
            render={({ field: { onChange, value } }) => (
              <CurrencyFormat
                id="current_amount"
                name="current_amount"
                thousandSeparator={true}
                data-testid="add-progress-current-amount"
                prefix={'$'}
                placeholder="$4,000"
                className="rounded-lg w-full border border-[#928b80] p-4 text-[#140C00] placeholder:text-[#928B80] invalid:border-red-600 focus:border-[#140C00] ring-0"
                value={value}
                onValueChange={values => onChange(values.value)}
              />
            )}
          />
          <InputError
            text={'Please enter a valid amount'}
            error={errors.current_amount}
          />
        </div>
      )}
      {fields.includes('description') && (
        <div className="flex flex-col gap-2 w-full max-w-sm">
          <label className="text-[#140C00]" htmlFor="content">
            New Description
          </label>
          <textarea
            data-testid="add-progress-description"
            placeholder="Hello guys, I’ve started saving to buy a car. This is a Dodge Charger, my dream car and I believe I would be able to save and buy the car."
            {...register('description', {
              validate: {
                notEmpty: validateNotEmpty,
                noProfanity: value =>
                  containsBadWords(value) || 'Contains inappropriate language'
              }
            })}
            onChange={e => {
              if (e.target.value.length <= descriptionLimit) {
                setValue('description', e.target.value)
              } else {
                setValue('description', description)
                toast.error(
                  `Content is more than limit: ${descriptionLimit} characters`
                )
              }
            }}
            rows={3}
            className="rounded-lg w-full border border-[#928b80] p-4 text-[#140C00] placeholder:text-[#928B80] invalid:border-red-600 focus:border-[#140C00] ring-0"
          />
          <p className="text-right text-sm text-[#140C00] pr-2">
            {description.length}/{descriptionLimit}
          </p>
          <InputError
            text={
              errors.description?.message || 'Please enter a valid description'
            }
            error={errors.description}
          />
        </div>
      )}
      <div className="mb-4 flex items-center justify-center pt-2 w-full text-left">
        <Controller
          control={control}
          name="media"
          rules={{ validate: v => v && v.length > 0, required: true }}
          render={({ field: { onChange, value } }) => (
            <PostImageSelect
              images={value || []}
              setter={onChange}
              processingImages={processingImages}
              setProcessingImages={setProcessingImages}
            />
          )}
        />
      </div>
      <div className="mx-auto flex items-center justify-center">
        <InputError text={'Please select an image'} error={errors.media} />
      </div>
      <PrimaryButton
        text={'Review'}
        data-testid="add-progress-review-btn"
        type="submit"
        disabled={processingImages}
      />
    </form>
  )
}

export default AddProgressToPost
