import React from 'react'
import { OnboardLogo } from '@components/utils/Logo'
import { SearchBar } from '@components/pages/Dashboard/Search'
import useAppSize from '@hooks/general/useAppSize'
import { useAppDispatch, useAppSelector } from '@hooks/redux'
import UserProfileImage from '@components/utils/UserAvatar'
import { getMemberName, userDisplaysLocation } from '@utils/helpers'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as NewPost } from '@assets/icons/newPost.svg'
import { ReactComponent as CloseIcon } from '@assets/icons/menu-close.svg'
import { updateModalMenu } from '@store/slices/app'
import { ReactComponent as HomeBlack } from '@assets/icons/home_black.svg'
import { getUserLocation } from '@utils/utils'

const MobileMenuHeader = ({ onClose, onPostCreationStart }) => (
  <nav
    className="bg-white  border-gray-200 
          px-2  fixed top-0 z-[99] h-[120px] xs:h-[70px]
       font-lato shadow-md drop-shadow-sm  w-full 
       flex justify-between items-center"
  >
    <div className="flex items-center justify-between w-full mr-10 xs:mr-6 max-w-7xl xl:mx-auto">
      <div
        className="flex align-middle
           justify-center items-center gap-10 mr-5"
      >
        <Link
          onClick={onClose}
          to={'/'}
          className="flex items-center justify-start mx-10 xs:mx-6 xl:ml-0"
        >
          <OnboardLogo width="w-[36px]" height="h-[36px]" vertical={false} />
        </Link>
      </div>

      <div x-data="{ open: false }" className="flex justify-end items-center">
        <NewPost
          className="create-post-btn-mobile"
          onClick={() => {
            onClose()
            onPostCreationStart()
          }}
          style={{ width: 50, marginRight: 1 }}
        />
        <div
          data-testid="mobile-menu-close-btn"
          className={`relative border-b-4 w-[125%] border-transparent }`}
        >
          <div className="flex justify-end items-end  cursor-pointer">
            <div onClick={() => onClose()}>
              <div className="w-8 h-[34px] flex items-center justify-center">
                <CloseIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
)

const MobileMenu = ({
  show,
  onClose,
  onPostCreationStart,
  dashboardData,
  setOpenModal
}) => {
  const { isMobile } = useAppSize()
  const { user } = useAppSelector(state => state.auth)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onItemClick = item => {
    onClose()
    if (item.name === 'Logout') {
      dispatch(updateModalMenu('Logout'))
      setOpenModal(true)
    } else {
      navigate(item.url)
    }
  }

  if (!isMobile || !show) {
    return null
  }

  return (
    <div
      id="mobile-screen-menu"
      data-testid="mobile-screen-menu"
      className="bg-[white] fixed top-0 z-[999] pt-[70px] w-full min-h-screen overflow-y-auto"
    >
      <MobileMenuHeader
        onClose={onClose}
        onPostCreationStart={onPostCreationStart}
      />
      {/* user info */}
      <div className="flex flex-col mx-4 ">
        <div className="mt-5">
          <SearchBar hasBackdrop={false} className="!w-full !max-w-full" />
        </div>

        <div className="flex w-full justify-start items-center gap-3 mb-6 mt-8">
          {!user?.picture ? (
            <UserProfileImage user={user} />
          ) : (
            <img
              src={user?.picture}
              alt="avatar"
              className="w-12 h-12 rounded-full overflow-hidden"
              style={{
                objectFit: 'cover'
              }}
            />
          )}
          <div>
            <div className="text-[#58534D] text-lg">{getMemberName(user)}</div>
            {userDisplaysLocation(user) && (
              <div className="text-[#928B80] text-xs whitespace-nowrap">
                {getUserLocation(user, true)}
              </div>
            )}
          </div>
        </div>

        <div className="flex w-full justify-center items-center mb-8">
          <Link
            onClick={onClose}
            to="/dashboard/profile"
            className="w-full mx-6"
          >
            <button
              data-testid="view-profile-btn"
              className="bg-[#FF9502] h-[40px] rounded-3xl text-white whitespace-nowrap px-[12px] w-full view-profile-btn"
            >
              View Profile
            </button>
          </Link>
        </div>
      </div>
      {/* end user info */}
      <ul className="space-y-3 text-[#140C00]">
        <li className=" px-5" data-testid="home-link">
          <Link
            onClick={onClose}
            to="/dashboard/home"
            className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent py-[6px]"
          >
            <div className="mr-3">
              <HomeBlack className="w-6 h-6" />
            </div>
            Home
          </Link>
        </li>
        <hr className="border-[#928B804D]" />
        <li
          data-testid="create-post-link"
          className=" px-5"
          onClick={() => {
            onClose()
            onPostCreationStart()
          }}
        >
          <div
            href="#"
            className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent py-[6px]"
          >
            <div className="mr-3">
              <NewPost className="w-6 h-6" />
            </div>
            Post
          </div>
        </li>
        <hr className="border-[#928B804D]" />
        {dashboardData.map((item, index) => (
          <div className="" key={index}>
            <li className=" px-4 py-1 mb-2">
              <div
                data-testid={item.testid}
                onClick={() => onItemClick(item)}
                className={`flex items-center transform transition-colors duration-200 border-r-4 border-transparent cursor-pointer text-[#140C00]`}
              >
                <div className="mr-3">
                  <img
                    src={item.icon}
                    className="w-6 h-6 text-[16px] fill-gray-200"
                  />
                </div>
                {item.name}
              </div>
            </li>
            {dashboardData.length === index + 1 ? (
              <></>
            ) : (
              <hr className="border-[#928B804D]" />
            )}
          </div>
        ))}
      </ul>
    </div>
  )
}

export default MobileMenu
