import linkedin from '@assets/icons/linkedin.svg'
import facebook from '@assets/icons/facebook-outlined.svg'
import instagram from '@assets/icons/instagram.svg'
import twitter from '@assets/icons/twitter.svg'
export const socials = [
  {
    name: 'Linkedin',
    url: 'https://www.linkedin.com/company/maoney/',
    icon: linkedin
  },
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/maoneyinc',
    icon: facebook
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/maoney_inc/',
    icon: instagram
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/MaoneyInc',
    icon: twitter
  }
]

export const fakeUser = {
  id: 0,
  username: null,
  email: 'anon@maoney.com',
  first_name: 'User',
  last_name: 'User',
  org_name: null,
  national_id: null,
  passport_number: null,
  address: null,
  city: null,
  state: null,
  zip_code: null,
  country: null,
  phone_number: null,
  date_of_birth: null,
  gender: null,
  occupation: null,
  marital_status: null,
  picture: null,
  bio: null,
  location_status: 0,
  is_verified: false,
  verification_code: null,
  reset_token: null,
  fcm_token: '',
  tour_completed: false,
  created_at: '2024-05-24T07:41:33.662Z',
  updated_at: '2024-05-24T07:41:33.662Z',
  deleted_at: null,
  socialAssets: '0'
}
