import cancel from "@assets/tour/cancel.png";
import previous from "@assets/tour/previous.png";
import next from "@assets/tour/next.png";
import closebt from "@assets/tour/close.png";

const TourController = ({
  setStartTour,
  setCounter,
  getValue,
  setShowTour,
  onGoEnd,
  startTour,
  close,
  tourArray,
  counter,
  isTablet,
  isMobile,
  isDesktop,
  goToProfile,
  goToHome,
}) => {
  const marginTopMobile = [
    "145vh",
    "70vh",
    "70vh",
    "20vh",
    "20vh",
    "60vh",
    "120vh",
    "20vh",
    "20vh",
    "20vh",
  ];
  return (
    <div
      style={{
        marginTop: isTablet
          ? counter == 5
            ? "78%"
            : "200px"
          : isMobile
          ? marginTopMobile[counter]
          : "",
      }}
      className={`p-2 ${!startTour ? "hidden" : ""} fixed ${
        isDesktop ? "mt-[40%]" : ""
      } bg-white rounded-2xl z-[2000] ${
        (counter === 3 || counter === 7 || counter === 8) &&
        (isDesktop || isTablet)
          ? "right-[40px]"
          : "mx-6"
      }`}
    >
      <div
        className={`flex justify-evenly flex-row ${
          isMobile ? "w-[93%] mx-auto" : isTablet ? "w-[440px]" : "w-[520px]"
        } p-2`}
      >
        <div
          className={`w-10 h-10 xs:w-8 xs:h-8 bg-[#FF9502] rounded-full text-2xl xs:text-base text-medium text-white flex justify-center items-center shrink-0`}
        >
          {getValue(counter, "index")}
        </div>
        <div className="w-full text-center text-2xl xs:text-[16px] font-medium">
          {getValue(counter, "title")}
        </div>
        <div
          className="w-10 h-10 xs:w-8 xs:h-8 flex items-center justify-center rounded-full bg-[#928B8033] shrink-0"
          onClick={() => {
            setStartTour(false);
            setShowTour(true);
            setCounter(0);
          }}
        >
          <img src={close} className="w-4 h-4 cursor-pointer" />
        </div>
      </div>

      <div
        className={`${
          isMobile ? "w-[93%]" : "w-[350px]"
        } mt-6 text-center text-lg xs:text-sm text-[#58534D] mx-auto`}
      >
        {getValue(counter, "body")}
      </div>
      <div className="w-[auto] text-center mt-10 justify-center flex gap-2">
        {tourArray.map((data, i) => (
          <div
            key={i}
            style={{
              backgroundColor: i === counter ? "#FF9502" : "#928B80",
              borderRadius: 10,
              height: 8,
              //left: "50%",
              opacity: 1,
              //position: "absolute",
              top: 0,
              // transform: `translateX(${translateX}px) scale(${scale})`,
              transformOrigin: "left center",
              transition: "all 400ms ease-out",
              width: 8,
            }}
          />
        ))}
      </div>

      <div
        className={`w-full grid grid-cols-2 xs:px-4 px-10 mt-6 gap-8 ${
          !isDesktop ? "mt-[-22px]" : "mt-0"
        } mb-4`}
      >
        <button
          onClick={() => {
            if (counter > 0) {
              setCounter(counter - 1);
              if (counter === 7) goToHome();
            } else {
              setStartTour(false);
              setShowTour(true);
              setCounter(0);
            }
          }}
          className={` text-[#FF9502] font-bold xs:text-[14px] flex xs:justify-start justify-center ${
            !isDesktop ? "border-0" : "border"
          } border-[#FF9502] items-center  hover:text-white ${
            !isDesktop
              ? ""
              : "hover:bg-[#FF9502]/[0.5] bg-[#fff] outline outline-white"
          }   rounded-lg h-9 w-full gap-3`}
        >
          {counter > 0 ? (
            !isDesktop ? (
              <img src={previous} />
            ) : (
              "Back"
            )
          ) : !isDesktop ? (
            <img src={cancel} />
          ) : (
            "Cancel"
          )}
        </button>
        {/* <div></div> */}
        <button
          onClick={() => {
            if (Object.keys(tourArray).length <= counter + 1) {
              onGoEnd();
            } else {
              setCounter(counter + 1);
              if (counter === 6) goToProfile();
            }
          }}
          className={` text-[#fff] font-bold xs:text-[14px] flex xs:justify-end justify-center ${
            !isDesktop ? "border-0" : "border"
          } border-[#fff] items-center  ${
            !isDesktop ? "" : "bg-[#FF9502]"
          } rounded-lg h-9 w-full  gap-3`}
        >
          {!isDesktop ? <img src={next} width="32" /> : "Next"}
        </button>
      </div>
    </div>
  );
};

export default TourController;
