/* eslint-disable react/prop-types */
import React from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import toast from 'react-hot-toast'

const GOOGLE_MAPS_API_KEY = `AIzaSyBdoPLDevyLLNFCZxbc5FRevBKAlzxMmjI`
const GOOGLE_KEY = 'AIzaSyBX2nIf0JcfYqlq3ZZ-C3sDG5g9zZ-p0Lk'

const AdvancedGeoLocationInput = ({
  value,
  setValue,
  containerClassName,
  controlClassName,
  selectId
}) => {
  console.log()
  return (
    <GooglePlacesAutocomplete
      debounce={700}
      minLengthAutocomplete={4}
      apiKey={GOOGLE_KEY}
      onLoadFailed={error =>
        toast.error(
          'Could not inject Google script. Please reload the page',
          error
        )
      }
      options={{
        types: ['(regions)']
        // componentRestrictions: { country: 'ru' }
      }}
      selectProps={{
        id: selectId,
        isClearable: true,
        placeholder: 'Type in an address',
        value,
        onChange: setValue,
        menuPlacement: 'top',
        components: {
          IndicatorSeparator: null
        },
        classNames: {
          option: () => `advanced-geolocation-option`,
          container: () => containerClassName,
          control: () => controlClassName
        }
      }}
    />
  )
}

export default AdvancedGeoLocationInput
