import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { SearchDrawerItem } from './SearchDrawerItem'

const MAX_RESULTS = 4

const SearchDrawer = ({ searchResults, open, setOpen, searchText = '' }) => {
  const ref = useRef()
  const navigate = useNavigate()
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        event.target.id !== 'global-search'
      ) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, setOpen])

  if (!open) {
    return null
  }

  return (
    <div
      ref={ref}
      className="w-full flex flex-col bg-white h-[302px] shadow-[0px_2px_16px_0px_rgba(20,12,0,0.16)] rounded-2xl py-4 px-6"
    >
      {searchResults.length > 0 && (
        <div className="text-_140C00 text-base leading-6 mb-6">Related</div>
      )}
      {searchResults?.length > 0 ? (
        <div className="space-y-6">
          {searchResults?.slice(0, MAX_RESULTS).map((result, index) => (
            <SearchDrawerItem
              key={index}
              id={result.id}
              result={result}
              onClose={() => setOpen(false)}
              searchText={searchText}
            />
          ))}
        </div>
      ) : (
        <div className="flex gap-2 items-center">
          <p className="text-center text-_140C00">no user record</p>
        </div>
      )}
      {searchResults.length > MAX_RESULTS && (
        <>
          <div className="mt-6" />
          <div className="mt-auto pt-3 border-t border-t-_928B8066/[.4] flex items-center">
            <button
              onClick={() => {
                setOpen(false)
                navigate('/dashboard/search')
              }}
              className="text-primary text-sm leading-[16.8px] cursor-pointer text-center mx-auto"
            >
              View more results
            </button>
          </div>
        </>
      )}
    </div>
  )
}

SearchDrawer.propTypes = {
  searchResults: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  ),
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  searchText: PropTypes.string
}

export default SearchDrawer
