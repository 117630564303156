/* eslint-disable react/prop-types */
import Loader from '@components/Loaders/Loader'
import { Suspense } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { useNavigate } from 'react-router-dom'
import ErrorFallback from './ErrorFallback'

const LoaderFallback = () => (
  <div className="h-screen flex bg-white items-center justify-center">
    <Loader />
  </div>
)

const isDev = import.meta.env.VITE_ENV === 'development'

const ErrorBoundaryWrapper = ({ children }) => {
  const navigate = useNavigate()

  if (isDev) {
    return <div>{children}</div>
  }
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => navigate('/')}
    >
      {children}
    </ReactErrorBoundary>
  )
}

const ErrorBoundary = ({
  children,
  useLoaderAsFallback = false,
  customFallBackComponent
}) => (
  <ErrorBoundaryWrapper>
    <Suspense
      fallback={
        useLoaderAsFallback ? (
          <LoaderFallback />
        ) : (
          customFallBackComponent ?? <div />
        )
      }
    >
      {children}
    </Suspense>
  </ErrorBoundaryWrapper>
)

export default ErrorBoundary
