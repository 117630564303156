import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const LoadableImage = ({
  src,
  className,
  alt,
  style,
  onClick,
  stall,
  imgClassName,
  element,
  loadOnStart = true,
  ...rest
}) => {
  const [imageState, setImageState] = useState({
    loading: loadOnStart,
    error: false
  })

  const handleLoad = () => {
    setImageState({ loading: false, error: false })
  }

  const handleError = () => {
    setImageState({ loading: false, error: true })
  }

  const shouldNotDisplay = stall && imageState.loading && !imageState.error

  return (
    <div
      onClick={onClick}
      className={clsx('relative overflow-hidden', className, {
        'overflow-visible': element
      })}
      style={style}
    >
      {(imageState.loading || imageState.error) && (
        <Skeleton className={'absolute top-0 left-0 w-full h-full'} />
      )}
      {!shouldNotDisplay && (
        <img
          src={src}
          {...rest}
          className={clsx(
            'absolute top-0 left-0 w-full h-full object-cover opacity-0',
            {
              '!opacity-100': !imageState.loading && !imageState.error,
              '!h-0': imageState.loading || imageState.error
            },
            imgClassName
          )}
          alt={alt}
          onLoad={handleLoad}
          onError={handleError}
        />
      )}
      {element && element}
    </div>
  )
}

LoadableImage.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string.isRequired,
  style: PropTypes.object,
  onClick: PropTypes.any,
  stall: PropTypes.bool,
  imgClassName: PropTypes.string,
  loadOnStart: PropTypes.bool,
  element: PropTypes.any
}

export default LoadableImage
