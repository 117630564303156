/* eslint-disable react/prop-types */
import { RenderStyle } from '@constants/renderStyle'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import UserProfileImage from './UserAvatar'
import { formattedTime, getUserLocation } from '../../utils/utils'
import LoadableImage from './LoadableImage'
import Loader from '../Loaders/Loader'
import clsx from 'clsx'
import { getMemberName, goToUserProfile, isOrganization } from '@utils/helpers'
import { ReactComponent as OrganizationIcon } from '@assets/icons/organization-icon.svg'

function UserBrand({
  user,
  className,
  titleColor = '#140C00',
  subtitleColor = '#58534D',
  size = 64,
  isSize = false,
  showName = true,
  showLocation = false,
  width,
  height,
  renderStyle = RenderStyle.NAME_ONLY,
  isCornerComponentLoading = null,
  date = '',
  border = false,
  navigatesToProfile = true,
  cornerComponent = null // New prop for the component to be placed in the top right corner,
}) {
  const { user: currentUser } = useSelector(state => state.auth)

  const navigate = useNavigate()

  const name = getMemberName(user)
  const location = getUserLocation(user)
  const image = user.picture
  const loc = location && location !== 'undefined' ? location.split('-') : ''

  const handleClick = () => {
    if (navigatesToProfile) {
      goToUserProfile({ navigate, user, currentUser })
    }
  }

  const userIsOrganization = isOrganization(user)

  return (
    <>
      <div
        onClick={handleClick}
        style={{
          cursor: 'pointer'
        }}
        className={clsx('w-full relative flex-shrink-0', className)}
      >
        {' '}
        {/* Add 'relative' class here */}
        <div
          className={`flex justify-start items-center w-full  my-${
            isSize ? 0 : 4
          } font-lato gap-2 `}
        >
          <div className="relative">
            {!image ? (
              <UserProfileImage
                width={width}
                height={height}
                user={user}
                border={border}
                element={
                  userIsOrganization ? (
                    <OrganizationIcon className="absolute bottom-0 right-0" />
                  ) : null
                }
              />
            ) : image ? (
              <div>
                <LoadableImage
                  style={{
                    width: size ? `${size}px` : '70px',
                    height: size ? `${size}px` : '70px',
                    objectFit: 'cover'
                  }}
                  element={
                    userIsOrganization ? (
                      <OrganizationIcon className="absolute bottom-0 right-0" />
                    ) : null
                  }
                  imgClassName={'rounded-full'}
                  className="xs:w-20 xs:h-20 mx-auto rounded-full relative"
                  src={image}
                  alt={name}
                />
              </div>
            ) : (
              <div className="ml-0 rounded-sm overflow-hidden"></div>
            )}

            {cornerComponent && (
              <div
                className="absolute top-0 right-0" // Adjust the positioning here as per your requirement
                style={{
                  zIndex: 10
                }}
              >
                {isCornerComponentLoading ? (
                  <div className="absolute top-0 right-[-75px]">
                    <Loader type="regular" />
                  </div>
                ) : (
                  cornerComponent
                )}
              </div>
            )}
          </div>

          <div className="flex flex-col justify-start items-start">
            {showName && renderStyle === RenderStyle.NAME_ONLY && (
              <div className="">
                <p className={`text-[${titleColor}]`}>{name}</p>
              </div>
            )}
            {showName && renderStyle === RenderStyle.NAME_AND_ADDRESS && (
              <div className="flex">
                <p className={`text-[${titleColor}]`}>{name} </p>
                <p className={`text-[${subtitleColor}]`}>
                  &nbsp;.&nbsp;{user?.city}
                </p>
              </div>
            )}

            <div className="w-full">
              <p className={`text-[${subtitleColor}] text-sm xs:text-xs`}>
                {showLocation && location && (
                  <>
                    {typeof loc[0] !== 'undefined' && loc[0] != null && loc[0]}
                    {typeof loc[1] !== 'undefined' && (
                      <span className="font-bold"> - {loc[1]}</span>
                    )}{' '}
                  </>
                )}
                {date && (
                  <>
                    {/* <span>- </span> */}
                    <span className="text-black">{formattedTime(date)}</span>
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserBrand
