import { logUserOut } from '@store/slices/auth'
import React from 'react'
import { useDispatch } from 'react-redux'

function Logout({ closeModal }) {
  const dispatch = useDispatch()
  const handleLogout = () => {
    dispatch(logUserOut())
  }

  return (
    <div className="flex w-full flex-col" data-testid="logout-modal-content">
      <div className="flex w-full justify-center items-center">
        <div className="flex w-full flex-col">
          <div className="font-lato flex flex-col w-full justify-center items-center align-middle pt-[24px] xs:pt-[16px] text-center">
            <p className="text-[#58534D] text-[18px] leading-[24px] w-[50%] xs:w-full">
              Are you sure you want to logout?
            </p>
          </div>
          <div className="flex flex-col justify-center items-center w-full gap-0 pt-[64px] xs:pt-[40px]">
            <div className="flex flex-row w-full">
              <div className="m-4 flex flex-col gap-2 w-1/2 font-lato">
                <button
                  data-testid="logout-modal-content-cancel"
                  className="bg-white h-[54px] text-[18px] text-[#cc0000] rounded-xl border-solid border-2 border-[#cc0000]"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
              <div className="m-4 flex flex-col gap-2 w-1/2  font-lato">
                <button
                  data-testid="logout-modal-content-confirm"
                  className="bg-[#FF9502] h-[54px] text-[18px] text-white rounded-xl"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Logout
