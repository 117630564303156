import { PrimaryButton } from '@components/Buttons'
import LoadableImage from '@components/utils/LoadableImage'
import { getPostDetailLink } from '@utils/helpers'
import { transformPostMedia } from '@utils/post-utils'
import React from 'react'

const MessagePreview = ({ data, post, onProceed }) => {
  const postMedia = post ? transformPostMedia(post.media) : []
  const img = post ? postMedia?.[0]?.[0] : null
  const postLink = post ? getPostDetailLink(post?.id) : null
  return (
    <div className="pt-10 md-full:pt-[90px] w-full flex flex-col">
      <div className="space-y-2">
        <LoadableImage
          src={img}
          className={'h-[95px] rounded-[6px]'}
          loadOnStart={false}
        />
        <div className="max-h-[200px] pb-10 overflow-y-auto no-scrollbar">
          <p className="text-_928B80 text-base leading-6 font-lato">
            {data?.message}
          </p>
          <a
            className="text-[#4181E1] block"
            style={{ wordWrap: 'break-word' }}
            href={postLink}
            target="_blank"
            rel="noreferrer"
          >
            {postLink}
          </a>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 px-5 md-full:px-[99px] w-full">
        <PrimaryButton text={'Next'} onClick={onProceed} />
      </div>
    </div>
  )
}

export default MessagePreview
