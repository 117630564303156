/* eslint-disable react-hooks/exhaustive-deps */
import apiConfig from '@config/apiConfig'
import { MEASUREMENT_ID } from '@constants/events'
import usePosts from '@hooks/posts/usePosts'
import routes from '@routes/AppRouter'
import { useLazyGetUserProfileQuery } from '@services/profile-service'
import { logUserOut } from '@store/slices/auth'
import { clearNotifs, updateNotifs } from '@store/slices/notifications'
import { generateRandomId } from '@utils/helpers'
import moment from 'moment'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import toast, { Toaster } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { Context as ResponsiveContext } from 'react-responsive'
import { useRoutes } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { io } from 'socket.io-client'
import './App.css'
import { setupNotifications } from './firebase'
import useVisibilityChange from './hooks/general/useVisibilityChange'
import {
  sendNativeNotification,
  toastNotification
} from './notificationHelpers'

if (!window.Cypress) {
  ReactGA.initialize(
    [
      {
        trackingId: MEASUREMENT_ID,
        gaOptions: {
          name: 'MaoneyTracker'
        }
      }
    ],
    { debug: true }
  )
}

function App() {
  const [loading, setLoading] = useState(true)
  const [currentTimer, setCurrentTimer] = useState('')
  const timer = useSelector(state => state.timer)
  const notifs = useSelector(state => state.notifications)
  const notifsLog = useSelector(store => store.notif)
  const mutes = useSelector(state => state.mutes)
  const { jwt, user } = useSelector(state => state.auth)
  const { posts } = useSelector(state => state.posts)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [getProfile, { isSuccess: profileFetched, error }] =
    useLazyGetUserProfileQuery()
  const { insertPost, upsertPosts, editPostsBasedOnAction } = usePosts()

  const clear = () => {
    dispatch(logUserOut())
  }
  const checkForTokenValidity = async () => {
    if (jwt) {
      if (!user?.id) {
        clear()
        setTimeout(() => {
          setLoading(false)
        }, 100)
        return
      }
      try {
        await getProfile(user.id).unwrap()
        setLoading(false)
      } catch (e) {
        console.log(e)
      }
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!jwt) {
      console.log('no jwt clearing...')
      localStorage.clear()
    }
  }, [jwt])

  useEffect(() => {
    if (error && loading) {
      if (error?.status === 401) {
        toast.error('Session expired.', { duration: 3000 })
        clear()
        setTimeout(() => {
          setLoading(false)
        }, 100)
      } else {
        setLoading(false)
      }
    }
  }, [error])

  useEffect(() => {
    if (profileFetched && loading) {
      setLoading(false)
    }
  }, [profileFetched])

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = moment().format('hh:mm:ss A') // Get the current time
      setCurrentTimer(currentTime)
    }, 60000)

    return () => {
      clearInterval(intervalId)
    }
  }, [timer])

  useEffect(() => {
    try {
      if (timer && currentTimer && jwt?.length) {
        const momentTime1 = moment(timer, 'hh:mm:ss A')
        const momentTime2 = moment(currentTimer, 'hh:mm:ss A')
        const timeDifference = moment.duration(momentTime2.diff(momentTime1))
        if (timeDifference.asMinutes() >= 480) {
          dispatch(logUserOut())
          dispatch(clearNotifs())
          navigate(0)
        } else {
          /* empty */
        }
      }
    } catch (e) {
      console.log(e)
    }
  }, [timer, currentTimer, jwt])

  const isForeground = useVisibilityChange()
  useEffect(() => {
    checkForTokenValidity()
    setupNotifications(message => {
      console.log(message)
      if (isForeground) {
        // App is in the foreground, show toast notification
        toastNotification({
          title: 'title',
          description: 'body',
          status: 'info'
        })
      } else {
        // App is in the background, show native notification
        sendNativeNotification({
          title: 'title',
          body: 'body'
        })
      }
    })
  }, [])

  const WS_URL = apiConfig.SocketUrl

  const updateFeed = (action, postId, userId) => {
    editPostsBasedOnAction(action, postId, userId)
  }

  const addNotification = parsedMessage => {
    if (mutes?.includes(parsedMessage?.topic)) {
      return // Exit the function if the topic is muted
    } else {
      parsedMessage.id = generateRandomId(10)
      const notifsCopy = [...notifs]
      notifsCopy.push(parsedMessage)
      // dispatch(updateNotifs(parsedMessage))
      if (
        notifsLog?.post?.inApp &&
        (parsedMessage?.topic === 'created_post' ||
          parsedMessage?.topic === 'edited_post' ||
          parsedMessage?.topic === 'added_progress_post' ||
          parsedMessage?.topic === 'shared_post' ||
          parsedMessage?.topic === 'event_register' ||
          parsedMessage?.topic === 'follow_post')
      ) {
        dispatch(updateNotifs(parsedMessage))
      }
      if (
        notifsLog?.commentsAndLikes?.inApp &&
        (parsedMessage?.topic === 'post_comment_added' ||
          parsedMessage?.topic === 'replied_post_comment' ||
          parsedMessage?.topic === 'post_comment_edited' ||
          parsedMessage?.topic === 'liked_comment' ||
          parsedMessage?.topic === 'unliked_comment' ||
          parsedMessage?.topic === 'liked_post' ||
          parsedMessage?.topic === 'unlike_post')
      ) {
        dispatch(updateNotifs(parsedMessage))
      }
    }
  }

  useEffect(() => {
    if (window.Cypress) {
      return
    }
    const socket = io(WS_URL)

    const handleSocketConnect = () => {
      console.log('Connected to WebSocket server')
    }

    const handleSocketNotification = message => {
      const parsedMessage = JSON.parse(message.message)
      addNotification(parsedMessage)
      const currentDate = new Date()
      const createdAt = currentDate.toISOString()
      if (parsedMessage?.topic === 'created_post') {
        const newPost = parsedMessage?.data?.newPost
        console.log({ newPost })
        insertPost({
          ...newPost,
          reactions: 0,
          sharesCount: 0,
          likesCount: 0,
          liked: false,
          numberOfComments: 0,
          comments: [],
          likes: [],
          shares: [],
          created_at: createdAt,
          updated_at: createdAt
        })
      }
      if (parsedMessage?.topic === 'event_register') {
        const newFeeds = [...posts].map(feed => {
          if (feed.id === parsedMessage?.data?.updatedPost?.id) {
            return {
              ...feed,
              participants: parsedMessage?.data?.updatedPost?.participants
            }
          }
          return feed
        })
        upsertPosts(newFeeds)
      }
      if (parsedMessage?.topic === 'liked_post') {
        // updateFeed(
        //   'like',
        //   parsedMessage?.data?.newLike?.post_id,
        //   parsedMessage?.data?.newLike?.user_id
        // )
      }
      if (parsedMessage?.topic === 'unlike_post') {
        // updateFeed(
        //   'unlike',
        //   parsedMessage?.data?.newLike?.post_id,
        //   parsedMessage?.data?.newLike?.user_id
        // )
      }
    }

    const handleSocketError = error => {
      console.error('WebSocket error:', error)
    }

    const handleSocketDisconnect = () => {
      console.log('WebSocket connection closed')
    }

    // Event listeners
    socket.on('connect', handleSocketConnect)
    // socket.on('notification', handleSocketNotification)
    socket.onAny((eventName, message) => {
      if (eventName === 'WEB_SOCKET') {
        handleSocketNotification(message)
        console.log(`Received ${eventName} event:`, message)
      } else {
        console.log(`Received ${eventName} event:`, message)
      }
    })
    socket.on('error', handleSocketError)
    socket.on('disconnect', handleSocketDisconnect)

    // Cleanup function
    return () => {
      socket.off('connect', handleSocketConnect)
      socket.off('WEB_SOCKET', handleSocketNotification)
      socket.off('error', handleSocketError)
      socket.off('disconnect', handleSocketDisconnect)
      socket.close()
    }
  }, [])

  const element = useRoutes(routes)

  return (
    <ResponsiveContext.Provider value="">
      {loading ? <div /> : element}
      <Toaster
        position="top-right"
        toastOptions={{
          className: '',
          duration: 5000
        }}
      />
    </ResponsiveContext.Provider>
  )
}

export default App
