import { PrimaryButton } from '@components/Buttons'
import PlainLogo from '@components/shared/PlainLogo'
import React from 'react'
import gmail from '@assets/icons/gmail-colored.svg'
import whatsapp from '@assets/icons/whatsapp-colored.svg'
import smsLarge from '@assets/icons/sms-large.svg'
import { Link } from 'react-router-dom'
import routes from '@routes/routesList'

const shareOptions = [
  {
    icon: gmail,
    label: 'Email'
  },
  {
    icon: whatsapp,
    label: 'WhatsApp'
  },
  {
    icon: smsLarge,
    label: 'SMS'
  }
]

const PaymentInfoStep = ({ onProceed, onClose }) => (
  <div className="pt-5 md-full:pt-10 w-full flex flex-col">
    <div className="max-h-[300px] overflow-y-auto no-scrollbar md-full:max-h-auto">
      <div className="border border-primary border-dashed p-4 flex flex-col items-center rounded-2xl justify-center bg-primary/[.03]">
        <div className="space-y-3 flex flex-col items-center justify-center">
          <div className="flex items-center space-x-[6px]">
            <PlainLogo />
            <h3 className="font-lato text-[32px] leading-[38.4px] font-bold">
              200 Points
            </h3>
          </div>
          <p className="text-_928B80 font-lato text-sm leading-[16.8px]">
            Points Balance: <span className="text-_140C00">400 Points</span>
          </p>
        </div>
        <div className="flex flex-col items-center justify-center md-full:space-x-12 mt-8 md-full:flex-row space-y-3 md-full:space-y-0 md-full:mt-4">
          {shareOptions.map(shareOption => (
            <div
              className="flex flex-col space-y-2 h-[73px] justify-between items-center"
              key={`share-option-${shareOption.label}-subscribers`}
            >
              <img
                alt={`Share via ${shareOption.label}`}
                src={shareOption.icon}
              />
              <span className="font-lato text-sm leading-16.8 text-center text-_140C00">
                {shareOption.label}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="py-4 pb-[29px] mb-10 md-full:mb-0 flex items-center justify-center space-x-[2px]">
        <Link
          onClick={onClose}
          to={routes.purchasePoints}
          className="font-lato font-bold text-sm leading-[19.2px] underline text-primary"
        >
          Get more points
        </Link>
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.75 11.5635L10.5631 1.74977M10.5631 1.74977C9.99216 2.76855 9.52466 6.33814 11.25 8.06348M10.5631 1.74977C9.49718 2.27358 5.3622 2.67568 3.75 1.06348"
            stroke="#FF9502"
            strokeWidth="1.5"
          />
        </svg>
      </div>
    </div>
    <div className="absolute bottom-0 left-0 px-5 md-full:px-[99px] w-full">
      <PrimaryButton text={'Share'} onClick={onProceed} />
    </div>
  </div>
)

export default PaymentInfoStep
