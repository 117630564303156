import arrow_left from '@assets/icons/arrow_left.svg'
import close from '@assets/icons/close.svg'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddGoal from './AddGoal'
import AddProgressToPost from './AddProgressToPost'
import Congratulations from './Congratulations'
import PostDetailsInputForm from './PostDetailsInputForm'
import PostEventForm from './PostEventForm'
import PostPreview from './PostPreview'
import PostTypeConfirmation from './PostTypeConfirmation'
import {
  extractDateAndTimeFromISOString,
  getPostModalTextMappings,
  getSteps,
  postCreationSteps
} from './utils'
import { updateWalkthoughMenu } from '@store/slices/app'
import usePosts from '@hooks/posts/usePosts'
import { editPostTypes, postTypes } from '@constants/posts'
import { transformPostMedia } from '@utils/post-utils'
import useStepForm from '@hooks/general/useStepForm'

const {
  postDetailsInputStep,
  congratulationsStep,
  postPreviewStep,
  postTypeConfirmationStep,
  editEventDetailsStep,
  editGoalDetailsStep,
  addProgressForGeneralPostStep,
  addProgressForEventPostStep,
  addProgressForGoalPostStep
} = postCreationSteps

const defaultValues = {
  purpose: '',
  description: '',
  file: null,
  media: null,
  required_amount: '',
  deadline: '',
  current_amount: '',
  hasDonation: false,
  bank_id: '',
  type: postTypes.general
}

const CreatePost = ({ onClose, open }) => {
  const [postData, setPostData] = useState({ ...defaultValues })
  const { postToUpdate, clearPostToEdit } = usePosts()
  const { walkthroughMenu: menu } = useSelector(state => state.app)
  const dispatch = useDispatch()
  const isProgressEdit =
    postToUpdate && postToUpdate.updateType === editPostTypes.addProgress
  const isNormalEdit =
    postToUpdate &&
    (postToUpdate.updateType === editPostTypes.default ||
      postToUpdate.updateType === editPostTypes.goal)

  const isProgressEditForGoalPost =
    isProgressEdit && postToUpdate?.data?.type === postTypes.goal

  const isProgressEditForGeneralPost =
    isProgressEdit && postToUpdate?.data?.type === postTypes.general

  const isProgressEditForEventPost =
    isProgressEdit && postToUpdate?.data?.type === postTypes.event

  const isNormalEditForGeneralPost =
    isNormalEdit && postToUpdate?.data?.type === postTypes.general
  const isNormalEditForGoalPost =
    isNormalEdit && postToUpdate?.data?.type === postTypes.goal
  const isNormalEditForEvent =
    isNormalEdit && postToUpdate?.data?.type === postTypes.event

  const isEditingAProgress = !!postToUpdate?.index

  const allSteps = useMemo(
    () =>
      getSteps({
        isProgressEditForEventPost,
        isProgressEditForGoalPost,
        isProgressEditForGeneralPost,
        isNormalEditForGeneralPost,
        isNormalEditForGoalPost,
        isNormalEditForEvent,
        isEditingAProgress,
        postToUpdate
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postToUpdate]
  )

  const { currentStepIs, setStep, step, onProceed, onBackClick } = useStepForm({
    steps: allSteps,
    onClose
  })

  useEffect(() => {
    if (open) {
      const firstStep = getSteps({
        isProgressEditForGoalPost,
        isProgressEditForGeneralPost,
        isNormalEditForGeneralPost,
        isNormalEditForGoalPost,
        isNormalEditForEvent,
        isProgressEditForEventPost
      })[0]
      setStep(firstStep)
      if (
        (firstStep === postDetailsInputStep ||
          firstStep === editGoalDetailsStep) &&
        [
          isNormalEditForGeneralPost,
          isNormalEditForEvent,
          isNormalEditForGoalPost
        ].includes(true)
      ) {
        const prevMedia = (transformPostMedia(postToUpdate?.data?.media) || [])[
          postToUpdate?.index
        ]
        const description =
          postToUpdate?.data?.description?.[postToUpdate?.index] || ''
        const tempPostData = {
          ...(postToUpdate?.data || {}),
          media: prevMedia,
          description
        }
        if (isNormalEditForEvent) {
          try {
            const { date, time } = extractDateAndTimeFromISOString(
              postToUpdate?.data?.event_date_time
            )
            tempPostData.date = date
            tempPostData.time = time
            tempPostData.address = postToUpdate?.data?.event_address
          } catch (e) {
            console.log(e)
          }
        }
        setPostData(tempPostData)
      }
    } else {
      clearPostToEdit()
    }
  }, [open])

  const { title, subtitle } = useMemo(() => {
    const texts = getPostModalTextMappings(
      postToUpdate?.data,
      menu,
      isEditingAProgress
    )
    return texts?.[step] || { title: '', subtitle: '' }
  }, [step, postToUpdate, menu, isEditingAProgress])

  const handleClose = () => {
    onClose()
    clearPostToEdit()
    dispatch(updateWalkthoughMenu(null))
  }

  const isShowSubTitle = useMemo(() => step !== postPreviewStep, [step])

  const props = {
    postData,
    setPostData,
    setStep,
    isProgressEdit,
    onProceed,
    reset: () => setStep(allSteps[0]),
    clearFormData: () => {
      setPostData(defaultValues)
    },
    dataToEdit: postToUpdate?.data,
    dataToEditIndex: postToUpdate?.index,
    isEditingAProgress
  }

  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <div
        data-testid="post-creation-modal-card"
        className={clsx(
          'w-[90vw] relative md-full:w-[600px] shadow-lg transition-all duration-300 rounded-2xl bg-white font-lato mx-auto xs:mx-6 px-6 pt-8 md-full:py-8 flex flex-col',
          {
            'max-h-[70vh] min-h-[40vh] overflow-y-auto md-full:min-h-[640px] opacity-0':
              !step,
            'xs:h-full xs:max-h-[75vh]': step !== congratulationsStep
          }
        )}
      >
        {currentStepIs(congratulationsStep) ? (
          <Congratulations
            onClose={onClose}
            setStep={setStep}
            {...props}
            isProgressEdit={isProgressEdit}
            isNormalEdit={isNormalEdit}
          />
        ) : (
          <>
            <div className="flex justify-between text-center items-start">
              {menu === 'Create Post' ? (
                <div className="w-10 h-10 bg-[#FF9502] flex-shrink-0 rounded-full flex items-center justify-center text-white font-lato text-center text-2xl float-left">
                  2
                </div>
              ) : (
                <div onClick={onBackClick}>
                  <img src={arrow_left} className="cursor-pointer" />
                </div>
              )}
              <p
                data-testid="post-creation-modal-card-title"
                className="font-lato font-bold max-w-[387px] px-2 text-center !text-[18px] md-full:!text-[24px] text-[#140C00]"
              >
                {title}
              </p>
              <div
                className="flex flex-shrink-0 items-center justify-center bg-[#928B8033] rounded-full w-10 h-10 cursor-pointer"
                onClick={handleClose}
              >
                <img src={close} className="w-4 h-4" />
              </div>
            </div>
            {isShowSubTitle && (
              <div
                data-testid="post-creation-modal-card-subtitle"
                className="text-[#58534D] text-center xs:text-sm mt-4 mx-auto max-w-[347px]"
              >
                {subtitle}
              </div>
            )}
            <div className="w-full overflow-y-auto">
              {currentStepIs(editEventDetailsStep) && (
                <PostEventForm
                  postData={postData}
                  onSubmit={data => {
                    setPostData(prev => ({ ...prev, ...data }))
                    onProceed()
                  }}
                />
              )}
              {currentStepIs(addProgressForGoalPostStep) ||
              currentStepIs(addProgressForEventPostStep) ||
              currentStepIs(addProgressForGeneralPostStep) ? (
                <AddProgressToPost
                  postType={postToUpdate?.data?.type}
                  postData={postData}
                  setPostData={setPostData}
                  onProceed={onProceed}
                  prevPostData={postToUpdate?.data}
                />
              ) : (
                <div />
              )}
              {currentStepIs(postDetailsInputStep) && (
                <PostDetailsInputForm {...props} />
              )}
              {currentStepIs(editGoalDetailsStep) && <AddGoal {...props} />}
              {currentStepIs(postPreviewStep) && <PostPreview {...props} />}
              {currentStepIs(postTypeConfirmationStep) && (
                <PostTypeConfirmation {...props} />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

CreatePost.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool
}

export default CreatePost
