import sidebar from '@assets/tour/sidebar-min.png'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useLocation, useNavigate } from 'react-router-dom'

import close from '@assets/icons/close.svg'
import cuate from '@assets/tour/cuate.svg'
import maoney_points_png from '@assets/tour/maoney_points-min.png'
import timeIcon from '@assets/tour/time.svg'

import dropDownMobile from '@assets/tour/dropDown-min-2.png'
import dropDown from '@assets/tour/dropDown-min.png'
import networkManagementMobile from '@assets/tour/mynetwork-management-mobile.png'
import networkMobile from '@assets/tour/mynetwork-mobile.png'
import navbar from '@assets/tour/navbar-min.png'
import navbar_mobile from '@assets/tour/navbar_mobile-min.png'
import postcardMobile from '@assets/tour/post-card-mobile.png'
import post_card from '@assets/tour/post_card-min.png'
import profile_mobile from '@assets/tour/profile-mobile.png'
import profile from '@assets/tour/profile.png'
import sideBarMobile from '@assets/tour/sidebar-mobile.png'
import social_assets from '@assets/tour/social-assets.png'
import social_points_png from '@assets/tour/social_points-min.png'
import top_10_social from '@assets/tour/top_10_social-min.png'

import Modal from '@components/Modals/Modal'
import TourButton from './TourButton'
import TourController from './TourController'
import TourStartModal from './TourStartModal'
import { startTour as setTourOpen } from '@store/slices/app'

const Tour = () => {
  const [startTour, setStartTour] = useState(false)
  const [showTourButton, setShowTourButton] = useState(true)
  const [isTourModalOpen, setIsTourModalOpen] = useState(false)
  const [counter, setCounter] = useState(0)
  const [isEnd, setIsEnd] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const isTablet = useMediaQuery({
    query: '(min-width: 800px) and (max-width: 1200px)'
  })
  const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' })

  useEffect(() => {
    if (startTour) {
      //document.body.style.overflow = "hidden";
    } else {
      //document.body.style.overflow = "scrolls";
    }
  }, [startTour])

  const commonTour = [
    {
      index: 0,
      title: 'Mini Profile Section',
      body: 'Here you have your Name, Picture, Location points, social assets and share links.'
    },
    {
      index: 1,
      title: 'Maoney Points',
      body: 'These are the points you get for every person you bring into Maoney. Good news they are worth real cash!'
    },
    {
      index: 2,
      title: 'Social Assets',
      body: "These are the number of people you've brought into Maoney and who are also in your network!"
    },
    {
      index: 3,
      title: 'Feeds',
      body: "Here's where you can find all the posts made by you, your network, and even Maoney Admin."
    },
    {
      index: 4,
      title: 'Top 10 Social Wealthiest',
      body: 'On this section we have the people who has the count for most social assets on Maoney'
    },
    {
      index: 5,
      title: 'Nav Bar',
      body: "At the Nav Bar we've got some super useful navigation functions like Search, Invite, Post, and Profile Menu"
    },
    {
      index: 6,
      title: 'Profile Menu/dropdown',
      body: 'On the menu, we’ve got some cool things going on. You can view your profile, edit it, and even access other awesome features!'
    },
    {
      index: 7,
      title: 'Profile Page',
      body: 'Here’s your profile page showing your personal details and Social Assets'
    }
  ]
  const tourArrayDesktop = [
    ...commonTour,
    {
      index: 8,
      title: 'Social Assets Management',
      body: 'Here you can categorize, mute, disconnect, accept and also view pending connections you have. Isn’t that cool?'
    }
  ]
  const tourArrayMobile = [
    ...commonTour,
    {
      index: 8,
      title: 'My Networks Section',
      body: 'On this section we see our Networks (people we are connected with) here and the different category you choose to classify them'
    },
    {
      index: 9,
      title: 'Network Management',
      body: 'Here you can categorize, mute, disconnect, accept and also view pending connections you have. Isn’t that cool?'
    }
  ]

  const getValue = (index, value) => {
    const tourArray = isMobile ? tourArrayMobile : tourArrayDesktop
    if (value == 'index') {
      return parseInt(Object.values(tourArray)[index][value]) + 1
    } else {
      return Object.values(tourArray)[index][value]
    }
  }

  const handleTourStart = () => {
    setStartTour(true)
    setIsTourModalOpen(false)
    dispatch(setTourOpen(true))
  }

  const handleHideTourModal = () => {
    setIsTourModalOpen(false)
    setShowTourButton(true)
    dispatch(setTourOpen(false))
  }

  const handleGoAgain = () => {
    setIsEnd(false)
    setIsTourModalOpen(false)
    setCounter(0)
    setStartTour(true)
    dispatch(setTourOpen(true))
    navigate('/dashboard/home')
  }

  const handleGoEnd = () => {
    setStartTour(false)
    setIsTourModalOpen(true)
    setCounter(0)
    setIsEnd(true)
    dispatch(setTourOpen(false))
  }

  const handleDone = () => {
    handleHideTourModal()
    setIsEnd(false)
    setCounter(0)
    dispatch(setTourOpen(false))
  }
  const goToProfile = () => {
    navigate('/dashboard/profile')
  }
  const goToHome = () => {
    navigate('/dashboard/home')
  }

  return (
    <Fragment>
      <Modal
        open={isTourModalOpen}
        setOpen={handleHideTourModal}
        size={!isDesktop ? 'w-1/2' : 'w-1/3'}
        content={
          <TourStartModal
            isEnd={isEnd}
            cuate={cuate}
            setIsOpen={handleHideTourModal}
            enableTour={handleTourStart}
            onGoAgain={handleGoAgain}
            onDone={handleDone}
          />
        }
      />
      <div
        className={`z-[1000] w-[100%] border-0 border-[#ccc] h-[120%] fixed top-0 ${
          !startTour && 'hidden'
        } left-0 bg-[#61605ec7]`}
      >
        <div className="w-full justify-center items-center h-[100px]">
          {counter == 5 && (
            <div>
              <img
                src={isMobile ? navbar_mobile : navbar}
                className="w-[100%]  mt-[0%] h-[auto] ml-auto mr-auto"
              />
            </div>
          )}
        </div>

        <div className="w-full h-auto grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 ">
          <div
            className={`text-left ${!isDesktop && 'pt-5 '} pl-5 ${
              isMobile ? 'w-full pl-0' : 'w-[85%]'
            }`}
          >
            {counter == 0 && (
              <img
                src={isMobile ? sideBarMobile : sidebar}
                className={`${
                  isMobile
                    ? 'max-w-[380px] w-[90vw]'
                    : isDesktop
                      ? 'w-[85%]'
                      : 'w-full'
                } ${isMobile ? 'mt-[-40px]' : 'mt-[15%]'} ml-auto mr-auto`}
              />
            )}

            {counter == 1 && (
              <div>
                <img
                  src={maoney_points_png}
                  className={`${
                    isMobile
                      ? 'max-w-[380px] w-[90vw]'
                      : isDesktop
                        ? 'w-[92%]'
                        : 'w-full'
                  } mt-[18%] ml-auto mr-auto`}
                />
              </div>
            )}

            {counter == 2 && (
              <div>
                <img
                  src={social_points_png}
                  className={`${
                    isMobile
                      ? 'max-w-[380px] w-[90vw]'
                      : isDesktop
                        ? 'w-[92%]'
                        : '100%'
                  } mt-[18%] ml-auto mr-auto`}
                />
              </div>
            )}
          </div>
          <div
            className={`text-center ${
              !isDesktop && !counter == 6 && 'pt-10 '
            }  ${isMobile ? 'ml-0' : 'ml-[-80px]'} ${
              counter === 6 && isTablet && 'col-span-2'
            }`}
          >
            {counter == 6 && !isDesktop && (
              <div className="ml-[0px]">
                <img
                  src={isMobile ? dropDownMobile : dropDown}
                  className={`w-[280px] ${
                    isMobile ? 'mt-[-80px]' : 'mt-[-20px]'
                  } mr-2 float-right`}
                />
              </div>
            )}

            {counter == 3 && (
              <div>
                <img
                  src={isMobile ? postcardMobile : post_card}
                  className={`${
                    isMobile ? 'max-w-[380px] w-[90vw]' : 'w-full'
                  } ${
                    isMobile ? 'mt-[30%]' : isTablet ? 'mt-[19%]' : 'mt-[5%]'
                  } ml-auto mr-auto`}
                />
              </div>
            )}
          </div>
          <div className={`text-right ${isMobile ? 'w-full' : 'w-[90%]'} `}>
            {counter == 4 && (
              <div>
                <img
                  src={top_10_social}
                  className={`${
                    isMobile ? 'max-w-[380px] w-[90vw]' : 'w-[90%]'
                  } ${
                    isMobile ? 'mt-[20%]' : isTablet ? 'mt-[17%]' : 'mt-[5%]'
                  } ml-auto mr-auto`}
                />
              </div>
            )}

            {counter == 6 && isDesktop && (
              <div className="ml-[30px]">
                <img
                  src={dropDown}
                  className="w-[70%] mt-[-3%] ml-auto mr-auto"
                />
              </div>
            )}
          </div>
        </div>
        <div className="w-full h-auto grid grid-cols-7">
          <div className="col-span-4 xs:col-span-7">
            {counter == 7 && (
              <div>
                <img
                  src={isMobile ? profile_mobile : profile}
                  className={`${
                    isMobile
                      ? 'max-w-[380px] w-[90vw]'
                      : isDesktop
                        ? 'w-[92%]'
                        : 'w-full'
                  } ${isMobile ? 'mt-[100px]' : 'mt-[10%]'} mx-auto`}
                />
              </div>
            )}
            {counter == 8 && (
              <div>
                <img
                  src={isMobile ? networkMobile : social_assets}
                  className={`${
                    isMobile
                      ? 'max-w-[380px] w-[90vw]'
                      : isDesktop
                        ? 'w-[92%]'
                        : 'w-full'
                  } ${isMobile ? 'mt-[100px]' : 'mt-[6%]'} mx-auto`}
                />
              </div>
            )}
            {counter == 9 && (
              <div>
                <img
                  src={networkManagementMobile}
                  className={`${
                    isMobile
                      ? 'max-w-[380px] w-[90vw]'
                      : isDesktop
                        ? 'w-[92%]'
                        : 'w-full'
                  } ${isMobile ? 'mt-[100px]' : 'mt-[6%]'} mx-auto`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <TourController
        isTablet={isTablet}
        isMobile={isMobile}
        isDesktop={isDesktop}
        setStartTour={setStartTour}
        setShowTour={setShowTourButton}
        onGoEnd={handleGoEnd}
        setCounter={setCounter}
        getValue={getValue}
        close={close}
        tourArray={isMobile ? tourArrayMobile : tourArrayDesktop}
        counter={counter}
        startTour={startTour}
        goToProfile={goToProfile}
        goToHome={goToHome}
      />

      {location.pathname === '/dashboard/home' && (
        <TourButton
          showTour={showTourButton}
          setShowTour={setShowTourButton}
          timeIcon={timeIcon}
          // setStartTour={setStartTour}
          setStartTour={setIsTourModalOpen}
        />
      )}
    </Fragment>
  )
}

export default Tour
