import ReactGA from 'react-ga4'
import { handleEncrypt } from './encrypt'
import { maoneyAccountTypes } from '@constants/auth'

const { VITE_AUTH_TOKEN } = import.meta.env

export const setToken = token => {
  if (token) {
    localStorage.setItem(VITE_AUTH_TOKEN, token)
  }
}

export const removeToken = () => {
  localStorage.clear()
}

export const convertImageToBase64 = file =>
  new Promise((resolve, reject) => {
    if (file instanceof Blob || file instanceof File) {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result.split(',')[1]) // Extracting base64 data
      }
      reader.onerror = error => reject(error)
      reader.readAsDataURL(file)
    } else {
      reject(new Error('Invalid file type. Expected Blob or File.'))
    }
  })

export function getProfileImageUrl(payload) {
  if (!payload) {
    return null
  }
  return payload
}
// const imageUrl = payload.picture;

// if (imageUrl.startsWith("https://") || imageUrl.startsWith("http://")) {
//   // If the URL already starts with "https://" or "http://", return it as is
//   return imageUrl;
// } else {
//   // If the URL does not start with "https://" or "http://", construct a full URL
//   const constructedUrl =
//     `https://test-user-service.maoney.com${imageUrl}`.replace(
//       /(https?:\/\/[^\/]+)\/+/i,
//       "$1/"
//     );

// Add the statement to return the constructed URL with apiConfig.UserImageUrl
// export function formatLocation({ city, country, zip_code, state, address }) {
//   if (!city) {
//     return (city = "");
//   }
//   if (!country) {
//     return (country = "");
//   }
//   if (!zip_code) {
//     return (zip_code = "");
//   }
//   if (!state) {
//     return (state = "");
//   }
//   if (!address) {
//     return (address = "");
//   }
// }

export const formatLocation = payload => (!payload ? '' : payload)

export const doNothing = () => {}

export const isLastArrayItem = (arrayLength, index) => index === arrayLength - 1

export const trackEvent = ({ category, action, label }) => {
  if (window.Cypress) {
    return
  }
  ReactGA.event({
    category,
    action,
    label
  })
}

export const truncateText = (text, maxChars) => {
  if (!text) {
    return ''
  }
  return text.length > maxChars ? `${text.slice(0, maxChars)}...` : text
}

/** Helper function to get the name of a maoney user (individual or organization) */
export const getMemberName = member => {
  if (!member) {
    return ''
  }
  if (!member?.account_type) {
    if (member.org_name && member?.org_name !== 'null') {
      return member.org_name
    } else if (member.first_name) {
      return [member.first_name, member.last_name].join(' ')
    }
  }
  if (member.account_type == maoneyAccountTypes.individual) {
    return `${member.first_name} ${member.last_name}`
  } else {
    return member?.org_name
  }
}

export const getMemberInitials = member => {
  const name = getMemberName(member)
  if (!name) {
    return ''
  }
  const initials = name.split(' ').map(item => item.charAt(0).toUpperCase())
  return initials.slice(0, 2).join('')
}

export const getLoggedInPersonName = getMemberName

export const getPostDetailLink = postId => {
  const encryptedId = handleEncrypt(postId)
  return window.location.origin + `/dashboard/posts/${encryptedId}`
}

export function pluralizeWord(number, word, plural) {
  if (number === undefined || number === null) {
    return word
  }
  if (number > 1 || number === 0) {
    return plural || `${word}s`
  }
  return word
}

export function removeBeforeSecondHttps(url) {
  const index = url.indexOf('https://', url.indexOf('https://') + 1) // Find the index of the second occurrence of 'https://'
  if (index !== -1) {
    return url.substring(index) // Return the substring starting from the second 'https://'
  }
  return url // Return the original URL if 'https://' is not found twice
}

export const getOtherUserIdFromConnection = (connection, currentUser) => {
  if (connection.receiver_id === currentUser.id) {
    return connection.sender_id
  }
  if (connection.sender_id === currentUser.id) {
    return connection.receiver_id
  }
}

/** Extract the error from a server response */
export const extractServerError = error =>
  error?.data?.message || error?.data?.error || 'Something went wrong'

/** Generate a random id */
export function generateRandomId(length) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const sortByUpdatedAt = array =>
  array.sort((a, b) => {
    const dateA = new Date(a.updated_at)
    const dateB = new Date(b.updated_at)
    return dateB - dateA
  })

export const sortByCreatedAt = array =>
  array.sort((a, b) => {
    const dateA = new Date(a.created_at).getTime()
    const dateB = new Date(b.created_at).getTime()
    return dateB - dateA
  })

export function formatPostDate(inputDateString) {
  const inputDate = new Date(inputDateString)

  if (isNaN(inputDate.getTime())) {
    return 'Invalid Date'
  }

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  const formattedDate = inputDate.toLocaleDateString('en-US', options)

  const day = inputDate.getDate()
  const daySuffix = day => {
    if (day >= 11 && day <= 13) {
      return 'th'
    }
    switch (day % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  return formattedDate.replace(
    /\d{1,2}/,
    day => `${day}${daySuffix(parseInt(day))}`
  )
}

function handleZeroAndNegative(inputValue) {
  return Math.max(0, inputValue)
}

function calculatePercentageAndStatus(requiredAmount, currentAmount) {
  if (requiredAmount === 0) {
    throw new Error(
      'Required amount cannot be zero. Please provide a non-zero value.'
    )
  }
  const percentage = (currentAmount / requiredAmount) * 100
  const formattedPercentage = parseFloat(percentage.toFixed(2))
  const statusString = `$${currentAmount?.toLocaleString()} so far`
  // const statusString = `$${formatNumber(currentAmount)} so far`
  return {
    percentage: formattedPercentage,
    status: statusString
  }
}

export const handlePercentage = (requiredAmount, currentAmount) => {
  try {
    if (requiredAmount === 0 && currentAmount === 0) {
      return {
        percentage: 0,
        status: 'No progress'
      }
    }

    const result = calculatePercentageAndStatus(requiredAmount, currentAmount)
    return result
  } catch (error) {
    console.error(error.message)
    return {
      percentage: 0,
      status: '0'
    }
  }
}

export const getPostProgressPercentage = post =>
  handleZeroAndNegative(
    handlePercentage(post?.required_amount, post?.current_amount)?.percentage
  )

export const deepClone = obj => {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  const clone = Array.isArray(obj) ? [] : {}

  for (const key in obj) {
    clone[key] = deepClone(obj[key])
  }

  return clone
}

export const goToUserProfile = ({ navigate, user, currentUser }) => {
  if (!user.id) {
    return
  }
  if (user?.id !== currentUser?.id) {
    navigate(`/dashboard/user/${user?.id}`)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  } else {
    navigate(`/dashboard/profile`)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
}

export const userDisplaysLocation = user => user.location_status === 0

export const getUserFirstName = user => {
  if (user?.org_name) {
    return user.org_name
  }
  return user?.first_name
}

export function arraysAreEqual(arr1, arr2) {
  // Check if arrays have the same length
  if (arr1.length !== arr2.length) {
    return false
  }

  // Iterate over each element and compare
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }

  // If all elements are the same, return true
  return true
}

export const isOrganization = user => {
  const hasNoFirstName = !user.first_name || user.first_name === 'null'
  const hasNoLastName = !user.last_name || user.last_name === 'null'
  const hasOrgName = user.org_name
  if (user.account_type === maoneyAccountTypes.organization) {
    return true
  }
  if (hasNoFirstName && hasNoLastName && hasOrgName) {
    return true
  }
  return false
}
