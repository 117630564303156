import apiConfig from '@config/apiConfig'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { POST, customBaseQuery } from './util'

export const connectionsService = createApi({
  reducerPath: 'connectionsService',
  baseQuery: customBaseQuery,
  endpoints: builder => ({
    getUserConnections: builder.query({
      query: query => {
        const { userId, offset = 0, limit = 1000 } = query || {}
        return {
          url: `${apiConfig.User.GetSocialAssets}/${userId}/${offset}/${limit}`
        }
      }
    }),
    requestForConnection: builder.mutation({
      query: body => ({
        url: apiConfig.User.RequestForConnection,
        method: POST,
        body
      })
    }),
    disconnectAsset: builder.mutation({
      query: body => ({
        url: apiConfig.Networks.DisconnectAsset,
        method: POST,
        body
      })
    }),
    updateCategory: builder.mutation({
      query: body => ({
        url: `${apiConfig.User.CategorizeAssets}`,
        method: POST,
        body
      })
    }),
    updateConnectStatus: builder.mutation({
      query: body => ({
        url: `${apiConfig.User.UpdateAssets}`,
        method: POST,
        body
      })
    }),
    getUserNetworks: builder.query({
      query: myId => ({
        url: `${apiConfig.Networks.UserNetworks}?loggedInUser=${myId}`
      })
    }),
    muteAsset: builder.mutation({
      query: body => ({
        url: `${apiConfig.Networks.MuteAsset}`,
        method: POST,
        body
      })
    }),
    unmuteAsset: builder.mutation({
      query: body => ({
        url: `${apiConfig.Networks.UnmuteAsset}`,
        method: POST,
        body
      })
    }),
    rejectUserConnectionRequest: builder.mutation({
      query: requestId => ({
        url: `${apiConfig.Networks.UserNetworkRequests}/${requestId}`,
        method: 'DELETE'
      })
    }),
    getNotification: builder.query({
      query: id => ({
        url: `${apiConfig?.User?.GetNotifications}/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
    }),
    getBlog: builder.query({
      query: () => ({
        url: 'https://api.hubapi.com/cms/v3/blogs/posts',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${import.meta.env.VITE_APP_HUBSPOTAPI}`
        }
      })
    }),
    getNotificationSettings: builder.query({
      query: id => ({
        url: `${apiConfig?.User?.GetNotificationSettings}/${id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      })
    }),
    getConnectionCategories: builder.query({
      keepUnusedDataFor: 3600,
      query: () => apiConfig.Networks.GetConnectionCategories
    })
  })
})

export const {
  useGetBlogQuery,
  useGetUserConnectionsQuery,
  useLazyGetUserConnectionsQuery,
  useLazyGetNotificationSettingsQuery,
  useRequestForConnectionMutation,
  useDisconnectAssetMutation,
  useUpdateCategoryMutation,
  useUpdateConnectStatusMutation,
  useGetUserNetworksQuery,
  useMuteAssetMutation,
  useUnmuteAssetMutation,
  useRejectUserConnectionRequestMutation,
  useGetConnectionCategoriesQuery
} = connectionsService
