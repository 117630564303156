import { shareSocials } from '@constants/utils'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '@hooks/redux'
import { getMemberName, getPostDetailLink } from '@utils/helpers'
import Modal from '../Modals/Modal'
import { clearPostToShare, setPosts } from '@store/slices/posts'
import { useSharePostMutation } from '@services/posts-service'

const SharePost = () => {
  const { user } = useAppSelector(state => state.auth)
  const { shareModalState, posts } = useAppSelector(state => state.posts)
  const { post, visible } = shareModalState
  const [sharePost, { isSuccess: shareSuccess, reset }] = useSharePostMutation()
  const feedsListing = [...posts]
  const dispatch = useDispatch()

  const updateFeed = () => {
    const action = 'share'
    // Find the index of the feed object in the feeds array
    const feedIndex = feedsListing?.findIndex(feed => feed?.id === post?.id)

    if (feedIndex !== -1) {
      // Clone the feedsListing array
      const updatedFeeds = [...feedsListing]

      // Get a copy of the feed object
      const updatedFeed = { ...updatedFeeds[feedIndex] }

      // Ensure that the 'likes' array exists and is initialized
      if (!updatedFeed.likes) {
        updatedFeed.likes = []
      }

      if (action === 'share') {
        // Add a new like object to the likes array
        updatedFeed.shares = [
          ...updatedFeed.shares,
          { post_id: post?.id, user_id: user.id }
        ]
      }

      // Update the feedsListing array with the updated feed object
      updatedFeeds[feedIndex] = updatedFeed

      // Update the state with the updated feeds array
      dispatch(setPosts(updatedFeeds))
    }
  }

  const onSuccess = () => {
    // check if it's a single post page and perform some action
    updateFeed()
  }

  useEffect(() => {
    if (shareSuccess) {
      if (onSuccess) {
        onSuccess()
      } else {
        updateFeed()
      }
      reset()
    }
  }, [shareSuccess])

  const handleShare = () => {
    try {
      const data = {
        post_id: post?.id,
        user_id: user?.id
      }
      sharePost(data)
        .then(res => {
          console.log(res)
        })
        .catch(err => {
          console.log(err)
        })
    } catch (error) {
      console.log('Error', error)
    }
  }

  const link = post ? getPostDetailLink(post.id) : ''
  const message = `${getMemberName(user)} is inviting you to view a post, Click on the link to view \n\n`

  return (
    <Modal
      size="max-w-lg"
      open={visible}
      onClose={() => dispatch(clearPostToShare())}
      setOpen={() => dispatch(clearPostToShare())}
      content={
        <div className="flex justify-between items-center align-middle w-full my-10">
          <div className="grid grid-cols-4 gap-14 w-full ">
            {shareSocials?.map((item, index) => (
              <a
                key={index}
                target={'_blank'}
                href={item.url(link, message)}
                rel="noreferrer"
                onClick={() => handleShare()}
              >
                <div
                  className="w-[48px] h-[37px] flex flex-col text-center items-center mb-6 gap-2"
                  key={index}
                >
                  <img src={item.icon} alt={item.name} />
                  <p className="font-lato text-[#140C00] text-[16px]">
                    {item.name}
                  </p>
                </div>
              </a>
            ))}
          </div>
        </div>
      }
    />
  )
}

export default SharePost
