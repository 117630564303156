/* import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp";
 */ import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

const SuccessAlertCard = ({
  action,
  msg,
  buttonLabel,
  isHideButton = false
}) => (
  <div
    onClick={() => {
      action()
    }}
    className="bg-white-900 text-center py-4 mt-4 mb-4 "
  >
    <div
      className="p-2 cursor-pointer items-center text-indigo-100 leading-none rounded-lg flex lg:inline-flex"
      style={{ backgroundColor: '#f2ffed', border: '1px solid #0eb334' }}
      role="alert"
    >
      <CheckCircleOutlineIcon style={{ color: '#0eb334', marginRight: 10 }} />
      <span
        className="font-semibold mr-2 text-left flex-auto"
        style={{ color: '#58534D', fontSize: 13 }}
      >
        {msg}
      </span>
      {!isHideButton && (
        <span
          className="flex rounded uppercase px-2 py-1 text-xs font-bold mr-3"
          style={{ backgroundColor: '#1a852e', color: '#fff' }}
        >
          {buttonLabel}
        </span>
      )}
    </div>
  </div>
)

export default SuccessAlertCard
