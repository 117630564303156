import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import Modal from '../Modal'
import Logout from './Logout'
import EditProfile from './EditProfile'
// import AddMemers from './AddMembers'
// import AddProducts from './AddProducts'
// import ReviewProduct from './AddProducts/ReviewProduct'
// import TribesStart from './AddTribes'
// import DescribeTribe from './AddTribes/DescribeTribe'
// import OTPVerification from './CommonModals/OTPVerification'
// import ValidateSocial from './CommonModals/ValidateSocial'
// import EditProfileSuccess from './EditProfileSuccess'
// import ManageSecurity from './ManageSecurity'
// import ManageVisibility from './ManageVisibility'
// import PasswordCongratulations from './PasswordCongratulations'
// import ReviewModal from './ReviewModal'
// import TribePermissions from './TribePermissions'
// import UpdateEmail from './UpdateEmail'
// import UpdatePassword from './UpdatePassword'
// import UpdatePhoneNumber from './UpdatePhoneNumber'
// import { updateModalMenu } from '@store/slices/app'

function MenuModalHOC({ open, setOpen }) {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' })
  const { modalMenu: menu } = useSelector(state => state.app)
  switch (menu) {
    case 'Edit Profile':
      return (
        <Modal
          size={isMobile ? 'w-[380px] h-[708px]' : 'w-[748px] h-[668px]'}
          content={<EditProfile />}
          open={open}
          setOpen={setOpen}
          title={isMobile ? 'Update Profile' : 'Update Your Profile'}
        />
      )
    // case 'Create New Tribes':
    //   return (
    //     <Modal
    //       size={isMobile ? 'w-[380px] h-[708px]' : 'w-[641px] h-[728px]'}
    //       content={<TribesStart />}
    //       open={open}
    //       setOpen={setOpen}
    //       title={isMobile ? 'Create Tribes' : 'Create New Tribes'}
    //     />
    //   )
    // case 'Describe Your Tribe':
    //   return (
    //     <Modal
    //       size={isMobile ? 'w-[380px] h-[628px]' : 'w-[641px] h-[638px]'}
    //       content={<DescribeTribe />}
    //       open={open}
    //       setOpen={setOpen}
    //       showBackButton={true}
    //       BackButtonClick={() => dispatch(updateModalMenu('Create New Tribes'))}
    //       title={isMobile ? 'Describe Tribes' : 'Describe Your Tribe'}
    //     />
    //   )
    // case 'Time to Add Members':
    //   return (
    //     <Modal
    //       size={isMobile ? 'w-[380px] h-[680px]' : 'w-[641px] h-[678px]'}
    //       content={<AddMemers />}
    //       open={open}
    //       setOpen={setOpen}
    //       showBackButton={true}
    //       BackButtonClick={() =>
    //         dispatch(updateModalMenu('Describe Your Tribe'))
    //       }
    //       title={isMobile ? 'Add Members' : 'Time to Add Members'}
    //     />
    //   )
    // case 'Permissions':
    //   return (
    //     <Modal
    //       size={isMobile ? 'w-[600px] h-[588px]' : 'w-[641px] h-[608px]'}
    //       content={<TribePermissions />}
    //       open={open}
    //       setOpen={setOpen}
    //       showBackButton={true}
    //       BackButtonClick={() =>
    //         dispatch(updateModalMenu('Time to Add Members'))
    //       }
    //       title={isMobile ? 'Permissions' : 'Permissions'}
    //     />
    //   )
    // case 'Okay, let’s review':
    //   return (
    //     <Modal
    //       size={isMobile ? 'w-[380px] h-[608px]' : 'w-[641px] h-[611px]'}
    //       content={<ReviewModal />}
    //       open={open}
    //       setOpen={setOpen}
    //       showBackButton={true}
    //       BackButtonClick={() => dispatch(updateModalMenu('Permissions'))}
    //       title={isMobile ? 'Review' : 'Okay, let’s review'}
    //     />
    //   )
    // case 'Manage Visibility':
    //   return (
    //     <Modal
    //       size={isMobile ? 'w-[380px] h-[588px]' : 'w-[748px] h-[588px]'}
    //       content={<ManageVisibility />}
    //       open={open}
    //       setOpen={setOpen}
    //       title={'Manage Visibility'}
    //     />
    //   )
    // case 'Manage Security':
    //   return (
    //     <Modal
    //       size={isMobile ? 'w-[380px] h-[500px]' : 'w-[584px] h-[508px]'}
    //       content={<ManageSecurity />}
    //       open={open}
    //       setOpen={setOpen}
    //       title={'Manage Security'}
    //     />
    //   )
    // case 'Update Password':
    //   return (
    //     <Modal
    //       size={isMobile ? 'w-[380px] h-[708px]' : 'w-[584px] h-[784px]'}
    //       content={<UpdatePassword />}
    //       open={open}
    //       setOpen={setOpen}
    //       title={'Update Password'}
    //     />
    //   )
    // case 'Update Email':
    //   return (
    //     <Modal
    //       size={isMobile ? 'w-[380px] h-[556px]' : 'w-[584px] h-[618px]'}
    //       content={<UpdateEmail closeModal={() => setOpen(false)} />}
    //       open={open}
    //       setOpen={setOpen}
    //       title={'Update Email'}
    //     />
    //   )
    // case 'Update Phone Number':
    //   return (
    //     <Modal
    //       size={isMobile ? 'w-[380px] h-[556px]' : 'w-[584px] h-[618px]'}
    //       content={<UpdatePhoneNumber closeModal={() => setOpen(false)} />}
    //       open={open}
    //       setOpen={setOpen}
    //       title={'Update Phone Number'}
    //     />
    //   )
    // case 'Validate Social':
    //   return (
    //     <Modal
    //       size={isMobile ? 'w-[380px] h-[550px]' : 'w-[584px] h-[618px]'}
    //       content={<ValidateSocial />}
    //       open={open}
    //       setOpen={setOpen}
    //       title={'Validate Social Account'}
    //     />
    //   )

    // //Password Congratulations-Update-Phone
    // case 'Password Congratulations-Update-Phone':
    //   return (
    //     <Modal
    //       size={isMobile ? 'w-[380px] h-[337px]' : 'w-[476px] h-[375px]'}
    //       content={
    //         <PasswordCongratulations
    //           state="changePassword"
    //           closeModal={() => setOpen(false)}
    //         />
    //       }
    //       open={open}
    //       setOpen={setOpen}
    //       title={''}
    //     />
    //   )

    // case 'Password Congratulations':
    //   return (
    //     <Modal
    //       size={isMobile ? 'w-[380px] h-[337px]' : 'w-[476px] h-[375px]'}
    //       content={
    //         <PasswordCongratulations closeModal={() => setOpen(false)} />
    //       }
    //       open={open}
    //       setOpen={setOpen}
    //       title={''}
    //     />
    //   )
    // case 'Edit Profile Success':
    //   return (
    //     <Modal
    //       size={isMobile ? 'w-[380px] h-[337px]' : 'w-[476px] h-[375px]'}
    //       content={<EditProfileSuccess closeModal={() => setOpen(false)} />}
    //       open={open}
    //       setOpen={setOpen}
    //       title={''}
    //     />
    //   )

    // //
    // case "Let's Get You Verified-Not-LoggedIn":
    //   return (
    //     <Modal
    //       size={isMobile ? 'w-[380px] h-[533px]' : 'w-[584px] h-[618px]'}
    //       content={
    //         <OTPVerification
    //           state={'notLogIn'}
    //           closeModal={() => setOpen(false)}
    //         />
    //       }
    //       open={open}
    //       setOpen={setOpen}
    //       title={"Let's Get You Verified"}
    //     />
    //   )

    // case "Let's Get You Verified":
    //   return (
    //     <Modal
    //       size={isMobile ? 'w-[380px] h-[533px]' : 'w-[584px] h-[618px]'}
    //       content={<OTPVerification closeModal={() => setOpen(false)} />}
    //       open={open}
    //       setOpen={setOpen}
    //       title={"Let's Get You Verified"}
    //     />
    //   )
    // case 'Add Products':
    //   return (
    //     <Modal
    //       size={isMobile ? 'w-[380px] h-[633px]' : 'w-[748px] h-[633px]'}
    //       content={<AddProducts />}
    //       open={open}
    //       setOpen={setOpen}
    //       title={'Products and Services'}
    //     />
    //   )
    // case 'Review Product':
    //   return (
    //     <Modal
    //       size={isMobile ? 'w-[380px] h-[633px]' : 'w-[748px] h-[633px]'}
    //       content={<ReviewProduct />}
    //       open={open}
    //       setOpen={setOpen}
    //       title={"Okay Let's Review"}
    //     />
    //   )
    case 'Logout':
      return (
        <Modal
          size={isMobile ? 'w-[380px] h-[267px]' : 'w-[476px] h-[331px]'}
          content={<Logout closeModal={() => setOpen(false)} />}
          open={open}
          setOpen={setOpen}
          title={'Logout'}
        />
      )
    default:
      break
  }
}

export default MenuModalHOC
