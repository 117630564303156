import apiConfig from '@config/apiConfig'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { DELETE, POST, PUT, customBaseQuery } from './util'

export const postsService = createApi({
  reducerPath: 'postsService',
  tagTypes: ['postList', 'commentList', 'userPostList'],
  baseQuery: customBaseQuery,
  endpoints: builder => ({
    /** Endpoint to create a post */
    createPost: builder.mutation({
      query: data => ({
        url: apiConfig.User.CreatePost,
        method: POST,
        body: data
      })
    }),
    getPosts: builder.query({
      providesTags: ['postList'],
      query: payload => {
        const {
          offset = 0,
          limit = 80,
          commentPage = 0,
          commentPageSize = 100
        } = payload || {}
        return {
          url: `${apiConfig.User.GetPost}/${offset}/${limit}`,
          params: { commentPage, commentPageSize }
        }
      }
    }),
    getUserPosts: builder.query({
      providesTags: ['userPostList'],
      query: ({ userId, lowLimit, upperLimit }) => ({
        url: apiConfig.User.GetUserPosts(userId, lowLimit, upperLimit)
      })
    }),
    deletePost: builder.mutation({
      invalidatesTags: ['userPostList'],
      query: postId => ({
        url: `${apiConfig.User.DeletePost}/${postId}`,
        method: DELETE
      })
    }),
    /** Add progress to post */
    addProgressToPost: builder.mutation({
      invalidatesTags: ['userPostList'],
      query: ({ postId, requiredObject }) => ({
        url: `${apiConfig.User.ProgressPost}/${postId}`,
        method: PUT,
        body: requiredObject
      })
    }),
    editPost: builder.mutation({
      invalidatesTags: ['userPostList'],
      query: ({ postId, requiredObject }) => ({
        url: `${apiConfig.User.UpdatePost}/${postId}`,
        method: PUT,
        body: requiredObject
      })
    }),
    editProgress: builder.mutation({
      invalidatesTags: ['userPostList'],
      query: ({ postId, requiredObject }) => ({
        url: apiConfig.User.UpdateProgress(postId),
        method: PUT,
        body: requiredObject
      })
    }),
    getPostById: builder.query({
      query: postId => ({
        url: `${apiConfig.User.GetPostById}/${postId}`
      })
    }),
    likePost: builder.mutation({
      invalidatesTags: ['userPostList'],
      query: data => ({
        url: apiConfig.User.LikePost,
        method: POST,
        body: data
      })
    }),
    unLikePost: builder.mutation({
      invalidatesTags: ['userPostList'],
      query: data => ({
        url: apiConfig.User.UnLikePost,
        method: POST,
        body: data
      })
    }),
    likeComment: builder.mutation({
      invalidatesTags: ['userPostList'],
      query: ({ userId, commentId }) => ({
        url: `${apiConfig.User.LikeComment}/${commentId}`,
        method: POST,
        body: { user_id: userId }
      })
    }),
    unLikeComment: builder.mutation({
      invalidatesTags: ['userPostList'],
      query: ({ userId, commentId }) => ({
        url: `${apiConfig.User.UnLikeComment}/${commentId}`,
        method: POST,
        body: { user_id: userId }
      })
    }),
    sharePost: builder.mutation({
      invalidatesTags: ['userPostList'],
      query: body => ({
        url: apiConfig.User.SharePost,
        method: POST,
        body
      })
    }),
    addComment: builder.mutation({
      query: data => ({
        url: apiConfig.User.PostComments,
        method: POST,
        body: data
      }),
      invalidatesTags: ['commentList', 'userPostList']
    }),
    getComments: builder.query({
      providesTags: ['commentList'],
      query: postId => ({
        url: `${apiConfig.User.GetComments}/${postId}`
      })
    }),
    editComment: builder.mutation({
      query: ({ commentId, body }) => ({
        url: `${apiConfig.User.EditComment}/${commentId}`,
        method: PUT,
        body: body
      }),
      invalidatesTags: ['commentList', 'userPostList']
    }),
    deleteComment: builder.mutation({
      query: commentId => ({
        url: `${apiConfig.User.DeleteComment}/${commentId}`,
        method: DELETE
      }),
      invalidatesTags: ['commentList', 'userPostList']
    }),
    replyComment: builder.mutation({
      query: ({ commentId, body }) => ({
        url: `${apiConfig.User.ReplyComment}/${commentId}`,
        method: POST,
        body
      }),
      invalidatesTags: ['PostService', 'userPostList']
    }),
    registerForPostEvent: builder.mutation({
      query: ({ userId, postId }) => ({
        url: `api/posts/register/${postId}`,
        body: { user_id: userId },
        method: POST
      })
    }),
    modifySetting: builder.mutation({
      query: ({ id, data }) => ({
        url: `${apiConfig.User.ModifyNotifications}/${id}`,
        method: 'POST',
        body: data
      })
    })
  })
})

export const {
  useModifySettingMutation,
  useCreatePostMutation,
  useGetPostsQuery,
  useLazyGetPostsQuery,
  useDeletePostMutation,
  useAddProgressToPostMutation,
  useEditPostMutation,
  useGetPostByIdQuery,
  useLazyGetPostByIdQuery,
  useLikePostMutation,
  useUnLikePostMutation,
  useLikeCommentMutation,
  useUnLikeCommentMutation,
  useSharePostMutation,
  useAddCommentMutation,
  useGetCommentsQuery,
  useLazyGetCommentsQuery,
  useEditCommentMutation,
  useDeleteCommentMutation,
  useReplyCommentMutation,
  useRegisterForPostEventMutation,
  useEditProgressMutation,
  useLazyGetUserPostsQuery
} = postsService
