import React from 'react'
import clsx from 'clsx'
import './WhiteSpinner.css' // Import CSS file for styling

const Spinner = ({ className }) => (
  <div
    className={clsx('white-spinner-container', className)}
    data-testid="spinner"
  >
    <div className="white-spinner"></div>
  </div>
)

export default Spinner
