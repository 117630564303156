/* eslint-disable react/prop-types */
import { PrimaryButton } from '@components/Buttons'
import AdvancedGeoLocationInput from '@components/GeoLocationInput/AdvancedGeoLocationInput'
import clsx from 'clsx'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

const inputClassName =
  'rounded-lg w-full border border-[#928b80] p-4 text-base text-[#140C00] placeholder:text-[#928B80] invalid:border-red-600 focus:border-[#140C00] ring-0'

export const PostEventForm = ({ onSubmit, postData }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue
  } = useForm({
    defaultValues: {
      date: postData?.date || '',
      time: postData?.time || '',
      address: ''
    }
  })

  const onSubmitTrial = data => {
    const { address, ...rest } = data
    onSubmit({ ...rest, address: address.label })
  }

  useEffect(() => {
    if (postData?.address) {
      setValue('address', { label: postData.address, value: {} })
    }
  }, [postData.address])

  return (
    <div
      data-testid="post-event-form"
      className="pt-[56px] w-full flex flex-col h-full justify-between"
    >
      <div className="flex flex-col  space-y-6 w-full">
        <div className="flex flex-col space-y-6 md-full:space-y-0 md-full:flex-row md-full:space-x-5 w-full">
          {/* date */}
          <div className="flex-1 flex-shrink-0">
            <label className="text-[#140C00]" htmlFor="date">
              Date
            </label>
            <input
              {...register('date', { required: true })}
              data-testid="post-event-form-date-input"
              className={clsx(inputClassName, { '!border-[red]': errors.date })}
              type="date"
            />
          </div>
          {/* time */}
          <div className="flex-1 flex-shrink-0">
            <label className="text-[#140C00]" htmlFor="time">
              Time
            </label>
            <input
              data-testid="post-event-form-time-input"
              {...register('time', { required: true })}
              className={clsx(inputClassName, { '!border-[red]': errors.time })}
              type="time"
            />
          </div>
        </div>
        <div className="w-full flex-shrink-0">
          <label className="text-[#140C00]" htmlFor="address">
            Address
          </label>
          <Controller
            control={control}
            name="address"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <AdvancedGeoLocationInput
                value={value}
                selectId="post-event-form-address-input"
                setValue={onChange}
                containerClassName={'!h-[58px] rounded-lg !ring-0 !shadow-none'}
                controlClassName={clsx(
                  '!shadow-none !ring-none w-full border border-[#928b80] !h-full text-[#140C00] placeholder:text-[#928B80] invalid:border-red-600 focus:border-[#140C00] ring-0',
                  {
                    '!border-[red]': !!errors.address
                  }
                )}
              />
            )}
          />
        </div>
      </div>
      <div className="mt-[53px] pb-4 md-full:pb-0 flex justify-center">
        <PrimaryButton
          data-testid="post-event-form-submit-btn"
          text={'Review'}
          onClick={handleSubmit(onSubmitTrial)}
        />
      </div>
    </div>
  )
}

export default PostEventForm
