import { Tooltip, tooltipClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 184,
    fontSize: '14px',
    color: '#58534D',
    textAlign: 'center',
    background: 'white',
    boxShadow: '0px 2px 16px 0px rgba(20, 12, 0, 0.08)'
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 16px 0px rgba(20, 12, 0, 0.08)'
  }
})

export default CustomWidthTooltip
