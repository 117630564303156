import { isValidPhoneNumber } from 'react-phone-number-input'

const validators = {
  validateEmail: inputEmail => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(inputEmail)
  },
  validatePhoneNumber: phoneNumber => isValidPhoneNumber(phoneNumber),
  validateString: value => typeof value === 'string' && value.trim().length > 0
}

export default validators
