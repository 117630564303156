import { UserType } from '@constants/userType'
import { faker } from '@faker-js/faker'
import { fireEvent } from '@testing-library/react'
import moment from 'moment'
import words from 'naughty-words'
import { userDisplaysLocation } from './helpers'

const allWords = Object.keys(words)
  .filter(key => key !== 'tr') // Ignore the 'tr' key
  .map(key => words[key])
  .flat()

export const generateAvatarFaker = () => faker.image.avatar()

export const imageToBase64 = (imageFile, callback) => {
  const reader = new FileReader()
  reader.onloadend = function () {
    const base64String = reader.result
    callback(base64String)
  }
  reader.readAsDataURL(imageFile)
}

export const classNames = (...classes) => classes.filter(Boolean).join(' ')

export const getName = (
  displayName,
  firstName,
  lastName,
  organisationName,
  username,
  userType
) => {
  switch (userType) {
    case UserType.ORGANISATION:
      return organisationName || displayName || username
    case UserType.INDIVIDUAL:
      return `${firstName}${' ' + lastName}` || displayName || username
    default:
      return displayName || username
  }
}

function stringToColor(string) {
  const stringWithoutSpace = string.replace(/\s/g, '')
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < stringWithoutSpace.length; i += 1) {
    hash = stringWithoutSpace.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export const capitalizeFirstCharacter = data => {
  if (data !== '' && data !== null && data !== undefined) {
    return data.charAt(0).toUpperCase() + data.slice(1)
  } else {
    return ''
  }
}

export const currencyFormat = (num = 0) => {
  if (!num) return
  if (typeof num === 'number') {
    return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
}

export const dateFormater = date => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  let day = date.getDate()
  // get month from 0 to 11
  let month = date.getMonth()
  // conver month digit to month name
  month = months[month]
  const year = date.getFullYear()

  // show date in two digits
  if (day < 10) {
    day = '0' + day
  }

  // now we have day, month and year
  // arrange them in the format we want
  return month + ' ' + day + ', ' + year
}

export const stringAvatar = name => {
  const response = {
    sx: {
      bgcolor: stringToColor(name),
      width: 70,
      height: 70
    },
    children: `${name
      .split(' ')
      .map(name => name?.charAt(0))
      .join('')}`
  }

  return response
}

export const backspace = element => {
  let actuallyTyped = element.value

  const backspaceKey = {
    key: 'Backspace',
    code: 8,
    inputType: 'deleteContentBackward'
  }

  const sharedEventConfig = {
    key: backspaceKey.key,
    charCode: backspaceKey.code,
    keyCode: backspaceKey.code,
    which: backspaceKey.code,
    modifier: backspaceKey.modifier
  }
  const downEvent = fireEvent.keyDown(element, sharedEventConfig)

  if (downEvent) {
    actuallyTyped = actuallyTyped.slice(0, -1)

    fireEvent.input(element, {
      target: { value: actuallyTyped },
      inputType: backspaceKey.inputType,
      bubbles: true,
      cancelable: true
    })
  }

  fireEvent.keyUp(element, sharedEventConfig)
}

export const formattedTime = timestamp => {
  let result = ''
  // Parse the timestamp using Moment.js
  const time = moment(timestamp)

  // Get the current time
  const now = moment()

  // Calculate the difference between the current time and the timestamp
  const diff = now.diff(time)

  // Convert the difference to a human-readable format
  if (diff < 60000) {
    result = 'Just now'
  } else if (diff < 3600000) {
    result = time.fromNow(true) + ' ago'
  } else if (diff < 86400000) {
    result = time.fromNow(true) + ' ago'
  } else {
    result = time.fromNow(true) + ' ago'
  }
  if (window.innerWidth < 500) {
    result = result.replace('minutes', 'mins')
    result = result.replace('seconds', 'secs')
    result = result.replace('hours', 'hrs')
  }
  return result
}

export async function imageUrlToBase64(webpUrl) {
  // Fetch the WebP image as a blob
  const response = await fetch(webpUrl)
  const blob = await response.blob()

  // Convert the blob to a PNG image
  const pngBlob = await createImageBitmap(blob).then(imageBitmap => {
    const canvas = document.createElement('canvas')
    canvas.width = imageBitmap.width
    canvas.height = imageBitmap.height
    const ctx = canvas.getContext('2d')
    ctx.drawImage(imageBitmap, 0, 0)
    return new Promise((resolve, reject) => {
      canvas.toBlob(resolve, 'image/png')
    })
  })

  // Convert the PNG blob to base64
  const reader = new FileReader()
  reader.readAsDataURL(pngBlob)
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}

export const isURL = str => {
  const pattern = /^(ftp|http|https|blob):\/\/[^ "]+$/
  return pattern.test(str)
}

export const handlePageScrolling = shouldScroll => {
  const body = document.querySelector('body')
  if (body) {
    if (shouldScroll) {
      body.classList.remove('overflow-y-hidden')
      return
    }
    body.classList.add('overflow-y-hidden')
  }
}

export const doNothing = () => {}

export const fetchRemoteFile = (url, onFetchFileCallback) => {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const splitNames = url.split('/')
      const fileName = splitNames[splitNames.length - 1]
      const file = new File([blob], fileName, { type: blob.type })
      onFetchFileCallback(file)
    })
    .catch(error => console.error('Error fetching the file:', error))
}

export const getUserLocation = (user, force = false) => {
  const member = user
  const locationItems = []
  if (userDisplaysLocation(user) || force) {
    if (member.city) {
      locationItems.push(member.city)
    }
    if (member.country) {
      locationItems.push(member.country)
    }
    return locationItems
      .filter(item => item !== 'undefined' && !!item)
      .join(', ')
  } else {
    return ''
  }
}

const allowedWords = ['am']

export const containsBadWords = value => {
  if (!value) return true // Return true for empty value to pass validation

  // Remove allowed words
  const filteredValue = value
    .split(' ')
    .map(word => (allowedWords.includes(word.toLowerCase()) ? '' : word))
    .join(' ')

  const lowerCaseValue = filteredValue.toLowerCase()

  // Check for exact bad word matches
  for (const badWord of allWords) {
    const regex = new RegExp(`\\b${badWord}\\b`, 'i')
    if (regex.test(lowerCaseValue)) {
      return false // Return false if a bad word is found
    }
  }

  // Check for substrings in each word
  const words = lowerCaseValue.split(/\s+/)
  for (const word of words) {
    for (const badWord of allWords) {
      if (word.includes(badWord)) {
        return false // Return false if a bad word substring is found
      }
    }
  }

  return true // Return true if no bad words are found
}

export const flagBadWords = value => {
  if (!value) return false

  // Remove allowed words
  const filteredValue = value
    .split(' ')
    .map(word => (allowedWords.includes(word.toLowerCase()) ? '' : word))
    .join(' ')

  const lowerCaseValue = filteredValue.toLowerCase()

  // Check for exact bad word matches
  for (const badWord of allWords) {
    const regex = new RegExp(`\\b${badWord}\\b`, 'i')
    if (regex.test(lowerCaseValue)) {
      return true
    }
  }

  // Check for substrings in each word
  const words = lowerCaseValue.split(/\s+/)
  for (const word of words) {
    for (const badWord of allWords) {
      if (word.includes(badWord)) {
        return true
      }
    }
  }

  return false
}
