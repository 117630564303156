import { useMediaQuery } from 'react-responsive'

const useAppSize = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' })
  const isTablet = useMediaQuery({
    query: '(min-width: 760px) and (max-width: 1200px)'
  })
  const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' })

  return {
    isMobile,
    isTablet,
    isDesktop
  }
}

export default useAppSize
