import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp'
import { useEffect, useMemo, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { postTypes } from '@constants/posts'
import toast from 'react-hot-toast'
import CustomWidthTooltip from '@components/utils/CustomWidthTooltip'

// eslint-disable-next-line react/prop-types
const AddGoal = ({ setPostData, postData = {}, onProceed, dataToEdit }) => {
  const [showErrors, setShowErrors] = useState(false)
  const [amount, setAmount] = useState(postData?.amount ?? '')
  const [deadline, setDeadline] = useState(getToday())
  const [amountSoFar, setAmountSoFar] = useState(postData?.amountSoFar ?? '')

  function getToday() {
    const today = postData?.deadline ? new Date(postData?.deadline) : new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0') // Month is 0-indexed
    const day = String(today.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const handleChangeAmount = value => {
    if (Number(value) > 0) {
      setAmount(value)
    } else {
      toast.error('Please input a valid amount')
    }
  }

  const handleChangeAmountSoFar = value => {
    setAmountSoFar(value)
  }

  const handleDeadlineChange = date => {
    // if (isDateLessThanToday(date, true)) {
    //   toast.error('Deadline is in the past. Please select a future date.')
    // } else {
    //   setDeadline(date)
    // }
    setDeadline(date)
  }

  const isValidated = useMemo(
    () => amount && deadline && amountSoFar,
    [amount, deadline, amountSoFar]
  )

  const handleClickNext = () => {
    if (isValidated) {
      setPostData(ps => ({
        ...ps,
        required_amount: amount,
        deadline,
        current_amount: amountSoFar,
        type: postTypes.goal,
        date: '',
        time: '',
        address: ''
      }))
      onProceed()
    }
  }

  useEffect(() => {
    if (Object.keys(postData)) {
      setAmount(postData?.required_amount)
      setAmountSoFar(postData?.current_amount)
      setDeadline(postData?.deadline)
    }
  }, [postData])

  const submitBtnTitle = dataToEdit?.type === 'goal' ? 'Review' : 'Next'

  return (
    <div
      data-testid="post-goal-form"
      className="font-lato flex flex-col justify-center items-center"
    >
      {Number(amount) < Number(amountSoFar) && (
        <div className="w-full max-w-sm my-2">
          <div className="rounded-lg border border-[#FF5018] bg-[#FF501814] px-4 py-2.5 flex items-center gap-3">
            <ErrorOutlineSharpIcon style={{ color: '#FF5018' }} />
            <p className="text-sm text-[#FF5018]">
              Required Amount should not be less than Current Amount
            </p>
          </div>
        </div>
      )}
      <div className="xs:mt-0 mt-6 w-full">
        <div className="pt-6 flex items-center justify-center w-full text-left">
          <div className="grid md-full:grid-cols-2 gap-5 w-full max-w-sm">
            <div className="flex flex-col gap-2 w-full">
              <label className="text-[#140C00]" htmlFor="amount">
                Amount
              </label>
              <CurrencyFormat
                id="amount"
                name="amount"
                thousandSeparator={true}
                data-testid="post-goal-form-amount-input"
                prefix={'$'}
                placeholder="$10,000"
                className="rounded-lg w-full border border-[#928b80] p-4 text-[#140C00] placeholder:text-[#928B80] invalid:border-red-600 focus:border-[#140C00] ring-0"
                value={amount}
                onValueChange={values => handleChangeAmount(values.value)}
              />
              {showErrors && !amount?.length ? (
                <p style={{ color: 'red' }}>Please enter valid amount</p>
              ) : null}
            </div>
            <div className="flex flex-col gap-2 w-full ">
              <label className="text-[#140C00]" htmlFor="deadline">
                Deadline
              </label>
              <input
                id="deadline"
                value={deadline}
                data-testid="post-goal-form-deadline-input"
                onChange={e => handleDeadlineChange(e.target.value)}
                className="rounded-lg w-full border border-[#928b80] text-base p-4 text-[#140C00] placeholder:text-[#928B80] invalid:border-red-600 focus:border-[#140C00] ring-0"
                type="date"
              />
              {showErrors && !deadline?.length ? (
                <p style={{ color: 'red' }}>Please select deadline</p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="pt-6 flex items-center justify-center w-full text-left">
          <div className="flex flex-col gap-2 w-full max-w-sm">
            <label
              className="text-[#140C00] flex items-center gap-5"
              htmlFor="amountSoFar"
            >
              <p>How much so far</p>
              <CustomWidthTooltip
                title="Input how much you have so far either in amount or in percentage"
                arrow
                placement="top"
              >
                <div className="w-4 h-4 bg-[#928B80] rounded-full text-xs text-white font-bold font-sans flex items-center justify-center">
                  ?
                </div>
              </CustomWidthTooltip>
            </label>
            <CurrencyFormat
              id="amountSoFar"
              name="amountSoFar"
              data-testid="post-goal-form-amount-sofar-input"
              thousandSeparator={true}
              prefix={'$'}
              placeholder="$4,000"
              className="rounded-lg w-full border border-[#928b80] p-4 text-[#140C00] placeholder:text-[#928B80] invalid:border-red-600 focus:border-[#140C00] ring-0"
              value={amountSoFar}
              onValueChange={values => handleChangeAmountSoFar(values.value)}
            />
            {showErrors && !amountSoFar?.length ? (
              <p style={{ color: 'red' }}>Please enter a valid amount</p>
            ) : null}
          </div>
        </div>
        <div className="my-8 flex items-center justify-center w-full">
          <div className="w-full max-w-sm">
            <button
              data-testid="goal-form-submit-btn"
              onClick={() => {
                if (isValidated) {
                  setShowErrors(false)
                  handleClickNext()
                } else {
                  setShowErrors(true)
                }
              }}
              // disabled={!isValidated}
              className="w-full bg-[#FF9502] hover:bg-[#FF9502]/80 rounded-lg text-white font-bold px-10 py-4 cursor-pointer"
            >
              {submitBtnTitle}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddGoal
