import { useState } from 'react'

/** Hook to use for step forms e.g create post, message subscribers etc. */
const useStepForm = ({ steps, initialStep, onClose }) => {
  const [step, setStep] = useState(initialStep ? initialStep : null)

  const currentStepIs = stepName => step === stepName

  const onProceed = () => {
    const currStepIndex = steps.indexOf(step)
    if (currStepIndex !== steps.length - 1) {
      setStep(steps[currStepIndex + 1])
    }
  }

  const onBackClick = () => {
    const currStepIndex = steps.indexOf(step)
    if (currStepIndex === 0) {
      onClose()
      return
    }
    setStep(steps[currStepIndex - 1])
  }

  return {
    step,
    setStep,
    currentStepIs,
    onProceed,
    onBackClick
  }
}

export default useStepForm
