import { QRCodeSVG } from "qrcode.react";
import React from "react";

const BarcodeModal = ({ link, logo }) => {
  return (
    <div className="flex flex-col justify-between items-center align-middle mt-8 text-center gap-4 pb-10">
      <div className="text-[18px] text-[#58534D] w-[75%]">
        Scan QR Code and invite your trusted Friends and Family into Maoney
      </div>
      <div className="bg-white rounded-xl shadow-lg  ">
        <QRCodeSVG
          className="m-6"
          value={link}
          // imageSettings={{
          //   src: logo,
          //   x: undefined,
          //   y: undefined,
          //   height: 24,
          //   width: 24,
          //   excavate: true,
          // }}
        />
      </div>
    </div>
  );
};

export default BarcodeModal;
