/* eslint-disable react/prop-types */
import congrats_fab from '@assets/images/congrats-fab.png'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import usePosts from '@hooks/posts/usePosts'
import { postTypes } from '@constants/posts'
import { updateWalkthoughMenu } from '@store/slices/app'
import { authenticateUser } from '@store/slices/auth'

const Congratulations = ({
  onClose,
  reset,
  dataToEdit,
  isProgressEdit,
  isNormalEdit,
  clearFormData
}) => {
  const { setPostToEdit, clearPostToEdit, postToUpdate } = usePosts()
  const dispatch = useDispatch()
  const { user } = useSelector(state => state?.auth)
  const { walkthroughMenu: menu } = useSelector(state => state.app)

  const closeModal = () => {
    if (!user?.tour_completed) {
      const clonedUserData = { ...user }
      clonedUserData.tour_completed = true
      dispatch(authenticateUser({ user: clonedUserData }))
    }
    onClose()
    clearPostToEdit()
    if (menu === 'Create Post') {
      dispatch(updateWalkthoughMenu('Invite Modal'))
    }
  }

  const handleContinueEditing = () => {
    if (postToUpdate) {
      setPostToEdit(
        postToUpdate.data.id,
        postToUpdate.updateType,
        postToUpdate.index
      )
      reset()
    }
  }

  const handleAddMore = () => {
    clearFormData()
    reset()
  }

  const subtitle = useMemo(() => {
    const isGoal = dataToEdit?.type === postTypes.goal
    if (isProgressEdit && isGoal) {
      return 'You have updated your Goal'
    }
    if (isProgressEdit && !isGoal) {
      return 'You have added progress to your post'
    }
    if (isNormalEdit && isGoal) {
      return 'You have made an edit to your Goal Post'
    }
    if (isNormalEdit && !isGoal) {
      return 'You have made an edit to your Post'
    }
    if (menu === 'Create Post') {
      return 'You have made a post, now lets invite our Friends and Family to Maoney!'
    }

    return 'You have made a post'
  }, [isProgressEdit, isNormalEdit, dataToEdit])

  return (
    <div
      className="flex flex-col justify-center items-center w-full"
      data-testid="post-success-modal"
    >
      <img width={'100px'} height={'100px'} src={congrats_fab} />
      <p className="text-[#140C00] font-bold font-sans text-[32px] xs:text-[24px] pt-[24px]">
        Congratulations
      </p>
      <p className="text-[#58534D] font-lato w-full text-center pt-[16px] max-w-xs">
        {subtitle}
      </p>
      {isProgressEdit ? (
        <div className="pt-[48px] w-full">
          <div className="flex gap-5 xs:flex-col justify-center items-center xs:pb-5">
            <button
              onClick={handleAddMore}
              data-testid="post-success-modal-add-more-progress-btn"
              className="bg-white rounded-lg border-2 border-[#FF9502] font-semibold text-[#FF9502] w-[180px] xs:w-full h-[54px]"
            >
              Add more progress
            </button>
            <button
              data-testid="post-success-modal-dismiss"
              onClick={closeModal}
              className="bg-[#FF9502] rounded-lg font-semibold text-white w-[180px] xs:w-full h-[54px]"
            >
              Done
            </button>
          </div>
        </div>
      ) : dataToEdit ? (
        <div className="pt-[48px] w-full">
          <div className="flex gap-5 xs:flex-col justify-center pb-3">
            <button
              onClick={handleContinueEditing}
              data-testid="post-success-modal-continue-editing-btn"
              className="bg-white rounded-lg border-2 border-[#FF9502] font-semibold text-[#FF9502] w-[180px] xs:w-full h-[54px]"
            >
              Continue editing
            </button>
            <button
              data-testid="post-success-modal-dismiss"
              onClick={closeModal}
              className="bg-[#FF9502] rounded-lg font-semibold text-white w-[180px] xs:w-full h-[54px]"
            >
              Done
            </button>
          </div>
        </div>
      ) : (
        <div className="pt-[48px] w-full pb-7 md-full:pb-0">
          <button
            onClick={closeModal}
            data-testid="post-success-modal-dismiss"
            className="bg-[#FF9502] rounded-lg font-semibold text-white w-full h-[54px]"
          >
            {menu === 'Create Post' ? "Okay, let's Continue" : 'Done'}
          </button>
        </div>
      )}
    </div>
  )
}

export default Congratulations
