export const postActions = {
  like: 'like',
  comment: 'comment',
  unlike: 'unlike'
}

export const postTypes = {
  goal: 'goal',
  event: 'event',
  general: 'general'
}

export const editPostTypes = {
  addProgress: 'add-progress',
  default: 'default',
  goal: 'goal'
}

export const minPostImgCount = 2
export const maxPostImgCount = 10

export const acceptedImages =
  'image/png, image/jpeg, image/heic, image/heif, image/webp'

export const postPageSize = 20
