/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
  messageSubscribersSteps,
  messageSubscribersTextMappings,
  steps
} from './utils'
import useStepForm from '@hooks/general/useStepForm'
import { useDispatch, useSelector } from 'react-redux'
import { setSubscribersMessagePost } from '@store/slices/posts'
import Backdrop from '@components/Modals/Backdrop'
import clsx from 'clsx'
import MessageInput from './MessageInput'
import MessagePreview from './MessagePreview'
import PaymentInfoStep from './PaymentInfoStep'
import Congratulations from './Congratulations'

const MessageSubscribers = () => {
  const [data, setData] = useState({ message: '' })
  const dispatch = useDispatch()
  const onClose = () => dispatch(setSubscribersMessagePost(null))
  const { subscribersMessagePost } = useSelector(state => state.posts)
  const { currentStepIs, onProceed, onBackClick, step, setStep } = useStepForm({
    steps,
    initialStep: messageSubscribersSteps.messageInputStep,
    onClose
  })

  const commonProps = {
    data,
    setData,
    onProceed,
    post: subscribersMessagePost,
    onClose
  }

  useEffect(() => {
    if (!subscribersMessagePost) {
      onClose()
      if (data?.message) {
        setData({ message: '' })
      }
      if (step !== messageSubscribersSteps.messageInputStep) {
        setTimeout(() => {
          setStep(messageSubscribersSteps.messageInputStep)
        }, 500)
      }
    }
  }, [subscribersMessagePost])

  const { title, subtitle } = messageSubscribersTextMappings[step] || {}
  const isEnd = currentStepIs(messageSubscribersSteps.congratulationsStep)
  return (
    <Backdrop visible={subscribersMessagePost}>
      <div
        className={clsx(
          'w-full max-w-[630px] flex flex-col min-h-[525px] md-full:h-[525px] transition-all bg-white rounded-3xl px-6 py-8',
          {
            '!h-auto': currentStepIs(
              messageSubscribersSteps.congratulationsStep
            ),
            '!h-[375px] !max-w-[476px] !py-8 !px-12': isEnd
          }
        )}
      >
        {!isEnd && (
          <div className="flex flex-shrink-0 justify-between">
            <button
              type="button"
              className="w-[38px] h-[49px]"
              onClick={onBackClick}
            >
              <svg
                width="12"
                height="21"
                viewBox="0 0 12 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.53714 20.0827L0.292152 10.9458C-0.097384 10.5612 -0.097384 9.93981 0.292152 9.5542L9.53714 0.417333C10.0995 -0.139111 11.0144 -0.139111 11.5777 0.417333C12.14 0.973776 12.14 1.87687 11.5777 2.43332L3.66913 10.2505L11.5777 18.0657C12.14 18.6231 12.14 19.5262 11.5777 20.0827C11.0144 20.6391 10.0995 20.6391 9.53714 20.0827Z"
                  fill="#140C00"
                />
              </svg>
            </button>
            <div className="flex flex-col space-y-2 pt-2">
              <h3 className="text-center font-lato text-[32px] leading-[38.4px] text-_140C00">
                {title}
              </h3>
              {typeof subtitle === 'object' ? (
                subtitle
              ) : (
                <p className="text-center font-lato text-[#58534D] text-base leading-6 max-w-[313px]">
                  {subtitle}
                </p>
              )}
            </div>

            <svg
              className="cursor-pointer flex-shrink-0"
              onClick={onClose}
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z"
                fill="#928B80"
                fillOpacity="0.2"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.2273 14.9556C26.3051 14.8779 26.3668 14.7857 26.4089 14.6842C26.451 14.5828 26.4727 14.474 26.4728 14.3641C26.4728 14.2542 26.4513 14.1454 26.4093 14.0439C26.3673 13.9423 26.3057 13.8501 26.2281 13.7723C26.1504 13.6946 26.0582 13.6329 25.9567 13.5908C25.8552 13.5487 25.7465 13.5269 25.6366 13.5269C25.5267 13.5268 25.4179 13.5484 25.3163 13.5904C25.2148 13.6324 25.1225 13.6939 25.0448 13.7716L19.999 18.8174L14.9546 13.7716C14.7976 13.6146 14.5846 13.5264 14.3626 13.5264C14.1406 13.5264 13.9276 13.6146 13.7706 13.7716C13.6136 13.9286 13.5254 14.1415 13.5254 14.3636C13.5254 14.5856 13.6136 14.7986 13.7706 14.9556L18.8164 19.9999L13.7706 25.0443C13.6929 25.1221 13.6312 25.2143 13.5891 25.3159C13.547 25.4175 13.5254 25.5264 13.5254 25.6363C13.5254 25.7463 13.547 25.8551 13.5891 25.9567C13.6312 26.0583 13.6929 26.1506 13.7706 26.2283C13.9276 26.3853 14.1406 26.4735 14.3626 26.4735C14.4725 26.4735 14.5814 26.4519 14.683 26.4098C14.7846 26.3677 14.8769 26.306 14.9546 26.2283L19.999 21.1825L25.0448 26.2283C25.2018 26.3851 25.4147 26.4731 25.6366 26.473C25.8585 26.4729 26.0712 26.3846 26.2281 26.2276C26.3849 26.0706 26.4729 25.8577 26.4728 25.6358C26.4726 25.4139 26.3843 25.2011 26.2273 25.0443L21.1815 19.9999L26.2273 14.9556Z"
                fill="#140C00"
              />
            </svg>
          </div>
        )}

        <div
          className={clsx('flex-1 w-full px-5 md-full:px-[99px] relative', {
            '!px-0': isEnd
          })}
        >
          {currentStepIs(messageSubscribersSteps.messageInputStep) && (
            <MessageInput {...commonProps} />
          )}
          {currentStepIs(messageSubscribersSteps.reviewStep) && (
            <MessagePreview {...commonProps} />
          )}
          {currentStepIs(messageSubscribersSteps.paymentInfoStep) && (
            <PaymentInfoStep {...commonProps} />
          )}
          {currentStepIs(messageSubscribersSteps.congratulationsStep) && (
            <Congratulations onClose={onClose} />
          )}
        </div>
      </div>
    </Backdrop>
  )
}

export default MessageSubscribers
